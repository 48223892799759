import React from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import RemarksNote from './RemarksNote/RemarksNote';
import { NodeType } from '../../../backendModels/report.model';
import DiviPage from '../../customs/DiviPage/DiviPage';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { useCSS } from '../../../provider/CSSProvider';
import style from './Remarks.scss';
import RefusalOfMeasures from './RefusalOfMeasures/RefusalOfMeasures';
import { REMARKS_RECORD_ELEMENT_KEY } from '../../../models/genericElements/remarks';

export function Remarks() {
  useCSS(style);

  const recordTypesOfPage = ['note'] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.GENERIC;
  const elementKeysOfPage = [REMARKS_RECORD_ELEMENT_KEY];

  const pageHeader = <PageHeader heading={getPageTitle(PageType.Remarks)} />;

  const pageFooter = <PageFooter pageType={PageType.Remarks} diviId={DiviNavigationIds.Remarks} noNextButton />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
      diviId={DiviNavigationIds.Remarks}
    >
      <div className='wireframe-container-remarks'>
        <RefusalOfMeasures nodeType={nodeTypeOfPage} />
        <RemarksNote nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
