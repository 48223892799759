import React from 'react';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { DiviNavigationIds } from '../../../models/diviProgress';
import { NodeType } from '../../../backendModels/report.model';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import MedicationCard from './Medication/MedicationCard';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { useCSS } from '../../../provider/CSSProvider';
import style from './Medication.scss';
import PreIntervention from './PreIntervention/PreIntervention';
import LyseTherapy from './LyseTherapy/LyseTherapy';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { LYSE_THERAPY_RECORD_ELEMENT_KEY } from '../../../models/genericElements/lysetherapy';
import { PRE_INTERVENTION_RECORD_ELEMENT_KEY } from '../../../models/genericElements/preIntervention';

export function Medication() {
  useCSS(style);

  const recordTypesOfPage = ['medication'] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.GENERIC;
  const elementKeysOfPage = [LYSE_THERAPY_RECORD_ELEMENT_KEY, PRE_INTERVENTION_RECORD_ELEMENT_KEY];

  const pageHeader = <PageHeader heading={getPageTitle(PageType.Medication)} />;

  const pageFooter = <PageFooter pageType={PageType.Medication} diviId={DiviNavigationIds.Medication} />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
      diviId={DiviNavigationIds.Medication}
    >
      <div>
        <div className='wireframe-container-medication'>
          <div className='medication'>
            <MedicationCard nodeType={nodeTypeOfPage} />
          </div>
          <PreIntervention nodeType={nodeTypeOfPage} />
          <LyseTherapy nodeType={nodeTypeOfPage} />
        </div>
      </div>
    </DiviPage>
  );
}
