import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../../generic';
import { MissionTypeKeys } from './missionType';
import { TextFieldValue } from '../../../backendModels/generic-element.model';
import { SymptomStartKeys } from './symptomStart';
import { InputState } from '../../../backendModels/general.model';

export const OPERATIONAL_DATA_RECORD_ELEMENT_KEY = 'diviMissionData';

export type OperationalDataRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof OPERATIONAL_DATA_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          missionType?: SingleSelectFieldValueTyped<MissionTypeKeys>;
          missionFailure?: SingleSelectFieldValueTyped<MissionTypeKeys>;
          isPrenotification?: MultiSelectFieldValueTyped<PrenotificationKeys>;
          symptomOnsetOptions?: MultiSelectFieldValueTyped<SymptomStartKeys>;
          symptomOnsetTimestamp?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export enum PrenotificationKeys {
  isprenotification = 'IS_PRENOTIFICATION',
}

const prenotificationLabels: Record<PrenotificationKeys, string> = {
  [PrenotificationKeys.isprenotification]: 'Voranmeldung',
};

export function getPrenotificationTupel(key: PrenotificationKeys): [PrenotificationKeys, string] {
  return [key, prenotificationLabels[key]];
}
