import React, { useContext, useState } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import style from './PatientData.scss';
import globalstyle from '../../../../global.scss';
import { useCSS } from '../../../../provider/CSSProvider';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import {
  BirthDateBackendFormat,
  BirthDateUiFormat,
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
  getPseudonymTuplesFor,
  PseudonymKeys,
} from '../../../../models/genericElements/extendedPatientData';
import { InputState } from '../../../../backendModels/general.model';
import { NodeType } from '../../../../backendModels/report.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

interface PatientDataProps {
  nodeType: NodeType;
}

export default function PatientData({ nodeType }: PatientDataProps) {
  useCSS(style);
  useCSS(globalstyle);

  const { findRecordOrDefault, adaptRecord, patient, patientUpdate, coverage } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as ExtendedPatientDataRecord;
  const birthDate = patient?.birthDate != null ? dayjs(patient?.birthDate, BirthDateBackendFormat) : null;
  const [familyNameTouched, setFamilyNameTouched] = useState(false);
  const [givenNameTouched, setGivenNameTouched] = useState(false);
  const [birthDateTouched, setBirthDateTouched] = useState(false);
  const [streetTouched, setStreetTouched] = useState(false);
  const [postalCodeTouched, setPostalCodeTouched] = useState(false);
  const [cityTouched, setCityTouched] = useState(false);

  const handleFamilyNameChange = useFreshCallback((value: string) => {
    if (value !== patient?.familyName) {
      patientUpdate?.({ ...patient, familyName: value });
      setFamilyNameTouched(true);
    }
  });

  const handleGivenNameChange = useFreshCallback((value: string) => {
    if (value !== patient?.givenName) {
      patientUpdate?.({ ...patient, givenName: value });
      setGivenNameTouched(true);
    }
  });

  const handleBirthDateChange = useFreshCallback((value: dayjs.Dayjs | null) => {
    if (value !== birthDate) {
      patientUpdate?.({
        ...patient,
        birthDate: value != null && value?.isValid() ? value.format(BirthDateBackendFormat) : undefined,
      });
      setBirthDateTouched(true);
    }
  });

  const handleStreetChange = useFreshCallback((value: string) => {
    if (value !== patient?.address?.street) {
      patientUpdate?.({ ...patient, address: { ...patient?.address, street: value } });
      setStreetTouched(true);
    }
  });

  const handlePostalCodeChange = useFreshCallback((value: string) => {
    if (value !== patient?.address?.postalCode) {
      patientUpdate?.({ ...patient, address: { ...patient?.address, postalCode: value } });
      setPostalCodeTouched(true);
    }
  });

  const handleCityChange = useFreshCallback((value: string) => {
    if (value !== patient?.address?.city) {
      patientUpdate?.({ ...patient, address: { ...patient?.address, city: value } });
      setCityTouched(true);
    }
  });

  const prefilled = useFreshCallback((value: string | undefined | null, touched: boolean) => {
    return value != null && !touched ? 'prefilled' : '';
  });

  const handlePseoudonymChange = useFreshCallback((value: PseudonymKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length > 0) {
          draft.values = {
            ...draft.values,
            pseudonym: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.pseudonym;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.PatientData}>
      <div className='patient-data-wireframe'>
        <DebouncedTextField
          className={prefilled(patient?.familyName, familyNameTouched)}
          label='Name'
          value={patient?.familyName ?? ''}
          onDebounceChange={handleFamilyNameChange}
        />
        <DebouncedTextField
          className={prefilled(patient?.givenName, givenNameTouched)}
          label='Vorname'
          value={patient?.givenName ?? ''}
          onDebounceChange={handleGivenNameChange}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            fullWidth
            className={`no-margin-top ${prefilled(patient?.birthDate, birthDateTouched)}`}
            label='Geburtsdatum'
            value={birthDate}
            format={BirthDateUiFormat}
            onChange={handleBirthDateChange}
          />
        </LocalizationProvider>

        <CheckboxList
          className='symptoms-list'
          items={getPseudonymTuplesFor([PseudonymKeys.pseudonym])}
          selectedValues={
            record.inputState === InputState.ENTERED ? (record.values.pseudonym?.options as PseudonymKeys[]) ?? [] : []
          }
          onValuesChange={handlePseoudonymChange}
        />
        <DebouncedTextField
          className={prefilled(patient?.address?.street, streetTouched)}
          label='Straße'
          value={patient?.address?.street ?? ''}
          onDebounceChange={handleStreetChange}
        />
        <div className='row patient-data-location'>
          <DebouncedTextField
            className={prefilled(patient?.address?.postalCode, postalCodeTouched)}
            fullWidth
            label='PLZ'
            value={patient?.address?.postalCode ?? ''}
            inputProps={{ min: 1, max: 99999, step: 1, maxLength: 5 }}
            type='number'
            onDebounceChange={handlePostalCodeChange}
          />
          <DebouncedTextField
            className={`patient-data-city ${prefilled(patient?.address?.city, cityTouched)}`}
            fullWidth
            label='Ort'
            value={patient?.address?.city ?? ''}
            onDebounceChange={handleCityChange}
          />
        </div>
        <DebouncedTextField
          className={`${prefilled(coverage?.insuranceName, false)} ${prefilled(coverage?.policyNumber, false)}`}
          InputProps={{
            disabled: true,
          }}
          label='Kasse / Nr.'
          value={`${coverage?.insuranceName ?? ''} / ${coverage?.policyNumber ?? ''}`}
        />
        <DebouncedTextField
          className={prefilled(coverage?.insuranceNumber, false)}
          InputProps={{
            disabled: true,
          }}
          label='Versicherungs Nr.'
          value={coverage?.insuranceNumber ?? ''}
        />
      </div>
    </DiviFieldCard>
  );
}
