import { Patient } from '../backendModels/patient.model';

export class PatientChangeEvent extends Event {
  change: Patient;

  constructor(type: string, newPatient: Patient) {
    super(type, { bubbles: true });

    this.change = newPatient;
  }
}
