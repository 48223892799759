import React, { useContext, useState } from 'react';
import { Breathing } from './Breathing/Breathing';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import Pain from './Pain/Pain';
import { useCSS } from '../../../provider/CSSProvider';
import style from '../DataInitial/DataInitial.scss';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { DiviNavigationIds } from '../../../models/diviProgress';
import Ecg from './Ecg/Ecg';
import VitalParameter from './VitalParameter/VitalParameter';
import { Skin } from './Skin/Skin';
import { Psyche } from './Psyche/Psyche';
import { NodeType } from '../../../backendModels/report.model';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import dayjs from 'dayjs';
import TimeDialog from '../../customs/timeDialog/TimeDialog';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { BLUTZUCKER_ERWEITERT_ELEMENT_KEY } from '../../../models/genericElements/bloodGlucose';
import { VITALPARAMETER_ERWEITERT_ELEMENT_KEY } from '../../../models/genericElements/vitalParameter';

export function DataInitial() {
  useCSS(style);

  const [timeDialogOpen, setTimeDialogOpen] = useState<boolean>(false);
  const reportAPI = useContext(ReportsAPIContext);

  const recordTypesOfPage = [
    'breathing',
    'environment',
    'circulation',
    'pain',
    'psyche',
    'ecgFindings',
    'vitalParameters',
  ] as (keyof BaseNodeRecordValueTypeMap)[];
  const elementKeysOfPage = [BLUTZUCKER_ERWEITERT_ELEMENT_KEY, VITALPARAMETER_ERWEITERT_ELEMENT_KEY];

  const nodeTypeOfPage = NodeType.INITIAL_SURVEY;

  const timestamp = dayjs(reportAPI.getNodeTimestamp(nodeTypeOfPage) ?? undefined);

  function setTimestamp(newTimestamp: dayjs.Dayjs) {
    reportAPI.setNodeTimestamp(newTimestamp.valueOf(), nodeTypeOfPage);
  }

  function openTimeDialog() {
    setTimeDialogOpen(true);
  }

  const pageHeader = (
    <PageHeader
      heading={getPageTitle(PageType.DataInitial)}
      onTimeClicked={openTimeDialog}
      time={timestamp.format('HH:mm')}
    />
  );

  //ElementKeys are not passed to footer in order to ensure the no path button highlighting to function
  const pageFooter = (
    <PageFooter
      pageType={PageType.DataInitial}
      diviId={DiviNavigationIds.DataInitial}
      noPathButton
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      elementKeys={elementKeysOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.DataInitial}
    >
      <TimeDialog
        timestamp={{ timestamp: timestamp, setTimestamp: setTimestamp }}
        dialogVisible={{ open: timeDialogOpen, setOpen: setTimeDialogOpen }}
      />
      <div className='wireframe-container-data-initial'>
        <div className='pain'>
          <Pain nodeType={nodeTypeOfPage} />
        </div>
        <div className='vital-params'>
          <VitalParameter nodeType={nodeTypeOfPage} />
        </div>
        <div className='ecg'>
          <Ecg nodeType={nodeTypeOfPage} />
        </div>
        <Breathing nodeType={nodeTypeOfPage} />
        <Skin nodeType={nodeTypeOfPage} />
        <Psyche nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
