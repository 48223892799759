import React, { useState } from 'react';
import style from './PageHeader.scss';
import { Chip, Divider, IconButton, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useCSS } from '../../../../provider/CSSProvider';
import { CardId } from '../../../../models/diviCard';
import InAreaNavigation from '../../../customs/inAreaNavigation/inAreaNavigation';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/delete.svg';
import { useDiviPageContext } from '../DiviPage';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';

export interface PageHeaderProps {
  heading: string;
  nextText?: string;
  onTimeClicked?: () => void;
  time?: string;
  ListOfCardIds?: CardId[];
  onButtonClick?: () => void;
  noClearAllButton?: boolean;
}
function PageHeader({ heading, onTimeClicked, time, ListOfCardIds, noClearAllButton }: PageHeaderProps) {
  useCSS(style);

  const { handleClearAll } = useDiviPageContext();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

  const clearPage = () => {
    handleClearAll();
  };

  const openDialog = () => {
    setConfirmationDialogOpen(true);
  };

  const handleOk = () => {
    setConfirmationDialogOpen(false);
    clearPage();
  };

  const handleCancel = () => {
    setConfirmationDialogOpen(false);
  };

  return (
    <div id='PageHeader-area'>
      <ConfirmationDialog
        dialogVisible={{ open: confirmationDialogOpen, setOpen: setConfirmationDialogOpen }}
        onOk={handleOk}
        onCancel={handleCancel}
        headline='Inhalt der Seite löschen'
        text='Sind sie sicher, dass sie die gesamte Seite zurücksetzen möchten? Ihre
            bisherigen Einträge auf dieser Seite werden durch diese Aktion unwiederbringlich überschrieben.'
      />
      <div id='PageHeader'>
        <div className='time-chip-placeholder'>
          {time && <Chip icon={<AccessTimeIcon />} label={time} onClick={onTimeClicked} />}
        </div>
        <Typography variant='h1'>{heading}</Typography>
        {!noClearAllButton && (
          <div className='clear-all'>
            <IconButton onClick={openDialog}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
      <Divider />
      {ListOfCardIds && (
        <div id='PageHeader-nav-appendix'>
          <InAreaNavigation listOfCardTypes={ListOfCardIds} />
        </div>
      )}
    </div>
  );
}

export default PageHeader;
