import Gcs from '../Neurology/Gcs/Gcs';
import LimbMovements from '../Neurology/LimbMovements/LimbMovements';
import Consciousness from '../Neurology/Consciousness/Consciousness';
import NeurologicalFeatures from '../Neurology/NeurologicalFeatures/NeurologicalFeatures';
import Pupils from '../Neurology/Pupils/Pupils';

import { Breathing } from '../DataInitial/Breathing/Breathing';
import Ecg from '../DataInitial/Ecg/Ecg';
import Pain from '../DataInitial/Pain/Pain';
import { Psyche } from '../DataInitial/Psyche/Psyche';
import { Skin } from '../DataInitial/Skin/Skin';
import VitalParameter from '../DataInitial/VitalParameter/VitalParameter';

import InjuriesActivity from '../Injuries/InjuriesActivity/InjuriesActivity';
import InjuriesCause from '../Injuries/InjuriesCause/InjuriesCause';
import InjuriesCategory from '../Injuries/InjuriesCategory/InjuriesCategory';
import InjuriesAccidentMechanism from '../Injuries/InjuriesAccidentMechanism/InjuriesAccidentMechanism';

import NacaScore from '../DiagnosisInitial/NacaScore/NacaScore';
import DiagnosisNote from '../DiagnosisInitial/DiagnosisNote/DiagnosisNote';
import PalliativeSituation from '../DiagnosisInitial/PalliativeSituation/PalliativeSituation';

import { CardId } from '../../../models/diviCard';
import PatientData from '../PatientMasterData/PatientData/PatientData';
import Gender from '../PatientMasterData/Gender/Gender';
import Bmi from '../PatientMasterData/Bmi/Bmi';
import Age from '../PatientMasterData/Age/Age';
import Mission from '../PatientMasterData/Mission/Mission';
import Participants from '../OperationalData/Participants/Participants';
import MissionType from '../OperationalData/MissionType/Type';
import MissionLocation from '../OperationalData/MissionLocation/Location';
import SymptomStart from '../OperationalData/SymptomStart/SymptomStart';
import Destination from '../OperationalData/Destination/Destination';

export const COMPONENT_MAP: { [key: string]: React.ElementType } = {
  Breathing: Breathing,
  Gcs: Gcs,
  LimbMovements: LimbMovements,
  Consciousness: Consciousness,
  NeurologicalFeatures: NeurologicalFeatures,
  Pupils: Pupils,
  Ecg: Ecg,
  Pain: Pain,
  Psyche: Psyche,
  Skin: Skin,
  VitalParameter: VitalParameter,
  InjuryActivity: InjuriesActivity,
  InjuryCause: InjuriesCause,
  InjuryLocation: InjuriesCategory,
  InjuryAccidentMechanism: InjuriesAccidentMechanism,
  NacaScore: NacaScore,
  DiagnosisNote: DiagnosisNote,
  PalliativeSituation: PalliativeSituation,

  // The cards below are not part of INITIAL_SURVEY, but can handle the nodeType without issue.
  PatientData: PatientData,
  Gender: Gender,
  Bmi: Bmi,
  Age: Age,
  Mission: Mission,
  Participants: Participants,
  MissionType: MissionType,
  MissionLocation: MissionLocation,
  SymptomStart: SymptomStart,
  Destination: Destination,
};

export const CARDID_MAP: { [key: string]: CardId } = {
  Breathing: CardId.Breathing,
  Gcs: CardId.Gcs,
  LimbMovements: CardId.LimbMovements,
  Consciousness: CardId.Consciousness,
  NeurologicalFeatures: CardId.NeurologicalFeatures,
  Pupils: CardId.Pupils,
  Ecg: CardId.Ecg,
  Pain: CardId.Pain,
  Psyche: CardId.Psyche,
  Skin: CardId.Skin,
  VitalParameter: CardId.VitalParameter,
  InjuryActivity: CardId.InjuryActivity,
  InjuryCause: CardId.InjuryCause,
  InjuryLocation: CardId.InjuryLocation,
  InjuryAccidentMechanism: CardId.InjuryAccidentMechanism,
  NacaScore: CardId.NacaScore,
  DiagnosisNote: CardId.DiagnosisNote,
  PalliativeSituation: CardId.PalliativeSituation,

  PatientData: CardId.PatientData,
  Gender: CardId.Gender,
  Bmi: CardId.Bmi,
  Age: CardId.Age,
  Mission: CardId.Mission,
  Participants: CardId.Participants,
  MissionType: CardId.MissionType,
  MissionLocation: CardId.MissionLocation,
  SymptomStart: CardId.SymptomStart,
  Destination: CardId.Destination,
};
