import { MissionData } from '../backendModels/mission-data.model';

export class MissionDataChangeEvent extends Event {
  change: MissionData;

  constructor(type: string, newMissionData: MissionData) {
    super(type, { bubbles: true });

    this.change = newMissionData;
  }
}
