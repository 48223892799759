import React, { useContext } from 'react';
import globalStyle from '../../../../global.scss';
import { Button, ButtonGroup } from '@mui/material';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { InputState } from '../../../../backendModels/general.model';
import { useCSS } from '../../../../provider/CSSProvider';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import { NodeType } from '../../../../backendModels/report.model';
import { CardId } from '../../../../models/diviCard';
import { useFreshCallback } from '../../../../utils/hooks';

interface PainProps {
  nodeType: NodeType;
}

function Pain({ nodeType }: PainProps) {
  useCSS(globalStyle);

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.pain.type, nodeType);

  const selectedPainValue =
    record.inputState === InputState.ENTERED ? record.severity : record.inputState === InputState.NORMAL ? 0 : null;

  const painScale = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleValueChange = (buttonValue: number) => {
    reportAPI.adaptRecord(defaultRecords.pain.type, nodeType, (draft) => {
      if (buttonValue == 0) {
        return {
          id: draft.id,
          type: draft.type,
          inputState: InputState.NORMAL,
        };
      } else {
        return {
          ...draft,
          inputState: InputState.ENTERED,
          severity: buttonValue,
          sensitiveToPressure: false,
          sensitiveToPosture: false,
          radiatingPain: false,
          colicPain: false,
        };
      }
    });
  };

  const setToNormal = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.pain.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.NORMAL,
    }));
  });

  return (
    <DiviFieldCard
      cardType={CardId.Pain}
      onNormalStateButtonClicked={setToNormal}
      normalStateButtonLabel={'keine'}
      recordType={defaultRecords.pain.type}
      nodeType={nodeType}
    >
      <div id='pain-area'>
        <ButtonGroup
          disableElevation
          id='pain-button-group'
          className='full-width'
          key={'pain-group'}
          variant='outlined'
          aria-label='Basic button group'
        >
          {painScale.map((button) => (
            <Button
              className='full-width'
              onClick={() => handleValueChange(button)}
              variant={selectedPainValue === button ? 'contained' : 'outlined'}
              key={'pain-button-' + button}
            >
              {button}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </DiviFieldCard>
  );
}

export default Pain;
