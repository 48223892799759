import React, { useContext, useState } from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import DiviPage from '../../customs/DiviPage/DiviPage';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import { useCSS } from '../../../provider/CSSProvider';
import style from '../Handover/Handover.scss';
import { DiviNavigationIds } from '../../../models/diviProgress';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import Gcs from '../Neurology/Gcs/Gcs';
import { Breathing } from '../DataInitial/Breathing/Breathing';
import Consciousness from '../Neurology/Consciousness/Consciousness';
import Ecg from '../DataInitial/Ecg/Ecg';
import VitalParameter from '../DataInitial/VitalParameter/VitalParameter';
import Pain from '../DataInitial/Pain/Pain';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import dayjs from 'dayjs';
import TimeDialog from '../../customs/timeDialog/TimeDialog';
import { BLUTZUCKER_ERWEITERT_ELEMENT_KEY } from '../../../models/genericElements/bloodGlucose';
import { VITALPARAMETER_ERWEITERT_ELEMENT_KEY } from '../../../models/genericElements/vitalParameter';

export function Handover() {
  useCSS(style);

  const recordTypesOfPage = [
    'gcs',
    'pain',
    'ecgFindings',
    'breathing',
    'consciousness',
    'vitalParameters',
    'circulation',
  ] as (keyof BaseNodeRecordValueTypeMap)[];
  const elementKeysOfPage = [BLUTZUCKER_ERWEITERT_ELEMENT_KEY, VITALPARAMETER_ERWEITERT_ELEMENT_KEY];

  const nodeTypeOfPage = NodeType.HANDOVER;

  const [timeDialogOpen, setTimeDialogOpen] = useState<boolean>(false);
  const reportAPI = useContext(ReportsAPIContext);
  const timestamp = dayjs(reportAPI.getNodeTimestamp(nodeTypeOfPage) ?? undefined);

  function setTimestamp(newTimestamp: dayjs.Dayjs) {
    reportAPI.setNodeTimestamp(newTimestamp.valueOf(), nodeTypeOfPage);
  }

  function openTimeDialog() {
    setTimeDialogOpen(true);
  }

  const pageHeader = (
    <PageHeader
      heading={getPageTitle(PageType.Handover)}
      onTimeClicked={openTimeDialog}
      time={timestamp.format('HH:mm')}
    />
  );

  //ElementKeys are not passed to footer in order to ensure the no path button highlighting to function
  const pageFooter = (
    <PageFooter
      pageType={PageType.Handover}
      diviId={DiviNavigationIds.Handover}
      noPathButton
      recordTypes={recordTypesOfPage}
      elementKeys={elementKeysOfPage}
      nodeType={nodeTypeOfPage}
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      elementKeys={elementKeysOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Handover}
    >
      <TimeDialog
        timestamp={{ timestamp: timestamp, setTimestamp: setTimestamp }}
        dialogVisible={{ open: timeDialogOpen, setOpen: setTimeDialogOpen }}
      />
      <div className='wireframe-container-handover'>
        <div className='gcs'>
          <Gcs nodeType={nodeTypeOfPage} />
        </div>
        <div className='pain'>
          <Pain nodeType={nodeTypeOfPage} />
        </div>
        <div className='vital-params'>
          <VitalParameter nodeType={nodeTypeOfPage} />
        </div>
        <div className='ecg'>
          <Ecg nodeType={nodeTypeOfPage} />
        </div>
        <Breathing nodeType={nodeTypeOfPage} />
        <Consciousness nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
