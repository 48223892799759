import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { InputState } from '../../../../backendModels/general.model';
import React, { useContext } from 'react';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { skinLabels, SkinKeys } from '../../../../models/skin';
import style from './Skin.scss';
import { useCSS } from '../../../../provider/CSSProvider';
import { NodeType } from '../../../../backendModels/report.model';
import { CardId } from '../../../../models/diviCard';
import { useFreshCallback } from '../../../../utils/hooks';

interface SkinProps {
  nodeType: NodeType;
}

export function Skin({ nodeType }: SkinProps) {
  useCSS(style);

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.environment.type, nodeType);

  const setToNormal = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.environment.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.NORMAL,
    }));
  });

  const changeSymptoms = (values: SkinKeys[]) => {
    reportAPI.adaptRecord(defaultRecords.environment.type, nodeType, (draft, deleteRecord) => {
      if (values.length === 0) {
        deleteRecord();
      } else {
        return { ...draft, inputState: InputState.ENTERED, symptoms: values };
      }
    });
  };

  return (
    <div className='skin-card'>
      <DiviFieldCard
        cardType={CardId.Skin}
        recordType={defaultRecords.environment.type}
        nodeType={nodeType}
        onNormalStateButtonClicked={setToNormal}
        normalStateButtonLabel={'unauffällig'}
      >
        <CheckboxList
          className='symptoms-list'
          items={skinLabels}
          selectedValues={record.inputState === InputState.ENTERED ? (record.symptoms as SkinKeys[]) ?? [] : []}
          onValuesChange={changeSymptoms}
        />
      </DiviFieldCard>
    </div>
  );
}
