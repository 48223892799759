// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#card-injury-cause .symptoms-list{min-width:300px}#card-injury-cause .burn-scald-input{display:flex;align-items:center;gap:8px;margin:0 16px 16px}#card-injury-cause .burn-scald-input .grade,#card-injury-cause .burn-scald-input .percentage{width:100px}`, "",{"version":3,"sources":["webpack://./src/App/Pages/Injuries/InjuriesCause/InjuriesCause.scss"],"names":[],"mappings":"AACE,kCACE,eAAA,CAGF,qCACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,kBAAA,CAEA,6FAEE,WAAA","sourcesContent":["#card-injury-cause {\n  .symptoms-list {\n    min-width: 300px;\n  }\n\n  .burn-scald-input {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    margin: 0 16px 16px;\n\n    .grade,\n    .percentage {\n      width: 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
