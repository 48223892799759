import React, { useContext } from 'react';
import globalStyle from '../../../../global.scss';
import style from './Consciousness.scss';
import { FormControl } from '@mui/material';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import {
  ConsciousnessKeys,
  getConsciousnessTupelsFor,
  consciousnessRadioKeys,
  getRadioValue,
} from '../../../../models/consciousness';
import { InputState } from '../../../../backendModels/general.model';
import { useCSS } from '../../../../provider/CSSProvider';
import { CardId } from '../../../../models/diviCard';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import _ from 'lodash';
import RadioList from '../../../customs/radioList/RadioList';
import { useFreshCallback } from '../../../../utils/hooks';

const consciousnessRadioItems: [ConsciousnessKeys, string][] = getConsciousnessTupelsFor([
  ConsciousnessKeys.clouded,
  ConsciousnessKeys.unconscious,
]);

const consciousnessCheckboxItems: [ConsciousnessKeys, string][] = getConsciousnessTupelsFor([
  ConsciousnessKeys.reactionToSpeech,
  ConsciousnessKeys.reactionToPainStimulus,
  ConsciousnessKeys.narcotized,
]);

interface ConsciousnessProps {
  nodeType: NodeType;
}

function Consciousness({ nodeType }: ConsciousnessProps) {
  useCSS(style);
  useCSS(globalStyle);

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.consciousness.type, nodeType);

  const inputState = record.inputState;
  const isInputStateEntered = inputState === InputState.ENTERED;
  const symptoms = (isInputStateEntered ? record.symptoms ?? [] : []) as ConsciousnessKeys[];

  const handleCheckboxChange = (values: ConsciousnessKeys[]) => {
    reportAPI.adaptRecord(defaultRecords.consciousness.type, nodeType, (draft, deleteRecord) => {
      if (values.length > 0) {
        return { ...draft, inputState: InputState.ENTERED, symptoms: values };
      } else {
        deleteRecord();
      }
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    reportAPI.adaptRecord(defaultRecords.consciousness.type, nodeType, (draft) => {
      if (event.target.value === ConsciousnessKeys.woke) {
        return { id: draft.id, type: draft.type, inputState: InputState.NORMAL };
      } else {
        return {
          ...draft,
          inputState: InputState.ENTERED,
          symptoms: [..._.difference(symptoms, consciousnessRadioKeys), event.target.value],
        };
      }
    });
  };

  const setToNormal = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.consciousness.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.NORMAL,
    }));
  });

  const setToUnknown = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.consciousness.type, nodeType, (draft) => ({
      id: draft.id,
      type: draft.type,
      inputState: InputState.UNKNOWN,
    }));
  });

  return (
    <DiviFieldCard
      cardType={CardId.Consciousness}
      onNormalStateButtonClicked={setToNormal}
      normalStateButtonLabel='wach'
      onUnknownStateButtonClicked={setToUnknown}
      recordType={defaultRecords.consciousness.type}
      nodeType={nodeType}
    >
      <div id='consciousness-area'>
        <div className='symptoms-list row'>
          <FormControl>
            <RadioList
              items={consciousnessRadioItems}
              name='consciousness-group'
              value={getRadioValue(record)}
              onChange={handleRadioChange}
            />
          </FormControl>
          <CheckboxList
            items={consciousnessCheckboxItems}
            selectedValues={symptoms}
            onValuesChange={handleCheckboxChange}
          />
        </div>
      </div>
    </DiviFieldCard>
  );
}

export default Consciousness;
