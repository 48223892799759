import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum MedicalConditionCardiovascularKeys {
  acs = 'ACS',
  stemi = 'STEMI',
  stemiVorderwand = 'STEMI_VORDERWAND',
  stemiHinterwand = 'STEMI_HINTERWAND',
  rhythmusstoerung = 'RHYTHMUSSTOERUNG',
  rhythmusstoerungTachycard = 'RHYTHMUSSTOERUNG_TACHYCARD',
  rhythmusstoerungBradycard = 'RHYTHMUSSTOERUNG_BRADYCARD',
  lungenembolie = 'LUNGENEMBOLIE',
  orthostase = 'ORTHOSTASE',
  herzinsuffizienz = 'HERZINSUFFIZIENZ',
  lungenoedem = 'LUNGENOEDEM',
  hypertensiverNotfall = 'HYPERTENSITIVER_NOTFALL',
  kardiogenerSchock = 'KARDIOGENER_SCHOCK',
  schrittmacherFehler = 'SCHRITTMACHER_FEHLER',
}

export const MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY = 'ERKRANKUNGEN_KREISLAUF';

export type MedicalConditionCardiovascularRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          ERKRANKUNGEN_KREISLAUF?: MultiSelectFieldValueTyped<MedicalConditionCardiovascularKeys>;
          ERKRANKUNGEN_KREISLAUF_SONSTIGE?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

const cardiovascularLabels: Record<MedicalConditionCardiovascularKeys, string> = {
  [MedicalConditionCardiovascularKeys.acs]: 'Akutes Koronarsyndrom',
  [MedicalConditionCardiovascularKeys.stemi]: 'STEMI',
  [MedicalConditionCardiovascularKeys.stemiVorderwand]: 'Vorderwand',
  [MedicalConditionCardiovascularKeys.stemiHinterwand]: 'Hinterwand',
  [MedicalConditionCardiovascularKeys.rhythmusstoerung]: 'Rhythmusstörung',
  [MedicalConditionCardiovascularKeys.rhythmusstoerungTachycard]: 'tachy',
  [MedicalConditionCardiovascularKeys.rhythmusstoerungBradycard]: 'brady',
  [MedicalConditionCardiovascularKeys.lungenembolie]: 'Lungenembolie',
  [MedicalConditionCardiovascularKeys.orthostase]: 'orthostatische Fehlregulation',
  [MedicalConditionCardiovascularKeys.herzinsuffizienz]: 'Herzinsuffizienz',
  [MedicalConditionCardiovascularKeys.lungenoedem]: 'Lungenödem',
  [MedicalConditionCardiovascularKeys.hypertensiverNotfall]: 'hypertensiver Notfall / hypertensive Krise',
  [MedicalConditionCardiovascularKeys.kardiogenerSchock]: 'kardiogener Shock',
  [MedicalConditionCardiovascularKeys.schrittmacherFehler]: 'Schrittmacher- / ICD-Fehlfunktion',
};

export function getCardiovascularTupel(
  key: MedicalConditionCardiovascularKeys,
): [MedicalConditionCardiovascularKeys, string] {
  return [key, cardiovascularLabels[key]];
}

export function getCardiovascularTupelsFor(
  keys: MedicalConditionCardiovascularKeys[],
): [MedicalConditionCardiovascularKeys, string][] {
  return keys.map((k) => {
    return getCardiovascularTupel(k);
  });
}
