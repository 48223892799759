export const corpulsOrange = {
  main: '#fb7324',
  light: '#fed5bd',
  dark: '#f95616',
  contrastText: '#fff',
};

export const corpulsGreen = {
  main: '#80b823',
  light: '#d9eabd',
  dark: '#63a015',
  contrastText: '#fff',
};

export const corpulsBlue = {
  main: '#4a90e2',
  light: '#c9def6',
  dark: '#3273d6',
  contrastText: '#fff',
};

export const corpulsYellow = {
  main: '#F5A623',
};
