export function getGCSOcularNames(isAdult: boolean): [number, string][] {
  if (isAdult) {
    return [
      [0, 'nicht vorhanden'],
      [1, 'auf Druck'],
      [2, 'auf Geräusch'],
      [3, 'spontan'],
    ];
  }
  return [
    [0, 'keine'],
    [1, 'auf Schmerzreiz'],
    [2, 'auf Schreien'],
    [3, 'spontan'],
  ];
}

export function getGCSOralNames(isAdult: boolean): [number, string][] {
  if (isAdult) {
    return [
      [0, 'keine'],
      [1, 'Laute'],
      [2, 'Wörter'],
      [3, 'verwirrt'],
      [4, 'orientiert'],
    ];
  }
  return [
    [0, 'keine'],
    [1, 'Stöhnen, unverständliche Laute'],
    [2, 'Schreien untröstbar'],
    [3, 'Schreien tröstbar'],
    [4, 'Plappern, Brabbeln'],
  ];
}

export function getGCSMotoricNames(isAdult: boolean): [number, string][] {
  if (isAdult) {
    return [
      [0, 'keine'],
      [1, 'streckt'],
      [2, 'beugt abnormal'],
      [3, 'beugt normal'],
      [4, 'lokalisiert'],
      [5, 'folgt Aufforderung'],
    ];
  }
  return [
    [0, 'keine'],
    [1, 'Strecksynergismen'],
    [2, 'auf Schmerzreiz abnorme Abwehr'],
    [3, 'auf Schmerzreiz Beugeabwehr'],
    [4, 'auf Schmerzreiz gezielt'],
    [5, 'Spontane Bewegungen'],
  ];
}
