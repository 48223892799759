import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  ApplicationMethod,
  Dose,
  Medication,
  MedicationDoseUnit,
  MedicationSource,
  MedicationValue,
} from '../../../../backendModels/medication.model';
import { Autocomplete, TextField } from '@mui/material';
import {
  convertToApplicationMethodLabels,
  convertToMedicationListLabels,
  dosageUnitLabels,
} from '../../../../models/medication';
import CreatableAutocomplete, { OptionValueType } from '../../../customs/creatableAutocomplete/CreatableAutocomplete';
import TextFieldSelectGroup from '../../../customs/textFieldSelectGroup/TextFieldSelectGroup';
import _ from 'lodash';
import { CheckCircleOutline } from '@mui/icons-material';
import style from './AddMedicationDialog.scss';
import { useCSS } from '../../../../provider/CSSProvider';

function createMedication(
  source: MedicationSource,
  name?: string,
  dose?: Dose,
  applicationMethod?: string,
): MedicationValue {
  if (source === MedicationSource.CONFIGURATION) {
    return {
      key: name,
      dose: dose,
      applicationMethod: applicationMethod,
      source: source,
    };
  } else {
    return {
      customActiveSubstance: name,
      dose: dose,
      applicationMethod: applicationMethod,
      source: source,
    };
  }
}

interface AddMedicationDialogProps {
  /**
   * The list of all available medications coming from the configuration.
   */
  medicationList: readonly Medication[];
  /**
   * The list of all application methods coming from the configuration.
   */
  applicationMethods: readonly ApplicationMethod[];
  open: boolean;
  onOpenChanged: (open: boolean) => void;
  /**
   * Callback that is invoked when a new medication is added.
   * @param medication The medication that was added.
   */
  addMedication: (medication: MedicationValue) => void;
}
export default function AddMedicationDialog(props: AddMedicationDialogProps) {
  useCSS(style);

  const { medicationList, applicationMethods, open, onOpenChanged: setOpen, addMedication } = props;

  const [medicationName, setMedicationName] = useState<string | undefined>(undefined);
  const [dosageValue, setDosageValue] = useState<number | undefined>(undefined);
  const [dosageUnit, setDosageUnit] = useState<MedicationDoseUnit>(MedicationDoseUnit.MILLIGRAM);
  const [applicationMethod, setApplicationMethod] = useState<string | undefined>(undefined);
  const [medicationSource, setMedicationSource] = useState<MedicationSource>(MedicationSource.CONFIGURATION);
  const [isConfirmed, setIsConfirmed] = useState(false);

  function isAddable(): boolean {
    return (
      medicationName !== undefined &&
      dosageValue !== undefined &&
      dosageUnit !== undefined &&
      applicationMethod !== undefined
    );
  }

  function handleAdd() {
    if (!isAddable()) {
      return;
    }
    const dose: Dose = {
      value: dosageValue!,
      unit: dosageUnit!,
    };
    const medication = createMedication(medicationSource, medicationName, dose, applicationMethod);
    addMedication(medication);
    setIsConfirmed(true);

    setTimeout(handleClose, 1000);
  }

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      setMedicationName(undefined);
      setDosageValue(undefined);
      setDosageUnit(MedicationDoseUnit.MILLIGRAM);
      setApplicationMethod(undefined);
      setMedicationSource(MedicationSource.CONFIGURATION);
      setIsConfirmed(false);
    }, 200);
  }

  function medicationKeyChanged(optionValue: OptionValueType<string>) {
    if (optionValue.customInputValue === undefined) {
      setMedicationSource(MedicationSource.CONFIGURATION);
    } else {
      setMedicationSource(MedicationSource.CUSTOM);
    }
    setMedicationName(optionValue.value);
    if (optionValue.customInputValue === undefined) {
      const medication = _.find(medicationList, { key: optionValue.value });
      if (medication?.defaultUnit != null) {
        setDosageUnit(medication.defaultUnit);
      }
      if (medication?.defaultApplicationMethod != null) {
        setApplicationMethod(medication.defaultApplicationMethod);
      }
    }
  }

  function dosageValueChanged(dosage: string) {
    if (dosage !== '') {
      setDosageValue(Number(dosage));
    } else {
      setDosageValue(undefined);
    }
  }

  const applicationMethodLabels = useMemo(
    () => convertToApplicationMethodLabels(applicationMethods),
    [applicationMethods],
  );

  const medicationOptions = useMemo(() => convertToMedicationListLabels(medicationList), [medicationList]);

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{ className: 'add-medication-dialog' }}>
      <DialogTitle>Medikation hinzufügen</DialogTitle>
      <DialogContent>
        {isConfirmed ? (
          <div className='add-medication-dialog-confirmed'>
            <CheckCircleOutline className='check-circle' />
          </div>
        ) : (
          <div>
            <CreatableAutocomplete
              options={medicationOptions}
              newValueCreatorFunction={(value: string) => value}
              onChange={medicationKeyChanged}
              label='Medikament'
            />
            <TextFieldSelectGroup
              fullWidth
              type='number'
              label={`Dosis [${dosageUnitLabels[dosageUnit]}]`}
              options={Object.entries(dosageUnitLabels) as [MedicationDoseUnit, string][]}
              selectedOption={dosageUnit}
              onSelectedOptionChange={setDosageUnit}
              onChange={(event) => dosageValueChanged(event.target.value)}
              value={dosageValue?.toString() ?? ''}
            />
            <Autocomplete
              options={applicationMethodLabels}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(event, value) => setApplicationMethod(value?.value)}
              renderInput={(params) => <TextField {...params} label='Applikationsform' />}
              value={_.find(applicationMethodLabels, { value: applicationMethod }) ?? null}
            />
          </div>
        )}
      </DialogContent>
      {!isConfirmed && (
        <DialogActions>
          <Button className='grey-button' onClick={handleClose} variant='contained'>
            Abbrechen
          </Button>
          <Button onClick={handleAdd} autoFocus disabled={!isAddable()} variant='contained'>
            Hinzufügen
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
