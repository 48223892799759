import React, { ReactNode, useContext, useEffect } from 'react';
import { Typography } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import style from './DiviFieldCard.scss';
import ToggleButton from '../toggleButton/ToggleButton';
import { useCSS } from '../../../provider/CSSProvider';
import { useDiviPageContext } from '../DiviPage/DiviPage';
import { CardId, getCardTitle } from '../../../models/diviCard';
import { ReactComponent as HeartPlusIcon } from '../../../assets/images/icons/heart-plus.svg';
import { ReactComponent as HeartCheckedIcon } from '../../../assets/images/icons/heart-checked.svg';
import { useTheme } from '@mui/material/styles';
import { useFreshCallback } from '../../../utils/hooks';
import { NodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../backendModels/report.model';
import { getEnhancedInputState } from '../../../models/custom';
import { InputState } from '../../../backendModels/general.model';
import WarningIcon from '@mui/icons-material/Warning';

interface CardProps {
  /**
   * The type of the card.
   */
  cardType: CardId;
  /**
   * Whether we should display no title.
   */
  showTitle?: boolean;
  /**
   * Custom title content when title is not a text.
   */
  customTitleContent?: ReactNode;
  /**
   * Sub-Title text.
   */
  subTitle?: string;
  /**
   * Is invoked when the noPathologicalFindingsButton is clicked.
   */
  onNormalStateButtonClicked?: () => void;
  /**
   * Displays "ohne path. Befund" button when true. Defaults to onNormalStateButtonClicked != null.
   */
  normalStateButtonVisible?: boolean;
  /**
   * Label displayed on the normal state text button. Defaults to 'ohne path. Befund'
   */
  normalStateButtonLabel?: string;
  /**
   * Is invoked when the notAccessibleButton is clicked.
   */
  onUnknownStateButtonClicked?: () => void;
  /**
   * Displays "nicht beurteilbar" button when true. Defaults to onUnknownStateButtonClicked != null.
   */
  unknownStateButtonVisible?: boolean;
  /**
   * Label displayed on the unknown state text button. Defaults to 'nicht beurteilbar'.
   */
  unknownStateButtonLabel?: string;

  /**
   * The node type used to determine the input state
   */
  nodeType?: NodeType;
  /**
   * The record type used to determine the input state
   */
  recordType?: keyof NodeRecordValueTypeMap;
  /**
   * The elementKey for generic records
   */
  elementKey?: string;
  /**
   * When true, disables elevation of the card.
   */
  disableElevation?: boolean;
  /**
   * Shows warning message in title header.
   */
  warningMessage?: ReactNode;
  children?: ReactNode;
}

export default function DiviFieldCard(props: CardProps) {
  useCSS(style);
  const theme = useTheme();

  const {
    cardType,
    showTitle,
    customTitleContent,
    subTitle,
    onNormalStateButtonClicked,
    onUnknownStateButtonClicked,
    normalStateButtonVisible,
    unknownStateButtonVisible,
    normalStateButtonLabel,
    unknownStateButtonLabel,
    recordType,
    elementKey,
    nodeType,
    disableElevation,
    warningMessage,
    children,
  } = props;

  const { findMultipleRecords } = useContext(ReportsAPIContext);
  const records = recordType && nodeType ? findMultipleRecords(recordType, nodeType, elementKey) : [];

  const { subscribeToReset, unsubscribeFromReset } = useDiviPageContext();
  const displayTitle = showTitle ?? true;

  const inputState = recordType != null && nodeType != null ? getEnhancedInputState(recordType, records) : null;

  const handleNormalStateButtonClicked = useFreshCallback(() => {
    if (onNormalStateButtonClicked) {
      onNormalStateButtonClicked();
    }
  });

  const handleUnknownStateButtonClicked = useFreshCallback(() => {
    if (onUnknownStateButtonClicked) {
      onUnknownStateButtonClicked();
    }
  });

  useEffect(() => {
    subscribeToReset(handleNormalStateButtonClicked);
    return () => unsubscribeFromReset(handleNormalStateButtonClicked);
  }, [subscribeToReset, unsubscribeFromReset, handleNormalStateButtonClicked]);

  return (
    <div
      id={`card-${cardType.toString()}`}
      className={`card${disableElevation ? ' disable-elevation' : ''}${warningMessage ? ' warning-message' : ''}`}
    >
      {customTitleContent === undefined && displayTitle ? (
        <div className='title-content'>
          <Typography align='left' variant='h2'>
            {getCardTitle(cardType)}
          </Typography>
          {warningMessage && (
            <div className='warning-message-content'>
              <WarningIcon color='warning' className='warning-message-icon' />
              <Typography className='warning-message-description' variant='caption'>
                {warningMessage}
              </Typography>
            </div>
          )}
        </div>
      ) : (
        customTitleContent
      )}
      {subTitle && (
        <Typography align='left' variant='subtitle1'>
          {subTitle}
        </Typography>
      )}
      {displayTitle &&
      !(
        (normalStateButtonVisible ?? onNormalStateButtonClicked) ||
        (unknownStateButtonVisible ?? onUnknownStateButtonClicked)
      ) ? (
        <div className='title-spacer' />
      ) : (
        <></>
      )}
      {((normalStateButtonVisible ?? onNormalStateButtonClicked) ||
        (unknownStateButtonVisible ?? onUnknownStateButtonClicked)) && (
        <div className='divi-field-card-button-row'>
          {(normalStateButtonVisible ?? onNormalStateButtonClicked) && (
            <ToggleButton
              onClick={handleNormalStateButtonClicked}
              disableElevation
              selected={inputState === InputState.NORMAL}
              color='secondary'
              selectedIcon={<HeartCheckedIcon fill={theme.palette.secondary.main} />}
              unselectedIcon={<HeartPlusIcon fill={theme.palette.secondary.main} />}
              className='no-path-button'
              variant={inputState === InputState.NORMAL ? 'contained' : 'text'}
            >
              {normalStateButtonLabel ?? 'ohne path. Befund'}
            </ToggleButton>
          )}
          {(unknownStateButtonVisible ?? onUnknownStateButtonClicked) && (
            <ToggleButton
              onClick={handleUnknownStateButtonClicked}
              disableElevation
              selected={inputState === InputState.UNKNOWN}
              color='info'
              selectedIcon={<HelpOutlinedIcon color='info' />}
              unselectedIcon={<HelpOutlineOutlinedIcon color='info' />}
              className='unknown-button'
              variant={inputState === InputState.UNKNOWN ? 'contained' : 'text'}
            >
              {unknownStateButtonLabel ?? 'nicht beurteilbar'}
            </ToggleButton>
          )}
        </div>
      )}
      <div className='main-content'>{children}</div>
    </div>
  );
}
