import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import { CardId } from '../../../../models/diviCard';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './RefusalOfMeasures.scss';
import {
  getRefusalOfMeasuresTupleFor,
  RefusalOfMeasuresKeys,
  REMARKS_RECORD_ELEMENT_KEY,
  RemarksRecord,
} from '../../../../models/genericElements/remarks';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import { isGenericRecordDeletable } from '../../../../models/generic';

interface RefusalOfMeasuresProps {
  nodeType: NodeType;
}

const refusalOfMeasuresItem: [RefusalOfMeasuresKeys, string][] = getRefusalOfMeasuresTupleFor([
  RefusalOfMeasuresKeys.massnahmenVerweigerung,
]);

export default function RefusalOfMeasures({ nodeType }: RefusalOfMeasuresProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, REMARKS_RECORD_ELEMENT_KEY) as RemarksRecord;

  function handleRefusalOfMeasuresChange(value: RefusalOfMeasuresKeys[]) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<RemarksRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length !== 0) {
          draft.values = {
            ...draft.values,
            BEMERKUNGEN_VERWEIGERUNG: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.BEMERKUNGEN_VERWEIGERUNG;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      REMARKS_RECORD_ELEMENT_KEY,
    );
  }

  return (
    <DiviFieldCard cardType={CardId.RefusalOfMeasures} showTitle={false} disableElevation={true}>
      <CheckboxList
        formControlLabelProps={{ className: 'refusal-of-measures-label' }}
        items={refusalOfMeasuresItem}
        selectedValues={
          record.inputState === InputState.ENTERED ? record.values?.BEMERKUNGEN_VERWEIGERUNG?.options ?? [] : []
        }
        onValuesChange={handleRefusalOfMeasuresChange}
      />
    </DiviFieldCard>
  );
}
