import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum MedicalConditionPsychiatryKeys {
  psychose = 'PSYCHOSE',
  angst = 'ANGST',
  intoxikation = 'INTOXIKATION',
  intoxikationAkzidentiell = 'INTOXIKATION_AKZIDENTIELL',
  intoxikationAlkohol = 'INTOXIKATION_ALKOHOL',
  intoxikationDrogen = 'INTOXIKATION_DROGEN',
  intoxikationMedis = 'INTOXIKATION_MEDIS',
  intoxikationSonstige = 'INTOXIKATION_SONSTIGE',
  entzug = 'ENTZUG',
  suizid = 'SUIZID',
  psychKrise = 'PSYCH_KRISE',
}

export const MEDICAL_CONDITION_PSYCHIATRY_ELEMENT_KEY = 'ERKRANKUNGEN_PSYCH';

export type MedicalConditionPsychiatryRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MEDICAL_CONDITION_PSYCHIATRY_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          ERKRANKUNGEN_PSYCH?: MultiSelectFieldValueTyped<MedicalConditionPsychiatryKeys>;
          ERKRANKUNGEN_PSYCH_SONSTIGE?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

const psychiatryLabels: Record<MedicalConditionPsychiatryKeys, string> = {
  [MedicalConditionPsychiatryKeys.psychose]: 'Psychose, Manie, Erregungszustand',
  [MedicalConditionPsychiatryKeys.angst]: 'Angst, Depression',
  [MedicalConditionPsychiatryKeys.intoxikation]: 'Intoxikation',
  [MedicalConditionPsychiatryKeys.intoxikationAkzidentiell]: 'akzidentiell',
  [MedicalConditionPsychiatryKeys.intoxikationAlkohol]: 'Alkohol',
  [MedicalConditionPsychiatryKeys.intoxikationDrogen]: 'Drogen',
  [MedicalConditionPsychiatryKeys.intoxikationMedis]: 'Medikamente',
  [MedicalConditionPsychiatryKeys.intoxikationSonstige]: 'Sonstiges',
  [MedicalConditionPsychiatryKeys.entzug]: 'Entzug, Delir',
  [MedicalConditionPsychiatryKeys.suizid]: 'Suizid(versuch)',
  [MedicalConditionPsychiatryKeys.psychKrise]: 'Psychosoziale Krise',
};

export function getPsychiatryTupel(key: MedicalConditionPsychiatryKeys): [MedicalConditionPsychiatryKeys, string] {
  return [key, psychiatryLabels[key]];
}

export function getPsychiatryTupelsFor(
  keys: MedicalConditionPsychiatryKeys[],
): [MedicalConditionPsychiatryKeys, string][] {
  return keys.map((k) => {
    return getPsychiatryTupel(k);
  });
}
