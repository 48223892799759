import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import { NodeType } from '../../../../backendModels/report.model';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { CardId, getCardTitle } from '../../../../models/diviCard';
import { useFreshCallback } from '../../../../utils/hooks';
import { PageType } from '../../../../models/diviPage';
import { SAMPLER_PLUS_S_RECORD_ELEMENT_KEY, SAMPLERPlusSRecord } from '../../../../models/genericElements/samplerPlusS';
import RecordInputInfo from '../../../customs/recordInputInfo/RecordInputInfo';
import { isGenericRecordDeletable } from '../../../../models/generic';

interface AnamneseProps {
  nodeType: NodeType;
}

export default function AnamneseNote({ nodeType }: AnamneseProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(defaultRecords.situationAndSymptoms.type, nodeType);
  const samplerPlusSRecord = findRecordOrDefault(
    'generic',
    nodeType,
    SAMPLER_PLUS_S_RECORD_ELEMENT_KEY,
  ) as SAMPLERPlusSRecord;

  const note = record.note || '';

  const changeNote = useFreshCallback((value: string) => {
    adaptRecord(defaultRecords.situationAndSymptoms.type, nodeType, (draft, deleteRecord) => {
      if ((!draft.situationAndSymptoms || draft.situationAndSymptoms.length === 0) && value === '') {
        deleteRecord();
      } else if (value !== '') {
        draft.note = value;
      }
    });
  });

  return (
    <DiviFieldCard cardType={CardId.AnamneseNote} showTitle={false}>
      <DebouncedTextField
        className='no-margin-top'
        label={getCardTitle(CardId.AnamneseNote)}
        placeholder={getCardTitle(CardId.AnamneseNote)}
        multiline
        fullWidth
        rows={10}
        onDebounceChange={changeNote}
        value={note}
      />
      <RecordInputInfo
        className='add-margin-top'
        show={!isGenericRecordDeletable(samplerPlusSRecord)}
        page={PageType.SAMPLERPlusS}
      />
    </DiviFieldCard>
  );
}
