import { RecordId } from '../backendModels/records.model';
import { VitalParametersValue, VitalParameterType, VitalParameterValue } from '../backendModels/vital-parameters.model';

export type VitalParametersRecord = {
  readonly id?: RecordId;
  readonly type: 'vitalParameters';
} & VitalParametersValue;

export enum VitalParameterKeys {
  nonInvasiveSystolicArterialPressure = 'NIBP_SYS', // left RR field
  nonInvasiveDiastolicArterialPressure = 'NIBP_DIA', // right RR field
  heartRate = 'HR', // HF field
  bloodGlucose = 'BG', // BZ field
  totalBreathRate = 'RR', // AF field
  capillaryOxygenSaturation = 'SPO2', // SPO2 field
  temperature = 'TEMP1', // Temp field
  endTidalCarbonDioxideTension = 'CO2', // etCO2 field
}

export type VitalParametersMappingType = Partial<{ [key in VitalParameterType]: VitalParameterValue }>;
export const convertVitalParametersToMapping = (
  vitalParameters: readonly VitalParameterValue[],
): VitalParametersMappingType => {
  return vitalParameters.reduce<VitalParametersMappingType>(
    (previousValue, currentValue) => {
      previousValue[currentValue.type] = currentValue;
      return previousValue;
    },

    {} as VitalParametersMappingType,
  );
};

export enum VitalParameterUnitKeys {
  millimolePerLiter = 'MILLIMOLE_PER_LITER',
  milligramPerDeciliter = 'MILLIGRAM_PER_DECILITER',
  millimeterOfMercury = 'MILLIMETER_OF_MERCURY',
  perMin = 'PER_MIN',
  percent = 'PERCENT',
  celsius = 'CELSIUS',
}

export type BloodGlucoseUnitLabelsType = Partial<{ [key in VitalParameterUnitKeys]: string }>;

export const BloodGlucoseUnitLabels: BloodGlucoseUnitLabelsType = {
  [VitalParameterUnitKeys.milligramPerDeciliter]: 'mg/dl',
  [VitalParameterUnitKeys.millimolePerLiter]: 'mmol/l',
};

export type DefaultUnitsType = { [key in VitalParameterKeys]: VitalParameterUnitKeys };

export const defaultUnit: DefaultUnitsType = {
  [VitalParameterKeys.nonInvasiveSystolicArterialPressure]: VitalParameterUnitKeys.millimeterOfMercury,
  [VitalParameterKeys.nonInvasiveDiastolicArterialPressure]: VitalParameterUnitKeys.millimeterOfMercury,
  [VitalParameterKeys.heartRate]: VitalParameterUnitKeys.perMin,
  [VitalParameterKeys.bloodGlucose]: VitalParameterUnitKeys.milligramPerDeciliter,
  [VitalParameterKeys.totalBreathRate]: VitalParameterUnitKeys.perMin,
  [VitalParameterKeys.capillaryOxygenSaturation]: VitalParameterUnitKeys.percent,
  [VitalParameterKeys.temperature]: VitalParameterUnitKeys.celsius,
  [VitalParameterKeys.endTidalCarbonDioxideTension]: VitalParameterUnitKeys.millimeterOfMercury,
};

export type VitalParameterUnitType = {
  [key in VitalParameterKeys]?: VitalParameterUnitKeys;
};

export const VITAL_PARAMETER_UNIT_STORAGE_KEY = 'VITAL_PARAMETER_UNITS';
