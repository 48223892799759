export enum PsycheKeys {
  notExamined = 'NICHT_UNTERSUCHT',
  excited = 'ERREGT',
  delusional = 'WAHNHAFT',
  depressed = 'DEPRESSIV',
  confused = 'VERWIRRT',
  aggressive = 'AGGRESSIV',
  anxious = 'AENGSTLICH',
  suicidal = 'SUIZIDAL',
  slowedDown = 'VERLANGSAMT',
  euphoric = 'EUPHORISCH',
  motoricallyRestless = 'MOTORISCH_UNRUHIG',
}

export const psycheLabels: Record<PsycheKeys, string> = {
  [PsycheKeys.notExamined]: 'nicht untersucht',
  [PsycheKeys.delusional]: 'wahnhaft',
  [PsycheKeys.excited]: 'erregt',
  [PsycheKeys.depressed]: 'depressiv',
  [PsycheKeys.confused]: 'verwirrt',
  [PsycheKeys.aggressive]: 'aggressiv',
  [PsycheKeys.anxious]: 'ängstlich',
  [PsycheKeys.suicidal]: 'suizidal',
  [PsycheKeys.slowedDown]: 'verlangsamt/stuporös',
  [PsycheKeys.euphoric]: 'euporisch',
  [PsycheKeys.motoricallyRestless]: 'motorisch unruhig',
};

export function getPsycheTuple(key: PsycheKeys): [PsycheKeys, string] {
  return [key, psycheLabels[key]];
}

export function getPsycheTuplesFor(keys: PsycheKeys[]): [PsycheKeys, string][] {
  return keys.map((k) => {
    return getPsycheTuple(k);
  });
}
