import React, { useContext, useState } from 'react';
import globalStyle from '../../../../global.scss';
import style from './Gcs.scss';
import { Link, ToggleButton } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import BoyIcon from '@mui/icons-material/Boy';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import ScoreButtonList from '../../../customs/scoreButtonList/ScoreButtonList';
import { GCS_NORMAL_STATE } from '../../../../backendModels/gcs.model';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { useCSS } from '../../../../provider/CSSProvider';
import MedicalScoreDisplay from '../../../customs/medicalScoreDisplay/MedicalScoreDisplay';
import { NodeType } from '../../../../backendModels/report.model';
import { getGCSMotoricNames, getGCSOcularNames, getGCSOralNames } from '../../../../models/gcs';
import { CardId } from '../../../../models/diviCard';
import { useFreshCallback } from '../../../../utils/hooks';
import QSofa from '../../../customs/qSofa/qsofa';

interface GcsProps {
  nodeType: NodeType;
}

function Gcs({ nodeType }: GcsProps) {
  useCSS(style);
  useCSS(globalStyle);

  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.gcs.type, nodeType);

  const [maturity, setMaturity] = useState('Adult');

  const ocularRating = record.ocularResponse;
  const oralRating = record.oralResponse;
  const motoricRating = record.motoricResponse;

  const gcsRating =
    ocularRating != null && oralRating != null && motoricRating != null
      ? ocularRating + oralRating + motoricRating
      : null;

  const handleChangeEye = (index: number) => {
    reportAPI.adaptRecord(defaultRecords.gcs.type, nodeType, (draft) => {
      draft.ocularResponse = index + 1;
    });
  };
  const handleChangeVerbal = (index: number) => {
    reportAPI.adaptRecord(defaultRecords.gcs.type, nodeType, (draft) => {
      draft.oralResponse = index + 1;
    });
  };
  const handleChangeMotoric = (index: number) => {
    reportAPI.adaptRecord(defaultRecords.gcs.type, nodeType, (draft) => {
      draft.motoricResponse = index + 1;
    });
  };

  const handleMaturityChange = () => {
    maturity == 'Adult' ? setMaturity('Child') : setMaturity('Adult');
  };

  const setToNormal = useFreshCallback(() => {
    reportAPI.adaptRecord(defaultRecords.gcs.type, nodeType, (draft) => ({
      ...draft,
      motoricResponse: GCS_NORMAL_STATE.motoricResponse,
      oralResponse: GCS_NORMAL_STATE.oralResponse,
      ocularResponse: GCS_NORMAL_STATE.ocularResponse,
    }));
  });

  const customTitleContent = (
    <div className='row gcs-toggle'>
      <ToggleButtonGroup value={maturity} color='primary' size='small' exclusive onChange={handleMaturityChange}>
        <ToggleButton value='Adult' aria-label='left aligned'>
          <BoyIcon fontSize='large' />
        </ToggleButton>
        <ToggleButton value='Child' aria-label='centered'>
          <BoyIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <MedicalScoreDisplay
        asHeader={true}
        className={'gcs-score'}
        header={`[${maturity == 'Adult' ? 'Erwachsener' : 'Kind'}] Glasgow Coma Scale:`}
        medicalScore={gcsRating}
        description={
          <div>
            Zur Summierung der GCS wird nur der Wert der Extremität mit der besten motorischen Reaktion verwendet
            siehe&nbsp;
            <Link href='https://glasgowcomascale.org' color='inherit'>
              www.glasgowcomascale.org
            </Link>
          </div>
        }
      />
    </div>
  );

  return (
    <DiviFieldCard
      cardType={CardId.Gcs}
      customTitleContent={customTitleContent}
      onNormalStateButtonClicked={setToNormal}
      recordType={defaultRecords.gcs.type}
      nodeType={nodeType}
    >
      <div className='row gcs-row'>
        <div className='column'>
          <ScoreButtonList
            title='Augen öffnen'
            reverse={true}
            listOfEntries={getGCSOcularNames(maturity == 'Adult')}
            selectedValue={ocularRating !== undefined ? ocularRating - 1 : GCS_NORMAL_STATE.ocularResponse}
            onChange={handleChangeEye}
          />
          <QSofa nodeType={nodeType} />
        </div>
        <div>
          <ScoreButtonList
            title='beste verbale Reaktion'
            reverse={true}
            listOfEntries={getGCSOralNames(maturity == 'Adult')}
            selectedValue={oralRating !== undefined ? oralRating - 1 : GCS_NORMAL_STATE.oralResponse}
            onChange={handleChangeVerbal}
          />
        </div>
        <div>
          <ScoreButtonList
            title='beste motorische Reaktion'
            reverse={true}
            listOfEntries={getGCSMotoricNames(maturity == 'Adult')}
            selectedValue={motoricRating !== undefined ? motoricRating - 1 : GCS_NORMAL_STATE.motoricResponse}
            onChange={handleChangeMotoric}
          />
        </div>
      </div>
    </DiviFieldCard>
  );
}

export default Gcs;
