// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snackbar-alert{box-shadow:0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px rgba(0,0,0,.1411764706),0 1px 18px rgba(0,0,0,.1215686275);border-radius:4px;position:absolute !important}`, "",{"version":3,"sources":["webpack://./src/App/customs/snackbarAlert/SnackbarAlert.scss"],"names":[],"mappings":"AAAA,gBACE,8GACE,CAGF,iBAAA,CACA,4BAAA","sourcesContent":[".snackbar-alert {\n  box-shadow:\n    0 3px 5px -1px #0003,\n    0 6px 10px #00000024,\n    0 1px 18px #0000001f;\n  border-radius: 4px;\n  position: absolute !important;\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
