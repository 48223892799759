export enum EcgKeys {
  absolutearrhythmie = 'ABSOLUTE_ARRHYTHMIE',
  avblockii = 'AV_BLOCK_II',
  avblockiii = 'AV_BLOCK_III',
  schrittmacherrhythmus = 'SCHRITTMACHERRHYTHMUS',
  kammerflimmern = 'KAMMERFLIMMERN',
  peaemd = 'PEA_EMD',
  asystolie = 'ASYSTOLIE',
  stemi = 'STEMI',
  schmaleqrstachykardie = 'SCHMALE_QRS_TACHYKARDIE',
  breiteqrstachykardie = 'BREITE_QRS_TACHYKARDIE',
  svesvesmonomorph = 'SVES_VES_MONOMORPH',
  vespolymorph = 'VES_POLYMORPH',
  noEkg = 'KEIN_EKG',
}

const ecgLabels: Record<EcgKeys, string> = {
  [EcgKeys.absolutearrhythmie]: 'Abs. Arrhythmie',
  [EcgKeys.avblockii]: 'AV-Block II°',
  [EcgKeys.avblockiii]: 'AV-Block III°',
  [EcgKeys.schrittmacherrhythmus]: 'Schrittmacherrhythmus',
  [EcgKeys.kammerflimmern]: 'Kammerflimmern',
  [EcgKeys.peaemd]: 'PEA / EMD',
  [EcgKeys.asystolie]: 'Asystolie',
  [EcgKeys.stemi]: 'STEMI',
  [EcgKeys.schmaleqrstachykardie]: 'schmale QRS-Tachykardie',
  [EcgKeys.breiteqrstachykardie]: 'breite QRS-Tachykardie',
  [EcgKeys.svesvesmonomorph]: 'SVES / VES monomorph',
  [EcgKeys.vespolymorph]: 'VES polymorph',
  [EcgKeys.noEkg]: 'kein EKG',
};
export function getEcgTuple(key: EcgKeys): [EcgKeys, string] {
  return [key, ecgLabels[key]];
}

export function getEcgTuplesFor(keys: EcgKeys[]): [EcgKeys, string][] {
  return keys.map((k) => {
    return getEcgTuple(k);
  });
}
