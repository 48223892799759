import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import { AnticoagualantsKeys, getAnticoagualantsTuplesFor } from '../../../../models/medication';
import { InputState } from '../../../../backendModels/general.model';
import { CardId } from '../../../../models/diviCard';

interface AnamneseProps {
  nodeType: NodeType;
}

const anticoagualantsItems = getAnticoagualantsTuplesFor([
  AnticoagualantsKeys.tzaggregationshemmer,
  AnticoagualantsKeys.vitkantagonist,
  AnticoagualantsKeys.doak,
  AnticoagualantsKeys.heparin,
  AnticoagualantsKeys.sonstigeantikoagulanzmedikation,
]);

export default function Anticoagulants({ nodeType }: AnamneseProps) {
  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.patientMedication.type, nodeType);

  const selectedAnticoagualantsItems = (record.patientMedications ?? []) as AnticoagualantsKeys[];

  const changeSymptoms = (values: AnticoagualantsKeys[]) => {
    reportAPI.adaptRecord(defaultRecords.patientMedication.type, nodeType, (draft, deleteRecord) => {
      if (values.length === 0) {
        deleteRecord();
      } else {
        return { ...draft, anticoagulation: true, patientMedications: values, inputState: InputState.ENTERED };
      }
    });
  };

  return (
    <DiviFieldCard cardType={CardId.Anticoagulants}>
      <CheckboxList
        className='symptoms-list'
        items={anticoagualantsItems}
        selectedValues={selectedAnticoagualantsItems}
        onValuesChange={changeSymptoms}
      />
    </DiviFieldCard>
  );
}
