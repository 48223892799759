import React, { useContext, useState } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { NodeType } from '../../../../backendModels/report.model';
import {
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
  getNewBornAgeTuplesFor,
  NewBornAgeKeys,
} from '../../../../models/genericElements/extendedPatientData';
import RadioList from '../../../customs/radioList/RadioList';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { Draft, produce } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { useFreshCallback } from '../../../../utils/hooks';
import { isGenericRecordDeletable } from '../../../../models/generic';

const newBornAgeRadioItems: [NewBornAgeKeys, string][] = getNewBornAgeTuplesFor([
  NewBornAgeKeys.eineWocheAlt,
  NewBornAgeKeys.zwischenZweiUndVierWochenAlt,
]);

interface AgeProps {
  nodeType: NodeType;
}

export default function Age({ nodeType }: AgeProps) {
  const { findRecordOrDefault, adaptRecord, patient, patientUpdate } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ) as ExtendedPatientDataRecord;

  const [ageTouched, setAgeTouched] = useState(false);

  const prefilled = useFreshCallback((value: string | undefined | null, touched: boolean) => {
    return value != null && !touched ? 'prefilled' : '';
  });

  const handleNewBornAgeChange = useFreshCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (patient?.age !== 0) {
      patientUpdate?.({ ...patient, age: 0 });
      setAgeTouched(true);
    }
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          newbornAge: {
            fieldType: 'singleSelect',
            option: event.target.value as NewBornAgeKeys,
          },
        };
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  const handleAgeChange = useFreshCallback((value: string) => {
    if (value === '') {
      if (patient?.age != null) {
        patientUpdate?.(
          produce(patient, (draft) => {
            delete draft.age;
          }),
        );
        setAgeTouched(true);
      }
    } else if (Number(value) !== patient?.age) {
      patientUpdate?.({ ...patient, age: Number(value) });
      setAgeTouched(true);
      if (Number(value) !== 0) {
        adaptRecord(
          'generic',
          nodeType,
          (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
            delete draft.values?.newbornAge;
            if (isGenericRecordDeletable(draft)) {
              deleteRecord();
            }
          },
          EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
        );
      }
    }
  });

  return (
    <DiviFieldCard cardType={CardId.Age}>
      <div className='column'>
        <DebouncedTextField
          className={prefilled(patient?.age?.toString(), ageTouched)}
          label='Jahre'
          type='number'
          inputProps={{ step: 1, min: 0 }}
          value={patient?.age?.toString() ?? ''}
          onDebounceChange={handleAgeChange}
        />
        <RadioList
          className='symptoms-list add-margin-top'
          items={newBornAgeRadioItems}
          value={record.values?.newbornAge?.option ?? null}
          onChange={handleNewBornAgeChange}
        />
      </div>
    </DiviFieldCard>
  );
}
