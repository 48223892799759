import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { FormControl } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './Reanimation.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {
  getReanimationTuplesFor,
  REANIMATION_RECORD_ELEMENT_KEY,
  ReanimationCompressionKeys,
  ReanimationCoolingByKeys,
  ReanimationCoolingKeys,
  ReanimationDeviceKeys,
  ReanimationFeedbackKeys,
  ReanimationInstructionKeys,
  ReanimationRecord,
} from '../../../../models/genericElements/treatments/reanimation';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { NodeType } from '../../../../backendModels/report.model';
import { InputState } from '../../../../backendModels/general.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import RadioList from '../../../customs/radioList/RadioList';
import dayjs, { Dayjs } from 'dayjs';
import { Draft } from 'immer';
import {
  GenericRecordMultiSelects,
  GenericRecordMultiSelectType,
  GenericRecordSingleSelects,
  GenericRecordSingleSelectType,
  GenericRecordValues,
  isGenericRecordDeletable,
} from '../../../../models/generic';

const compressionItems = getReanimationTuplesFor([ReanimationCompressionKeys.cardiacMassage]);
const feedbackItems = getReanimationTuplesFor([ReanimationFeedbackKeys.manual, ReanimationFeedbackKeys.mechanical]);
const deviceItems = getReanimationTuplesFor([
  ReanimationDeviceKeys.vvEcmo,
  ReanimationDeviceKeys.vaEcmo,
  ReanimationDeviceKeys.reboa,
]);
const coolingItems = getReanimationTuplesFor([ReanimationCoolingKeys.activeCooling]);
const coolingByItems = getReanimationTuplesFor([
  ReanimationCoolingByKeys.infusion,
  ReanimationCoolingByKeys.coolingPack,
  ReanimationCoolingByKeys.technical,
]);
const instructionItems = getReanimationTuplesFor([ReanimationInstructionKeys.phoneInstruction]);

interface ReanimationProps {
  nodeType: NodeType;
}

export default function Reanimation({ nodeType }: ReanimationProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('generic', nodeType, REANIMATION_RECORD_ELEMENT_KEY) as ReanimationRecord;

  const timeString =
    record.inputState == InputState.ENTERED ? record.values.REANIMATION_BEGINN_KOMPRESSIONEN?.text ?? null : null;
  const timeDayjs = timeString != null ? dayjs(timeString, 'HH:mm') : null;

  const updateParentCheckbox = <
    FIELD_NAME extends keyof GenericRecordMultiSelects<ReanimationRecord>,
    OPTIONS_TYPE extends GenericRecordMultiSelectType<ReanimationRecord, FIELD_NAME>,
  >(
    fieldName: FIELD_NAME,
    newValues: OPTIONS_TYPE[],
    subFieldNames: (keyof GenericRecordValues<ReanimationRecord>)[] = [],
  ) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (newValues.length > 0) {
          draft.values = {
            ...draft.values,
            [fieldName]: {
              fieldType: 'multiSelect',
              options: newValues,
            },
          };
        } else {
          delete draft.values?.[fieldName];
          for (const subFieldName of subFieldNames) {
            delete draft.values?.[subFieldName];
          }

          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      REANIMATION_RECORD_ELEMENT_KEY,
    );
  };

  const updateChildRadio = <
    FIELD_NAME extends keyof GenericRecordSingleSelects<ReanimationRecord>,
    OPTIONS_TYPE extends GenericRecordSingleSelectType<ReanimationRecord, FIELD_NAME>,
    PARENT_FIELD_NAME extends keyof GenericRecordMultiSelects<ReanimationRecord>,
    PARENT_OPTIONS_TYPE extends GenericRecordMultiSelectType<ReanimationRecord, PARENT_FIELD_NAME>,
  >(
    fieldName: FIELD_NAME,
    newValue: OPTIONS_TYPE,
    parentFieldName: PARENT_FIELD_NAME,
    parentOptions: PARENT_OPTIONS_TYPE[],
  ) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          [fieldName]: {
            fieldType: 'singleSelect',
            option: newValue,
          },
          [parentFieldName]: {
            fieldType: 'multiSelect',
            options: parentOptions,
          },
        };
      },
      REANIMATION_RECORD_ELEMENT_KEY,
    );
  };

  const updateTime = (newTime: Dayjs | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ReanimationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (newTime != null && newTime.isValid()) {
          draft.values = {
            ...draft.values,
            REANIMATION_KOMPRESSIONEN: {
              fieldType: 'multiSelect',
              options: [ReanimationCompressionKeys.cardiacMassage],
            },
            REANIMATION_BEGINN_KOMPRESSIONEN: {
              fieldType: 'text',
              text: newTime.format('HH:mm'),
            },
          };
        } else {
          delete draft.values?.REANIMATION_BEGINN_KOMPRESSIONEN;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      REANIMATION_RECORD_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.MeasuresReanimation}
      subTitle='(Ergänzende Angaben im Kasten “Reanimation / Tod / Todesfeststellung”)'
    >
      <div className='measures-reanimation symptoms-list'>
        <FormControl fullWidth>
          <div className='row'>
            <div>
              <div className='symptoms-list'>
                <CheckboxList
                  items={compressionItems}
                  selectedValues={
                    record.inputState === InputState.ENTERED
                      ? record.values.REANIMATION_KOMPRESSIONEN?.options ?? []
                      : []
                  }
                  onValuesChange={(newOptions) =>
                    updateParentCheckbox('REANIMATION_KOMPRESSIONEN', newOptions, [
                      'REANIMATION_FEEDBACK',
                      'REANIMATION_BEGINN_KOMPRESSIONEN',
                    ])
                  }
                />
                <RadioList
                  className='indented'
                  items={feedbackItems}
                  value={
                    record.inputState === InputState.ENTERED ? record.values.REANIMATION_FEEDBACK?.option ?? null : null
                  }
                  onChange={(event) =>
                    updateChildRadio(
                      'REANIMATION_FEEDBACK',
                      event.target.value as ReanimationFeedbackKeys,
                      'REANIMATION_KOMPRESSIONEN',
                      [ReanimationCompressionKeys.cardiacMassage],
                    )
                  }
                />
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimeField
                  fullWidth
                  name='start-hdm'
                  label='Beginn HDM'
                  format='HH:mm'
                  value={timeDayjs}
                  onChange={updateTime}
                />
              </LocalizationProvider>
            </div>
            <div className='symptoms-list'>
              <CheckboxList
                items={deviceItems}
                selectedValues={
                  record.inputState === InputState.ENTERED
                    ? record.values.REANIMATION_SPEZIAL_GERAET?.options ?? []
                    : []
                }
                onValuesChange={(newOptions) => updateParentCheckbox('REANIMATION_SPEZIAL_GERAET', newOptions)}
              />
            </div>
            <div className='symptoms-list'>
              <CheckboxList
                items={coolingItems}
                selectedValues={
                  record.inputState === InputState.ENTERED ? record.values.REANIMATION_KUEHLUNG?.options ?? [] : []
                }
                onValuesChange={(newOptions) =>
                  updateParentCheckbox('REANIMATION_KUEHLUNG', newOptions, ['REANIMATION_KUEHLUNG_DURCH'])
                }
              />
              <RadioList
                className='indented'
                items={coolingByItems}
                value={
                  record.inputState === InputState.ENTERED
                    ? record.values.REANIMATION_KUEHLUNG_DURCH?.option ?? null
                    : null
                }
                onChange={(event) =>
                  updateChildRadio(
                    'REANIMATION_KUEHLUNG_DURCH',
                    event.target.value as ReanimationCoolingByKeys,
                    'REANIMATION_KUEHLUNG',
                    [ReanimationCoolingKeys.activeCooling],
                  )
                }
              />
            </div>
            <div className='symptoms-list'>
              <CheckboxList
                items={instructionItems}
                selectedValues={
                  record.inputState === InputState.ENTERED ? record.values.REANIMATION_TEL_ANLEITUNG?.options ?? [] : []
                }
                onValuesChange={(newOptions) => updateParentCheckbox('REANIMATION_TEL_ANLEITUNG', newOptions)}
              />
            </div>
          </div>
        </FormControl>
      </div>
    </DiviFieldCard>
  );
}
