import React from 'react';
import globalStyle from '../../../global.scss';
import style from './ScoreButtonList.scss';
import { List, ListItem, ListItemButton, Typography } from '@mui/material';
import { useCSS } from '../../../provider/CSSProvider';
import { convertToRomanNumber } from '../../../utils/util';

export type ScoreButtonLabel = string;

interface ScoreButtonProps<T> {
  title?: string;
  reverse: boolean;
  listOfEntries: ReadonlyArray<readonly [T, ScoreButtonLabel]>;
  selectedValue: T | null;
  /**
   * When true, roman scores are displayed.
   */
  romanScores?: boolean;
  onChange: (value: T) => void;
}
function ScoreButtonList<T>(props: ScoreButtonProps<T>) {
  useCSS(style);
  useCSS(globalStyle);

  const { title, reverse, listOfEntries, selectedValue, romanScores, onChange } = props;

  function getScoreLabel(score: number): string {
    if (romanScores) {
      return convertToRomanNumber(score);
    } else {
      return score.toString();
    }
  }

  const ListButtons = listOfEntries.map(([value, label], index) => (
    <ListItem key={label}>
      <ListItemButton selected={value === selectedValue} onClick={() => onChange(value)}>
        <div className='option'>
          <div className='option__score'>{getScoreLabel(index + 1)}</div>
          <div className='option__label'>{label}</div>
        </div>
      </ListItemButton>
    </ListItem>
  ));

  return (
    <div className='score-button'>
      {title && (
        <Typography align='left' className='title'>
          {title}
        </Typography>
      )}
      <List className={'reverse-' + reverse}>{ListButtons}</List>
    </div>
  );
}

export default ScoreButtonList;
