import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../../generic';
import { NumericFieldValue } from '../../../backendModels/generic-element.model';

export enum VentilationKeys {
  beatmungmanual = 'BEATMUNG_MANUAL',
  beatmungkontrolliert = 'BEATMUNG_KONTROLLIERT',
  beatmungassistiert = 'BEATMUNG_ASSISTIERT',
  beatmungniv = 'BEATMUNG_NIV',
  beatmungnarkose = 'BEATMUNG_NARKOSE',
}

const ventilationLabels: Record<VentilationKeys, string> = {
  [VentilationKeys.beatmungmanual]: 'manuell',
  [VentilationKeys.beatmungkontrolliert]: 'kontrolliert',
  [VentilationKeys.beatmungassistiert]: 'assistiert',
  [VentilationKeys.beatmungniv]: 'NIV',
  [VentilationKeys.beatmungnarkose]: 'Notfallnarkose',
};

export const VENTILATION_RECORD_ELEMENT_KEY = 'BEATMUNG';

export type VentilationRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof VENTILATION_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          BEATMUNG?: SingleSelectFieldValueTyped<VentilationKeys>;
          BEATMUNG_FIO2?: NumericFieldValue;
          BEATMUNG_AF?: NumericFieldValue;
          BEATMUNG_AMV?: NumericFieldValue;
          BEATMUNG_PEEP?: NumericFieldValue;
          BEATMUNG_PMAX?: NumericFieldValue;
          BEATMUNG_NOTFALL?: MultiSelectFieldValueTyped<VentilationKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function getVentilationTuple(key: VentilationKeys): [VentilationKeys, string] {
  return [key, ventilationLabels[key]];
}

export function getVentilationTuplesFor(keys: VentilationKeys[]): [VentilationKeys, string][] {
  return keys.map((k) => {
    return getVentilationTuple(k);
  });
}
