import { useCallback, useRef, useSyncExternalStore } from 'react';

// This hook serves two purposes:
// - It avoids rerenders similar to useCallback by always returning the same function.
// - It ensures that the function has the current captures even if it is called after some delay (e.g. debounce).
// Avoid this hook if you intend to use the function in component rendering (in contrast to an effect or event),
// since it will not trigger a rerender if a dependency has changed.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFreshCallback<PARAMETERS extends any[], RETURN_TYPE>(func: (...args: PARAMETERS) => RETURN_TYPE) {
  const funcRef = useRef(func);
  funcRef.current = func;
  return useCallback((...args: PARAMETERS) => funcRef.current(...args), []);
}

class ObservableRef<T> {
  private current_: T;
  private listeners = new Set<() => void>();

  public get current() {
    return this.current_;
  }

  public set current(newValue: T) {
    this.current_ = newValue;
    for (const oneListener of this.listeners) {
      oneListener();
    }
  }

  public constructor(initial: T) {
    this.current_ = initial;
  }

  public readonly subscribe = (listener: () => void) => {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  };
}

// Create a ref which is mirrored to a state as well.
// All uses in functions and changes should use the ref.
// However, the state can be used in rendering.
// A rerender will automatically be triggered when the ref is changed.
export function useStateRefCombo<T>(factory: () => T): [T, ObservableRef<T>] {
  const observableRef = useRef<ObservableRef<T> | null>(null);
  if (!observableRef.current) {
    observableRef.current = new ObservableRef<T>(factory());
  }
  const state = useSyncExternalStore(observableRef.current.subscribe, () => observableRef.current!.current);
  return [state, observableRef.current];
}
