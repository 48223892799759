import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum MedicalConditionOtherKeys {
  anaphylaktischeReaktion = 'ANAPHLAKTISCHE_REAKTION',
  anaphylaktischeReaktionI_II = 'ANAPHLAKTISCHE_REAKTION_I_II',
  anaphylaktischeReaktionIII_IV = 'ANAPHLAKTISCHE_REAKTION_III_IV',
  hitzschlag = 'HITZSCHLAG',
  erfrierung = 'ERFRIERUNG',
  sepsis = 'SEPSIS',
  covid = 'COVID',
  influenza = 'INFLUENZA',
  hepatitis = 'HEPATITIS',
  lumbago = 'LUMBAGO',
  epistaxis = 'EPISTAXIS',
  sozialesProblem = 'SOZIALES_PROBLEM',
  iatrogen = 'IATROGEN',
}

export const MEDICAL_CONDITION_OTHER_ELEMENT_KEY = 'ERKRANKUNGEN_SONSTIGE';

export type MedicalConditionOtherRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MEDICAL_CONDITION_OTHER_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          ERKRANKUNGEN_SONSTIGE?: MultiSelectFieldValueTyped<MedicalConditionOtherKeys>;
          ERKRANKUNGEN_SONSTIGE_SONSTIGE?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

const MedicalConditionOtherLabels: Record<MedicalConditionOtherKeys, string> = {
  [MedicalConditionOtherKeys.anaphylaktischeReaktion]: 'anaphylakt. Reaktion',
  [MedicalConditionOtherKeys.anaphylaktischeReaktionI_II]: 'I-II°',
  [MedicalConditionOtherKeys.anaphylaktischeReaktionIII_IV]: 'III-IV°',
  [MedicalConditionOtherKeys.hitzschlag]: 'Hitzeerschöpfung, Hitzschlag',
  [MedicalConditionOtherKeys.erfrierung]: 'Unterkühlung / Erfrierung',
  [MedicalConditionOtherKeys.sepsis]: 'hochfieb. Infekt / Sepsis / Septischer Schock',
  [MedicalConditionOtherKeys.covid]: 'CoVid-19 / -Verdacht',
  [MedicalConditionOtherKeys.influenza]: 'Influenza',
  [MedicalConditionOtherKeys.hepatitis]: 'Hepatitis / HIV',
  [MedicalConditionOtherKeys.lumbago]: 'akute Lumbago',
  [MedicalConditionOtherKeys.epistaxis]: 'Epistaxis',
  [MedicalConditionOtherKeys.sozialesProblem]: 'Soziales Problem (ohne psych. Störung)',
  [MedicalConditionOtherKeys.iatrogen]: 'Medizinische Behandlungskomplikation',
};

export function getMedicalConditionOtherTupel(key: MedicalConditionOtherKeys): [MedicalConditionOtherKeys, string] {
  return [key, MedicalConditionOtherLabels[key]];
}

export function getMedicalConditionOtherTupelsFor(
  keys: MedicalConditionOtherKeys[],
): [MedicalConditionOtherKeys, string][] {
  return keys.map((k) => {
    return getMedicalConditionOtherTupel(k);
  });
}
