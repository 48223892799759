// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#card-refusal-of-measures.card{padding:0}#card-refusal-of-measures.card .refusal-of-measures-label{margin:0}`, "",{"version":3,"sources":["webpack://./src/App/Pages/Remarks/RefusalOfMeasures/RefusalOfMeasures.scss"],"names":[],"mappings":"AACE,+BACE,SAAA,CAEA,0DACE,QAAA","sourcesContent":["#card-refusal-of-measures {\n  &.card {\n    padding: 0;\n\n    .refusal-of-measures-label {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
