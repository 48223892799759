import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';
import { useFreshCallback } from '../../../../utils/hooks';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  getLyseTherapyTuplesFor,
  LYSE_THERAPY_RECORD_ELEMENT_KEY,
  LyseTherapyKeys,
  LyseTherapyRecord,
} from '../../../../models/genericElements/lysetherapy';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';

const lyseTherapyItems = getLyseTherapyTuplesFor([
  LyseTherapyKeys.lysetherapieestartvorstillstand,
  LyseTherapyKeys.lysetherapiestartwaehrendstillstand,
  LyseTherapyKeys.lysetherapiestartnachrosc,
]);

interface LyseTherapyProps {
  nodeType: NodeType;
}

export default function LyseTherapy({ nodeType }: LyseTherapyProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, LYSE_THERAPY_RECORD_ELEMENT_KEY) as LyseTherapyRecord;

  const timeString =
    record.inputState == InputState.ENTERED ? record.values.LYSE_THERAPIE_START_ZEIT?.text ?? null : null;
  const timeDayjs = timeString != null ? dayjs(timeString, 'HH:mm') : null;

  const changeStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<LyseTherapyRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (event.target == null) {
          delete draft.values?.LYSE_THERAPIE_START;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            LYSE_THERAPIE_START: {
              fieldType: 'singleSelect',
              option: event.target.value as LyseTherapyKeys,
            },
          };
        }
      },
      LYSE_THERAPY_RECORD_ELEMENT_KEY,
    );
  };

  const handleStartTime = useFreshCallback((value: dayjs.Dayjs | null) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<LyseTherapyRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value == null) {
          delete draft.values?.LYSE_THERAPIE_START_ZEIT;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        } else {
          draft.values = {
            ...draft.values,
            LYSE_THERAPIE_START_ZEIT: {
              fieldType: 'text',
              text: value.format('HH:mm'),
            },
          };
        }
      },
      LYSE_THERAPY_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.LysisTherapy}>
      <div className='symptoms-list'>
        <RadioList
          items={lyseTherapyItems}
          name='lysis-group'
          value={record.inputState === InputState.ENTERED ? record.values.LYSE_THERAPIE_START?.option ?? null : null}
          onChange={changeStart}
        />
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeField
          fullWidth
          className='time-of-arrival'
          label='Beginn'
          format='HH:mm'
          value={timeDayjs}
          onChange={handleStartTime}
        />
      </LocalizationProvider>
    </DiviFieldCard>
  );
}
