import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import style from './Searchbar.scss';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { useCSS } from '../../../provider/CSSProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { OptionFieldType, OptionType, searchOptions } from './Entries';
import SearchIcon from '@mui/icons-material/Search';
import { getPageRoute, PageType } from '../../../models/diviPage';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';

interface searchEntry {
  label: string;
  path: string;
  keywords?: string[] | undefined;
}

function Searchbar() {
  useCSS(style);

  const navigate = useNavigate();
  const location = useLocation();
  const { acronyms } = useContext(ReportsAPIContext);
  const [valueState, setValueState] = useState<string | OptionType | null>('');
  const [inputValueState, setInputValueState] = useState<string>('');
  const autocompleteRef = useRef<HTMLElement | null>(null);

  //needed in order to scroll to the card id
  useEffect(() => {
    const anchor = '#card-' + location.hash.replace('#', '');
    if (anchor) {
      const root = autocompleteRef.current?.getRootNode() as Document | ShadowRoot | undefined;
      const element = root?.querySelector(anchor);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  const filterOptions = (options: OptionType[], { inputValue }: { inputValue: string }): OptionType[] => {
    return options.filter((option) => {
      const searchKeyword = inputValue.toLowerCase();
      if (option.label.toLowerCase().includes(searchKeyword)) {
        return true;
      }
      return option.keywords?.some((keyword) => keyword.toLowerCase().includes(searchKeyword));
    });
  };

  const handleSearchSelect = (event: React.SyntheticEvent<Element, Event>, value: string | OptionType | null) => {
    if (value != null && typeof value !== 'string') {
      navigate(value.path);
      setValueState('');
      setInputValueState('');
    } else {
      setValueState(value);
      setInputValueState(value ?? '');
    }
  };

  function generateFlatList(options: OptionType[], acronymOptions: OptionType[]) {
    const flatList: searchEntry[] = [];

    function addEntries(option: OptionType | OptionFieldType, basePath: string) {
      const newPath = 'cardId' in option ? `${basePath}#${option.cardId}` : basePath;
      flatList.push({
        label: option.label,
        path: newPath,
        keywords: option.keywords,
      });

      if ('fields' in option && option.fields) {
        option.fields.forEach((field) => addEntries(field, newPath));
      } else if ('children' in option && option.children) {
        option.children.forEach((child) => addEntries(child, newPath));
      }
    }
    [...options, ...acronymOptions].forEach((option) => addEntries(option, option.path));
    return flatList;
  }

  function getOptionKey(option: string | OptionType): string {
    if (typeof option === 'string') {
      return option;
    }
    return option.path + option.label;
  }

  function generateAcronymSearchOptions(): OptionType[] {
    if (acronyms == null) {
      return [];
    }

    return Object.getOwnPropertyNames(acronyms).map((key: string) => {
      return {
        label: 'Akronym - ' + key,
        path: getPageRoute(PageType.Acronym) + '/' + encodeURIComponent(key),
      };
    });
  }

  const acronymOptions: OptionType[] = generateAcronymSearchOptions();
  const flatList = useMemo(() => generateFlatList(searchOptions, acronymOptions), [acronymOptions]);
  const sortedOptions = [...flatList].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Autocomplete
      disablePortal
      freeSolo
      className='searchbar'
      options={sortedOptions}
      getOptionKey={getOptionKey}
      value={valueState}
      inputValue={inputValueState}
      onInputChange={handleSearchSelect}
      getOptionLabel={(searchOptions: string | OptionType) =>
        typeof searchOptions === 'string' ? searchOptions : searchOptions.label
      }
      size={'small'}
      ref={autocompleteRef}
      onChange={handleSearchSelect}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Suche nach Schlagwörtern oder Akronymen ...'
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      filterOptions={filterOptions}
    />
  );
}

export default Searchbar;
