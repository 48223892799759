export enum SkinKeys {
  nichtUntersucht = 'NICHT_UNTERSUCHT',
  pathologisch = 'PATHOLOGISCH',
  stehendeHautfalten = 'STEHENDE_HAUTFALTEN',
  dekubitus = 'DEKUBITUS',
  exanthem = 'EXANTHEM',
  oedeme = 'OEDEME',
  kaltschweissig = 'KALTSCHWEISSIG',
}

export const skinLabels: [SkinKeys, string][] = [
  [SkinKeys.nichtUntersucht, 'nicht untersucht'],
  [SkinKeys.pathologisch, 'pathologisch'],
  [SkinKeys.stehendeHautfalten, 'stehende Hautfalten'],
  [SkinKeys.dekubitus, 'Dekubitus'],
  [SkinKeys.exanthem, 'Exanthem'],
  [SkinKeys.oedeme, 'Oedeme'],
  [SkinKeys.kaltschweissig, 'kaltschweißig'],
];
