import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { SingleSelectFieldValueTyped } from '../generic';

export enum VitalParameterAdditionKeys {
  raumluft = 'RAUMLUFT',
  o2gabe = 'O2_GABE',
}

const vitalParameterAdditionLabels: Record<VitalParameterAdditionKeys, string> = {
  [VitalParameterAdditionKeys.raumluft]: 'bei Raumluft',
  [VitalParameterAdditionKeys.o2gabe]: 'unter O2-Gabe',
};

export const VITALPARAMETER_ERWEITERT_ELEMENT_KEY = 'VITALPARAMETER_ERWEITERT';

export type VitalParameterAdditionRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof VITALPARAMETER_ERWEITERT_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          SPO2_GEMESSEN?: SingleSelectFieldValueTyped<VitalParameterAdditionKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function getVitalParameterAdditionTupel(key: VitalParameterAdditionKeys): [VitalParameterAdditionKeys, string] {
  return [key, vitalParameterAdditionLabels[key]];
}

export function getVitalParameterAdditionTuplesFor(
  keys: VitalParameterAdditionKeys[],
): [VitalParameterAdditionKeys, string][] {
  return keys.map((k) => {
    return getVitalParameterAdditionTupel(k);
  });
}
