export enum BreathingKeys {
  nichtUntersucht = 'NICHT_UNTERSUCHT',
  schnappatmung = 'SCHNAPPATMUNG',
  sonstigepathologischeatemmuster = 'SONSTIGE_PATHOLOGISCHE_ATEMMUSTER',
  apnoe = 'APNOE',
  dyspnoe = 'DYSPNOE',
  beatmung = 'BEATMUNG',
  stridor = 'STRIDOR',
  zyanose = 'ZYANOSE',
  spastik = 'SPASTIK',
  hyperventilation = 'HYPERVENTILATION',
  atemwegsverlegung = 'ATEMWEGSVERLEGUNG',
  rasselgeraeusche = 'RASSELGERAEUSCHE',
}

const breathingLabels: Record<BreathingKeys, string> = {
  [BreathingKeys.nichtUntersucht]: 'nicht untersucht',
  [BreathingKeys.schnappatmung]: 'Schnappatmung',
  [BreathingKeys.sonstigepathologischeatemmuster]: 'Sonstiges',
  [BreathingKeys.apnoe]: 'Apnoe',
  [BreathingKeys.dyspnoe]: 'Dyspnoe',
  [BreathingKeys.beatmung]: 'Beatmung',
  [BreathingKeys.stridor]: 'Stridor',
  [BreathingKeys.zyanose]: 'Zyanose',
  [BreathingKeys.spastik]: 'Spastik',
  [BreathingKeys.hyperventilation]: 'Hyperventilation',
  [BreathingKeys.atemwegsverlegung]: 'Atemwegsverlegung',
  [BreathingKeys.rasselgeraeusche]: 'Rasselgeräusche',
};

export function getBreathingTupel(key: BreathingKeys): [BreathingKeys, string] {
  return [key, breathingLabels[key]];
}

export function getBreathingTupelsFor(keys: BreathingKeys[]): [BreathingKeys, string][] {
  return keys.map((k) => {
    return getBreathingTupel(k);
  });
}
