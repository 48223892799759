import React, { useContext, useState } from 'react';
import style from './Neuroloy.scss';
import Gcs from './Gcs/Gcs';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import Pupils from './Pupils/Pupils';
import Consciousness from './Consciousness/Consciousness';
import LimbMovements from './LimbMovements/LimbMovements';
import NeurologicalFeatures from './NeurologicalFeatures/NeurologicalFeatures';
import TimeDialog from '../../customs/timeDialog/TimeDialog';
import dayjs from 'dayjs';
import { useCSS } from '../../../provider/CSSProvider';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { DiviNavigationIds } from '../../../models/diviProgress';
import { NodeType } from '../../../backendModels/report.model';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { LIMB_MOVEMENTS_ELEMENT_KEY } from '../../../models/genericElements/limbMovements';
import { DISABILITY_EXTENDED_ELEMENT_KEY } from '../../../models/genericElements/disabilityExtended';

export function Neurology() {
  useCSS(style);

  const [timeDialogOpen, setTimeDialogOpen] = useState<boolean>(false);
  const reportAPI = useContext(ReportsAPIContext);

  const recordTypesOfPage = ['gcs', 'disability', 'pupils', 'consciousness'] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.INITIAL_SURVEY;
  const elementKeysOfPage = [LIMB_MOVEMENTS_ELEMENT_KEY, DISABILITY_EXTENDED_ELEMENT_KEY];

  const timestamp = dayjs(reportAPI.getNodeTimestamp(nodeTypeOfPage) ?? undefined);

  function setTimestamp(newTimestamp: dayjs.Dayjs) {
    reportAPI.setNodeTimestamp(newTimestamp.valueOf(), nodeTypeOfPage);
  }

  function openTimeDialog() {
    setTimeDialogOpen(true);
  }

  const pageHeader = (
    <PageHeader
      heading={getPageTitle(PageType.Neurology)}
      onTimeClicked={openTimeDialog}
      time={timestamp.format('HH:mm')}
    />
  );

  const pageFooter = (
    <PageFooter
      pageType={PageType.Neurology}
      diviId={DiviNavigationIds.Neurology}
      noPathButton
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
      diviId={DiviNavigationIds.Neurology}
    >
      <TimeDialog
        timestamp={{ timestamp: timestamp, setTimestamp: setTimestamp }}
        dialogVisible={{ open: timeDialogOpen, setOpen: setTimeDialogOpen }}
      />
      <div className='wireframe-container-neurology'>
        <div className='gcs'>
          <Gcs nodeType={nodeTypeOfPage} />
        </div>
        <LimbMovements nodeType={nodeTypeOfPage} />
        <Consciousness nodeType={nodeTypeOfPage} />
        <NeurologicalFeatures nodeType={nodeTypeOfPage} />
        <Pupils nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
