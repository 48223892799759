import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { NodeType } from '../../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import {
  MEDICAL_CONDITION_BREATHING_ELEMENT_KEY,
  MedicalConditionBreathingRecord,
  getBreathingTupelsFor,
  MedicalConditionBreathingKeys,
} from '../../../../models/genericElements/medicalConditions/breathing';
import { checkParent, uncheckChildren } from '../../../../utils/util';
import { isGenericRecordDeletable, setGenericRecordToNormal } from '../../../../models/generic';

const checkboxes1: [MedicalConditionBreathingKeys, string][] = getBreathingTupelsFor([
  MedicalConditionBreathingKeys.asthma,
]);

const checkbox2: [MedicalConditionBreathingKeys, string][] = getBreathingTupelsFor([
  MedicalConditionBreathingKeys.statusAsthmaticus,
]);

const checkbox3: [MedicalConditionBreathingKeys, string][] = getBreathingTupelsFor([
  MedicalConditionBreathingKeys.copd,
]);

const checkboxes4: [MedicalConditionBreathingKeys, string][] = getBreathingTupelsFor([
  MedicalConditionBreathingKeys.copdHeimbeatmung,
  MedicalConditionBreathingKeys.copdHeimsauerstoff,
]);

const checkboxes5: [MedicalConditionBreathingKeys, string][] = getBreathingTupelsFor([
  MedicalConditionBreathingKeys.pneumonie,
  MedicalConditionBreathingKeys.hyperventilation,
  MedicalConditionBreathingKeys.aspiration,
  MedicalConditionBreathingKeys.haemoptysis,
]);

interface MedicalConditionBreathingProps {
  nodeType: NodeType;
}

export default function MedicalConditionBreathing({ nodeType }: MedicalConditionBreathingProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault(
    'generic',
    nodeType,
    MEDICAL_CONDITION_BREATHING_ELEMENT_KEY,
  ) as MedicalConditionBreathingRecord;
  const conditions = record.inputState === InputState.ENTERED ? record.values.ERKRANKUNGEN_ATMUNG?.options ?? [] : [];

  const conditionsChange = (newConditions: MedicalConditionBreathingKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionBreathingRecord>, deleteRecord) => {
        if (newConditions.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            ERKRANKUNGEN_ATMUNG: {
              fieldType: 'multiSelect',
              options: newConditions,
            },
          };
        } else {
          delete draft.values?.ERKRANKUNGEN_ATMUNG;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_BREATHING_ELEMENT_KEY,
    );
  };
  const otherChange = (value: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<MedicalConditionBreathingRecord>, deleteRecord) => {
        if (value !== '') {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            ERKRANKUNGEN_ATMUNG_SONSTIGE: {
              fieldType: 'text',
              text: value,
            },
          };
        } else {
          delete draft.values?.ERKRANKUNGEN_ATMUNG_SONSTIGE;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      MEDICAL_CONDITION_BREATHING_ELEMENT_KEY,
    );
  };

  return (
    <DiviFieldCard
      cardType={CardId.MedicalConditionBreathing}
      nodeType={nodeType}
      recordType='generic'
      elementKey={MEDICAL_CONDITION_BREATHING_ELEMENT_KEY}
      normalStateButtonVisible={false}
      onNormalStateButtonClicked={() =>
        setGenericRecordToNormal(adaptRecord, nodeType, MEDICAL_CONDITION_BREATHING_ELEMENT_KEY)
      }
    >
      <div className='symptoms-list'>
        <CheckboxList
          items={checkboxes1}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              uncheckChildren(newConditions, MedicalConditionBreathingKeys.asthma, [
                MedicalConditionBreathingKeys.statusAsthmaticus,
              ]),
            )
          }
        />
        <CheckboxList
          className='indented'
          items={checkbox2}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              checkParent(newConditions, MedicalConditionBreathingKeys.asthma, [
                MedicalConditionBreathingKeys.statusAsthmaticus,
              ]),
            )
          }
        />
        <CheckboxList
          items={checkbox3}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              uncheckChildren(newConditions, MedicalConditionBreathingKeys.copd, [
                MedicalConditionBreathingKeys.copdHeimbeatmung,
                MedicalConditionBreathingKeys.copdHeimsauerstoff,
              ]),
            )
          }
        />
        <CheckboxList
          className='indented'
          items={checkboxes4}
          selectedValues={conditions}
          onValuesChange={(newConditions) =>
            conditionsChange(
              checkParent(newConditions, MedicalConditionBreathingKeys.copd, [
                MedicalConditionBreathingKeys.copdHeimbeatmung,
                MedicalConditionBreathingKeys.copdHeimsauerstoff,
              ]),
            )
          }
        />
        <CheckboxList items={checkboxes5} selectedValues={conditions} onValuesChange={conditionsChange} />
      </div>
      <DebouncedTextField
        fullWidth
        label='Sonstige...'
        value={record.inputState === InputState.ENTERED ? record.values.ERKRANKUNGEN_ATMUNG_SONSTIGE?.text ?? '' : ''}
        onDebounceChange={otherChange}
      />
    </DiviFieldCard>
  );
}
