import React from 'react';
import { FormControlLabel, FormControlLabelProps, Radio, RadioGroup, RadioGroupProps } from '@mui/material';

export type RadioLabel = string;

interface RadioListProps<T> extends RadioGroupProps {
  /**
   * Items consisting of tuples: (value, RadioLabel).
   */
  items: [T, RadioLabel][];
  /**
   * Props for the form control label.
   */
  formControlLabelProps?: Omit<FormControlLabelProps, 'key' | 'value' | 'control' | 'label'>;
}

export default function RadioList<T>(props: RadioListProps<T>) {
  const { items, formControlLabelProps, children, ...radioGroupProps } = props;

  return (
    <RadioGroup {...radioGroupProps}>
      {items.map(([value, label], index) => (
        <FormControlLabel {...formControlLabelProps} key={index} value={value} control={<Radio />} label={label} />
      ))}
      {children}
    </RadioGroup>
  );
}
