import { Draft } from 'immer';
import _ from 'lodash';
import { InputState } from '../backendModels/general.model';
import { GenericElementValue } from '../backendModels/generic-element.model';
import { AdaptRecordFunction } from '../provider/ReportsAPIProvider';
import { NodeType } from '../backendModels/report.model';
import { GenericNodeRecordValueTypeMap } from '../backendModels/records.model';

export type MultiSelectFieldValueTyped<ENUM_TYPE extends string> = {
  fieldType: 'multiSelect';
  options: ENUM_TYPE[];
};

export type SingleSelectFieldValueTyped<ENUM_TYPE extends string> = {
  fieldType: 'singleSelect';
  option: ENUM_TYPE;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* A type that contains all MultiSelectFieldValueTypes of the type parameter.
   Can e.g. be used with keyof as type of a parameter to select any MultiSelect of the record.
   Example: GenericRecordMultiSelects<ReanimationDeathRecord> =
   {
     REANIMATION_ZUSAETZE_REA: MultiSelectFieldValueTyped<ReanimationYesKeys>;
     REANIMATION_ZUSAETZE_KEINE_REA: MultiSelectFieldValueTyped<ReanimationNoKeys>;
     REANIMATION_ZUSAETZE_KH_AUFNAHME: MultiSelectFieldValueTyped<ReanimationHospitalKeys>;
     REANIMATION_ZUSAETZE_TOD: MultiSelectFieldValueTyped<DeathYesKeys>;
     REANIMATION_ZUSAETZE_TODESZEITPUNKT_UNKLAR: MultiSelectFieldValueTyped<DeathUnknownTimeKeys>;
   }
*/
export type GenericRecordMultiSelects<RECORD_TYPE extends GenericElementValue> = {
  [K in keyof Required<(RECORD_TYPE & { values: object })['values']> as NonNullable<
    (RECORD_TYPE & { values: object })['values'][K]
  > extends MultiSelectFieldValueTyped<any>
    ? K
    : never]: (RECORD_TYPE & { values: object })['values'][K];
};

// A type that is equal to the (enum) type of the MultiSelectFieldValueTypes of the given record type and field name.
export type GenericRecordMultiSelectType<
  RECORD_TYPE extends GenericElementValue,
  FIELD_NAME extends keyof GenericRecordMultiSelects<RECORD_TYPE>,
> =
  NonNullable<GenericRecordMultiSelects<RECORD_TYPE>[FIELD_NAME]> extends MultiSelectFieldValueTyped<infer X>
    ? X
    : never;

/* A type that contains all SingleSelectFieldValueTypes of the type parameter.
   Can e.g. be used with keyof as type of a parameter to select any SingleSelect of the record.
   Example: GenericRecordSingleSelects<ReanimationDeathRecord> =
   {
     REANIMATION_ZUSAETZE_BESCHREIBUNG: SingleSelectFieldValueTyped<ReanimationDescriptionKeys>;
     REANIMATION_ZUSAETZE_PERSONAL_BEGINN: SingleSelectFieldValueTyped<ReanimationByKeys>;
     REANIMATION_ZUSAETZE_KEINE_REA_GRUND: SingleSelectFieldValueTyped<ReanimationNoReasonKeys>;
     REANIMATION_ZUSAETZE_KH_AUFNAHME_ZUSTAND: SingleSelectFieldValueTyped<ReanimationHospitalConditionKeys>;
     REANIMATION_ZUSAETZE_TOD_ART: SingleSelectFieldValueTyped<DeathKindKeys>;
   }
 */
export type GenericRecordSingleSelects<RECORD_TYPE extends GenericElementValue> = {
  [K in keyof Required<(RECORD_TYPE & { values: object })['values']> as NonNullable<
    (RECORD_TYPE & { values: object })['values'][K]
  > extends SingleSelectFieldValueTyped<any>
    ? K
    : never]: (RECORD_TYPE & { values: object })['values'][K];
};

// A type that is equal to the (enum) type of the SingleSelectFieldValueTypes of the given record type and field name.
export type GenericRecordSingleSelectType<
  RECORD_TYPE extends GenericElementValue,
  FIELD_NAME extends keyof GenericRecordSingleSelects<RECORD_TYPE>,
> =
  NonNullable<GenericRecordSingleSelects<RECORD_TYPE>[FIELD_NAME]> extends SingleSelectFieldValueTyped<infer X>
    ? X
    : never;
/* eslint-enable @typescript-eslint/no-explicit-any */

/* A type that contains all fields of the type parameter.
   Can e.g. be used with keyof as type of a parameter to select any field of the record.
   Example: GenericRecordValues<ReanimationDeathRecord> =
   {
     REANIMATION_ZUSAETZE_REA: MultiSelectFieldValueTyped<ReanimationYesKeys>;
     REANIMATION_ZUSAETZE_BESCHREIBUNG: SingleSelectFieldValueTyped<ReanimationDescriptionKeys>;
     REANIMATION_ZUSAETZE_PERSONAL_BEGINN: SingleSelectFieldValueTyped<ReanimationByKeys>;
     REANIMATION_ZUSAETZE_KEINE_REA: MultiSelectFieldValueTyped<ReanimationNoKeys>;
     REANIMATION_ZUSAETZE_KEINE_REA_GRUND: SingleSelectFieldValueTyped<ReanimationNoReasonKeys>;
     REANIMATION_ZUSAETZE_KEINE_REA_GRUND_SONSTIGES: TextFieldValue;
     REANIMATION_ZUSAETZE_KH_AUFNAHME: MultiSelectFieldValueTyped<ReanimationHospitalKeys>;
     REANIMATION_ZUSAETZE_KH_AUFNAHME_ZUSTAND: SingleSelectFieldValueTyped<ReanimationHospitalConditionKeys>;
     REANIMATION_ZUSAETZE_TOD: MultiSelectFieldValueTyped<DeathYesKeys>;
     REANIMATION_ZUSAETZE_TOD_ART: SingleSelectFieldValueTyped<DeathKindKeys>;
     REANIMATION_ZUSAETZE_TODESZEITPUNKT: TextFieldValue;
     REANIMATION_ZUSAETZE_TODESZEITPUNKT_UNKLAR: MultiSelectFieldValueTyped<DeathUnknownTimeKeys>;
   }
*/
export type GenericRecordValues<RECORD_TYPE extends GenericElementValue> = {
  [K in keyof Required<(RECORD_TYPE & { values: object })['values']> as NonNullable<
    (RECORD_TYPE & { values: object })['values'][K]
  > extends object
    ? string extends K
      ? never
      : K
    : never]: (RECORD_TYPE & { values: object })['values'][K];
};

export function isGenericRecordDeletable(record: Draft<GenericElementValue> | GenericElementValue): boolean {
  if (record.inputState === InputState.ENTERED) {
    return record.values == null || _.isEqual(record.values, {});
  }
  return false;
}

export function setGenericRecordToNormal(adaptRecord: AdaptRecordFunction, nodeType: NodeType, elementKey: string) {
  adaptRecord(
    'generic',
    nodeType,
    (draft) => {
      return {
        id: draft.id,
        type: draft.type,
        elementKey: draft.elementKey,
        inputState: InputState.NORMAL,
      } as GenericNodeRecordValueTypeMap['generic'];
    },
    elementKey,
  );
}
