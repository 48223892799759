import React from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import PatientData from './PatientData/PatientData';
import style from './PatientMasterData.scss';
import { useCSS } from '../../../provider/CSSProvider';
import Age from './Age/Age';
import Gender from './Gender/Gender';
import Bmi from './Bmi/Bmi';
import Mission from './Mission/Mission';
import { Typography } from '@mui/material';
import { EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY } from '../../../models/genericElements/extendedPatientData';

export function PatientMasterData() {
  useCSS(style);
  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.INITIAL_SURVEY;
  const elementKeysOfPage = [EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY];

  const pageHeader = <PageHeader heading={getPageTitle(PageType.PatientMasterData)} noClearAllButton />;

  const pageFooter = (
    <PageFooter
      noBackButton
      pageType={PageType.PatientMasterData}
      diviId={DiviNavigationIds.Pat}
      elementKeys={elementKeysOfPage}
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Pat}
      elementKeys={elementKeysOfPage}
    >
      <div className='legend row'>
        <div className='indicator'></div>
        <Typography variant='subtitle1'>Automatisch übertragene Daten</Typography>
      </div>
      <div className='patient-master-data-wireframe'>
        <div className='patient-data'>
          <PatientData nodeType={nodeTypeOfPage} />
        </div>
        <Gender />
        <Bmi nodeType={nodeTypeOfPage} />
        <Age nodeType={nodeTypeOfPage} />
        <div className='mission'>
          <Mission />
        </div>
      </div>
    </DiviPage>
  );
}
