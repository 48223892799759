export enum PageType {
  PatientMasterData,
  Anamnese,
  Neurology,
  DataInitial,
  MedicalConditions,
  Injuries,
  DiagnosisInitial,
  Course,
  Medication,
  ReanimationDeath,
  Measures,
  Handover,
  OperationalData,
  Specifics,
  HandoverTo,
  Remarks,
  Acronym,
  SAMPLERPlusS,
}

// Order of the pages, used for navigation to previous and next page
const pageOrder: PageType[] = [
  PageType.PatientMasterData,
  PageType.OperationalData,
  PageType.Anamnese,
  PageType.Neurology,
  PageType.DataInitial,
  PageType.MedicalConditions,
  PageType.Injuries,
  PageType.DiagnosisInitial,
  PageType.Course,
  PageType.Medication,
  PageType.ReanimationDeath,
  PageType.Measures,
  PageType.Handover,
  PageType.Specifics,
  PageType.HandoverTo,
  PageType.Remarks,
  PageType.Acronym,
  PageType.SAMPLERPlusS,
];

type PageData = {
  [key in PageType]: {
    pageRoute: string;
    title: string;
  };
};

const pageData: PageData = {
  [PageType.Acronym]: { pageRoute: '/akronym', title: 'Akronym' },
  [PageType.PatientMasterData]: { pageRoute: '/', title: 'Pat-Stammdaten' },
  [PageType.OperationalData]: { pageRoute: '/fielding-data', title: 'Einsatztechnische Daten' },
  [PageType.Anamnese]: {
    pageRoute: '/anamnese',
    title: 'Notfallgeschehen, Anamnese, Erstbefund, Vormedikation, Vorbehandlung',
  },
  [PageType.Neurology]: {
    pageRoute: '/neurology',
    title: 'Erstbefunde - Neurologie',
  },
  [PageType.DataInitial]: {
    pageRoute: '/data-initial',
    title: 'Erstbefunde - Messwerte initial',
  },
  [PageType.MedicalConditions]: {
    pageRoute: '/medical-conditions',
    title: 'Erstbefunde - Erkrankungen',
  },
  [PageType.Injuries]: { pageRoute: '/injuries', title: 'Erstbefunde - Verletzungen' },
  [PageType.DiagnosisInitial]: {
    pageRoute: '/diagnosis-initial',
    title: 'Erstdiagnosen',
  },
  [PageType.Course]: { pageRoute: '/course', title: 'Verlaufsbeschreibung' },
  [PageType.Medication]: { pageRoute: '/medication', title: 'Medikation' },
  [PageType.ReanimationDeath]: {
    pageRoute: '/reanimation-death',
    title: 'Reanimation / Tod / Todesfeststellung',
  },
  [PageType.Measures]: { pageRoute: '/measures', title: 'Maßnahmen' },
  [PageType.Handover]: {
    pageRoute: '/handover',
    title: 'Übergabe',
  },
  [PageType.Specifics]: { pageRoute: '/specifics', title: 'Einsatzverlauf - Besonderheiten' },
  [PageType.HandoverTo]: { pageRoute: '/handover-to', title: 'Übergabe' },
  [PageType.Remarks]: { pageRoute: '/remarks', title: 'Bemerkungen' },
  [PageType.SAMPLERPlusS]: { pageRoute: '/sampler-plus-s', title: 'Akronym - SAMPLER+S' },
};

export function getPageRoute(pageType: PageType): string {
  if (!(pageType in pageData)) {
    throw new Error('PageType does not exist in pageData');
  }
  return pageData[pageType].pageRoute;
}

export function getPageTitle(pageType: PageType): string {
  if (!(pageType in pageData)) {
    throw new Error('PageType does not exist in pageData');
  }
  return pageData[pageType].title;
}

/**
 * Gets the route for the previous page.
 * @param pageType The type of the current page.
 */
export function getPreviousPage(pageType: PageType): PageType {
  const index = pageOrder.indexOf(pageType);
  if (index === -1) {
    throw new Error('PageType does not exist in pageOrder');
  }
  if (index === 0) {
    return pageOrder[pageOrder.length - 1];
  }

  return pageOrder[index - 1];
}

/**
 * Gets the route for the next page.
 * @param pageType The type of the current page.
 */
export function getNextPage(pageType: PageType): PageType {
  const index = pageOrder.indexOf(pageType);
  if (index === -1) {
    throw new Error('PageType does not exist in pageOrder');
  }
  if (index === pageOrder.length - 1) {
    return pageOrder[0];
  }

  return pageOrder[index + 1];
}
