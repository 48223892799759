export enum DiviNavigationIds {
  Pat = 'pat',
  IncidentData = 'incident-data',
  Anamnese = 'anamnese',
  Neurology = 'neurology',
  DataInitial = 'data-initial',
  MedicalConditions = 'medical-conditions',
  Injuries = 'injuries',
  InitialDiagnosis = 'initial-diagnosis',
  Course = 'course',
  Medication = 'medication',
  Death = 'death',
  Measures = 'measures',
  Handover = 'handover',
  Specifics = 'specifics',
  HandoverTo = 'handover-to',
  Remarks = 'remarks',
  ReanimationDeath = 'reanimation-death',
}

export enum DiviProgress {
  todo = 'todo',
  touched = 'touched',
  done = 'done',
}

export type DiviProgressStatesType = {
  [key in DiviNavigationIds]?: DiviProgress;
};

export const DIVI_PROGRESS_STATE_STORAGE_KEY = 'DIVI_PROGRESS_STATE';
