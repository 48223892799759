import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { NumericFieldValue } from '../../backendModels/generic-element.model';

export const QSOFA_RECORD_ELEMENT_KEY = 'QSOFA';

export type QSOFARecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof QSOFA_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          QSOFA_SCORE: NumericFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );
