import React, { ReactNode } from 'react';
import style from './MedicalScoreDisplay.scss';
import { useCSS } from '../../../provider/CSSProvider';
import { Typography } from '@mui/material';
import { convertToRomanNumber } from '../../../utils/util';

interface MedicalScoreDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  header?: ReactNode;
  medicalScore?: number | null;
  description?: ReactNode;
  /**
   * When true, the score value is displayed as roman numeral.
   */
  romanScore?: boolean;
  asHeader?: boolean;
}

export default function MedicalScoreDisplay(props: MedicalScoreDisplayProps) {
  useCSS(style);

  const { header, medicalScore, description, romanScore, asHeader, className, ...htmlAttributes } = props;

  function getScoreLabel(score: number | null | undefined): string {
    if (score === null || score === undefined) {
      return '--';
    }
    if (romanScore) {
      return convertToRomanNumber(score);
    } else {
      return score.toString();
    }
  }

  return (
    <div {...htmlAttributes} className={`column medical-score-display ${className ?? ''}`}>
      <div className='medical-score-display-header'>
        <Typography variant={asHeader ? 'h2' : 'body1'}>{header}</Typography>
        <Typography variant='body1' className='accent'>
          {getScoreLabel(medicalScore)}
        </Typography>
      </div>
      <Typography className='medical-score-display-description' variant='subtitle1'>
        {description}
      </Typography>
    </div>
  );
}
