export enum NeurologicalFeatureKeys {
  sideMark = 'SEITENZEICHEN',
  noSmiling = 'KEIN_LAECHELN',
  sightImpediment = 'SEHSTOERUNG',
  speechDisorder = 'SPRACHSTOERUNG',
  dementia = 'DEMENZ',
  paraplegicSymptoms = 'QUERSCHNITTSSYMPTOMATIK',
  babinskiSign = 'BABINSKI_ZEICHEN',
  meningism = 'MENINGISMUS',
  preExistingNeurologicalDeficits = 'VORBESTEHENDE_NEUROLOGISCHE_DEFIZITE',
}

const neurologicalFeatureLabels: Record<NeurologicalFeatureKeys, string> = {
  [NeurologicalFeatureKeys.sideMark]: 'Seitenzeichen',
  [NeurologicalFeatureKeys.noSmiling]: 'kein Lächeln',
  [NeurologicalFeatureKeys.speechDisorder]: 'Sprachstörung',
  [NeurologicalFeatureKeys.sightImpediment]: 'Sehstörung',
  [NeurologicalFeatureKeys.dementia]: 'Demenz',
  [NeurologicalFeatureKeys.paraplegicSymptoms]: 'Querschnittssymptomatik',
  [NeurologicalFeatureKeys.babinskiSign]: 'Babinski Zeichen',
  [NeurologicalFeatureKeys.meningism]: 'Meningismus',
  [NeurologicalFeatureKeys.preExistingNeurologicalDeficits]: 'vorbestehende neurologische Defizite',
};

export function getNeurologicalFeatureTuple(key: NeurologicalFeatureKeys): [NeurologicalFeatureKeys, string] {
  return [key, neurologicalFeatureLabels[key]];
}

export function getNeurologicalFeatureTuplesFor(keys: NeurologicalFeatureKeys[]): [NeurologicalFeatureKeys, string][] {
  return keys.map((k) => {
    return getNeurologicalFeatureTuple(k);
  });
}
