import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../../generic';

export enum InjuriesConnectionKeys {
  verletzungzusammenhangsportja = 'VERLETZUNG_ZUSAMMENHANG_SPORT_JA',
  verletzungzusammenhangsportnein = 'VERLETZUNG_ZUSAMMENHANG_SPORT_NEIN',
  verletzungzusammenhangarbeitja = 'VERLETZUNG_ZUSAMMENHANG_ARBEIT_JA',
  verletzungzusammenhangarbeitnein = 'VERLETZUNG_ZUSAMMENHANG_ARBEIT_NEIN',
  verletzungzusammenhangpolytrauma = 'VERLETZUNG_ZUSAMMENHANG_POLYTRAUMA',
}

export const INJURIES_CONNECTION_RECORD_ELEMENT_KEY = 'VERLETZUNGEN_ZUSAMMENHANG';

export type InjuriesConnectionRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof INJURIES_CONNECTION_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          VERLETZUNG_ZUSAMMENHANG_SPORT?: SingleSelectFieldValueTyped<InjuriesConnectionKeys>;
          VERLETZUNG_ZUSAMMENHANG_ARBEIT?: SingleSelectFieldValueTyped<InjuriesConnectionKeys>;
          VERLETZUNG_ZUSAMMENHANG_POLYTRAUMA?: MultiSelectFieldValueTyped<InjuriesConnectionKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

export const injuriesConnectionLabels: Record<InjuriesConnectionKeys, string> = {
  [InjuriesConnectionKeys.verletzungzusammenhangsportja]: 'Ja',
  [InjuriesConnectionKeys.verletzungzusammenhangsportnein]: 'Nein',
  [InjuriesConnectionKeys.verletzungzusammenhangarbeitja]: 'Ja',
  [InjuriesConnectionKeys.verletzungzusammenhangarbeitnein]: 'Nein',
  [InjuriesConnectionKeys.verletzungzusammenhangpolytrauma]: 'Polytrauma',
};

export function getInjuriesConnectionTuple(key: InjuriesConnectionKeys): [InjuriesConnectionKeys, string] {
  return [key, injuriesConnectionLabels[key]];
}

export function getInjuriesConnectionTuplesFor(keys: InjuriesConnectionKeys[]): [InjuriesConnectionKeys, string][] {
  return keys.map((k) => {
    return getInjuriesConnectionTuple(k);
  });
}
