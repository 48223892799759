import React from 'react';
import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

export interface OptionValueType<T> {
  /**
   * When not undefined this indicates that the value was created with custom input.
   */
  customInputValue?: string;
  /**
   * The label displayed in the options list.
   */
  label: string;
  /**
   * The value of the option
   */
  value: T;
}

export type CreatableAutocompleteProps<T> = {
  value?: OptionValueType<T>;
  options: OptionValueType<T>[];
  /**
   * Creates the value for the newly input value.
   * @param inputValue The value that is inputted manually.
   */
  newValueCreatorFunction: (inputValue: string) => T;
  onChange?: (value: OptionValueType<T>) => void;
  label?: string;
};

/**
 * Autocomplete that provides functionality to create new values.
 */
export default function CreatableAutocomplete<T>(props: CreatableAutocompleteProps<T>) {
  const { value, options, newValueCreatorFunction, onChange, label } = props;
  const filter = createFilterOptions<OptionValueType<T>>();

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => {
        if (newValue === null) {
          return;
        }
        if (typeof newValue === 'string') {
          onChange?.({
            label: newValue,
            value: newValueCreatorFunction(newValue),
          });
          return;
        }
        onChange?.(newValue);
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            customInputValue: inputValue,
            label: `Unbekannten Wirkstoff wählen: "${inputValue}" `,
            value: newValueCreatorFunction(inputValue),
          });
        }

        return filtered;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.customInputValue !== undefined) {
          return option.customInputValue;
        }
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{typeof option === 'string' ? option : option.label}</li>}
      freeSolo
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
