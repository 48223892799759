export enum CardId {
  AccessRoute = 'access-route',
  Airway = 'airway',
  Gcs = 'gcs',
  LimbMovements = 'limb-movements',
  AnamneseNote = 'anamnese-note',
  FirstAider = 'first-aider',
  FirstResponder = 'first-responder',
  Anticoagulants = 'anticoagulants',
  GeneralCondition = 'general-condition',
  Multiresistant = 'multiresistant',
  TreatmentSituation = 'treatment-situation',
  Breathing = 'breathing',
  Ecg = 'ecg',
  Pain = 'pain',
  Psyche = 'psyche',
  Skin = 'skin',
  VitalParameter = 'vital-parameter',
  NacaScore = 'naca-score',
  DiagnosisNote = 'diagnosis-note',
  PalliativeSituation = 'palliative-situation',
  Medication = 'medication',
  Consciousness = 'consciousness',
  NeurologicalFeatures = 'neurological-features',
  Pupils = 'pupils',
  HandoverTo = 'handover-to',
  Remarks = 'remarks',
  Reanimation = 'reanimation',
  Death = 'death',
  PreIntervention = 'pre-intervention',
  LysisTherapy = 'lysis-therapy',
  InjuryLocation = 'injury-location',
  InjuryActivity = 'injury-activity',
  InjuryCause = 'injury-cause',
  InjuryAccidentMechanism = 'injury-accident-mechanism',
  Ventilation = 'measures-breathing',
  MeasuresDefibrillation = 'measures-defibrillation',
  MeasuresReanimation = 'measures-reanimation',
  MeasuresOther = 'measures-other',
  Specifics = 'specifics',
  MedicalConditionZNS = 'medical-condition-zns',
  MedicalConditionCardiovascular = 'medical-condition-cardiovascular',
  MedicalConditionBreathing = 'medical-condition-breathing',
  MedicalConditionAbdomen = 'medical-condition-abdomen',
  MedicalConditionPsychiatry = 'medical-condition-psychiatry',
  MedicalConditionMetabolism = 'medical-condition-metabolism',
  MedicalConditionPediatrics = 'medical-condition-pediatrics',
  MedicalConditionGynecology = 'medical-condition-gynecology',
  OtherMedicalConditions = 'other-medical-conditions',
  RefusalOfMeasures = 'refusal-of-measures',
  SamplerPlusSNote = 'sampler-plus-s-note',
  PatientData = 'patient-data',
  Age = 'patient-age',
  Gender = 'patient-gender',
  Bmi = 'patient-bmi',
  Mission = 'patient-mission',
  MissionLocation = 'mission-location',
  MissionType = 'mission-type',
  SymptomStart = 'symptom-start',
  Destination = 'destination',
  Participants = 'participants',
}

type CardTitlesType = Record<CardId, string>;

const cardTitles: CardTitlesType = {
  [CardId.AccessRoute]: 'Zugänge',
  [CardId.Airway]: 'Atemweg', //Maßnahmen/Intubation
  [CardId.Gcs]: 'GCS',
  [CardId.AnamneseNote]: 'Notfallgeschehen, Anamnese, Erstbefund, Vormedikation, Vorbehandlung',
  [CardId.FirstAider]: 'Ersthelfermaßnahmen (Laien)',
  [CardId.FirstResponder]: 'First Responder',
  [CardId.Anticoagulants]: 'Antikoagulanzienmedikation (vor dem Ereignis)',
  [CardId.GeneralCondition]: 'AZ des Pat. vor dem Ereignis (entspr. ASA)',
  [CardId.Multiresistant]: 'Besiedelung mit multiresistenten Keimen',
  [CardId.TreatmentSituation]: 'Versorgungssituation',
  [CardId.Breathing]: 'Atmung',
  [CardId.Ecg]: 'EKG',
  [CardId.LimbMovements]: 'Extremitätenbewegung',
  [CardId.Pain]: 'Schmerzen',
  [CardId.Psyche]: 'Psyche',
  [CardId.Skin]: 'Haut',
  [CardId.Reanimation]: 'Reanimation',
  [CardId.Death]: 'Tod',
  [CardId.VitalParameter]: 'Vitalparameter',
  [CardId.NacaScore]: 'NACA SCORE',
  [CardId.DiagnosisNote]: 'Erstdiagnosen',
  [CardId.PalliativeSituation]: 'Palliative Situation',
  [CardId.Medication]: 'Medikation',
  [CardId.PreIntervention]: 'Vorab-Interventionen durch Rettungsdienst',
  [CardId.LysisTherapy]: 'Lysetherapie',
  [CardId.Consciousness]: 'Bewusstseinslage',
  [CardId.NeurologicalFeatures]: 'Neurologische Auffälligkeiten',
  [CardId.Pupils]: 'Pupillen',
  [CardId.HandoverTo]: '',
  [CardId.Remarks]: 'Bemerkungen (z.B. Verlauf, Hausarzt, Telefonnummer Angehörige, Notkompetenz-Maßnahmen)',
  [CardId.InjuryLocation]: 'Verletzungen',
  [CardId.InjuryActivity]: 'Aktivität',
  [CardId.InjuryCause]: 'Art / Ursache',
  [CardId.InjuryAccidentMechanism]: 'Unfallmechanismus',
  [CardId.Ventilation]: 'Beatmung', //Massnahmen
  [CardId.MeasuresDefibrillation]: 'Defibrillation',
  [CardId.MeasuresReanimation]: 'Reanimation',
  [CardId.MeasuresOther]: 'Sonstige',
  [CardId.Specifics]: '',
  [CardId.MedicalConditionZNS]: 'ZNS',
  [CardId.MedicalConditionCardiovascular]: 'Herz-Kreislauf',
  [CardId.MedicalConditionBreathing]: 'Atmung',
  [CardId.MedicalConditionAbdomen]: 'Abdomen',
  [CardId.MedicalConditionPsychiatry]: 'Psychiatrie',
  [CardId.MedicalConditionMetabolism]: 'Stoffwechsel',
  [CardId.MedicalConditionPediatrics]: 'Pädiatrie',
  [CardId.MedicalConditionGynecology]: 'Gynäkologie',
  [CardId.OtherMedicalConditions]: 'Sonstige',
  [CardId.RefusalOfMeasures]: '',
  [CardId.SamplerPlusSNote]: 'SAMPLER+S',
  [CardId.PatientData]: 'Patienten Stammdaten',
  [CardId.Age]: 'Alter',
  [CardId.Bmi]: 'BMI',
  [CardId.Gender]: 'Geschlecht',
  [CardId.Mission]: 'Einsatz',
  [CardId.MissionLocation]: 'Einsatz-Ort',
  [CardId.MissionType]: 'Einsatz-Art',
  [CardId.Participants]: 'Beteiligt',
  [CardId.SymptomStart]: 'Symptom Beginn',
  [CardId.Destination]: 'Transportziel',
};

export function getCardTitle(cardId: CardId): string {
  if (!(cardId in cardTitles)) {
    throw new Error('cardId does not exist in cardTitles');
  }
  return cardTitles[cardId] ?? '';
}
