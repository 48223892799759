import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { NodeType } from '../../../../backendModels/report.model';
import ScoreButtonList from '../../../customs/scoreButtonList/ScoreButtonList';
import MedicalScoreDisplay from '../../../customs/medicalScoreDisplay/MedicalScoreDisplay';
import { CardId, getCardTitle } from '../../../../models/diviCard';
import {
  DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY,
  DiagnosisInitialKeys,
  DiagnosisInitialRecord,
  getDiagnosisInitialTuplesFor,
  getNacaScoreForKey,
} from '../../../../models/genericElements/diagnosisInitial';
import { InputState } from '../../../../backendModels/general.model';
import { Draft } from 'immer';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';

const nacaScoreItems: [DiagnosisInitialKeys, string][] = getDiagnosisInitialTuplesFor([
  DiagnosisInitialKeys.nacai,
  DiagnosisInitialKeys.nacaii,
  DiagnosisInitialKeys.nacaiii,
  DiagnosisInitialKeys.nacaiv,
  DiagnosisInitialKeys.nacav,
  DiagnosisInitialKeys.nacavi,
  DiagnosisInitialKeys.nacavii,
]);

interface NacaScoreProps {
  nodeType: NodeType;
}

export default function NacaScore({ nodeType }: NacaScoreProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault(
    'generic',
    nodeType,
    DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY,
  ) as DiagnosisInitialRecord;

  const nacaScoreChanged = (nacaScore: DiagnosisInitialKeys) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<DiagnosisInitialRecord>) => {
        draft.inputState = InputState.ENTERED;
        delete draft.values?.NACA_SCORE;
        draft.values = {
          ...draft.values,
          NACA_SCORE: {
            fieldType: 'singleSelect',
            option: nacaScore,
          },
        };
      },
      DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY,
    );
  };
  return (
    <DiviFieldCard
      cardType={CardId.NacaScore}
      customTitleContent={
        <MedicalScoreDisplay
          asHeader={true}
          header={getCardTitle(CardId.NacaScore)}
          medicalScore={getNacaScoreForKey(
            record.inputState === InputState.ENTERED ? record.values?.NACA_SCORE?.option : undefined,
          )}
          romanScore={true}
          description='...'
        />
      }
    >
      <ScoreButtonList
        title=''
        reverse={false}
        listOfEntries={nacaScoreItems}
        selectedValue={record.inputState === InputState.ENTERED ? record.values?.NACA_SCORE?.option ?? null : null}
        onChange={nacaScoreChanged}
        romanScores={true}
      />
    </DiviFieldCard>
  );
}
