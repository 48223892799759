import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
  DIVI_PROGRESS_STATE_STORAGE_KEY,
  DiviNavigationIds,
  DiviProgress,
  DiviProgressStatesType,
} from '../../models/diviProgress';
import { LocalStorageContext } from '../../provider/LocalStorageProvider';

interface DiviNavigationElementProps {
  id: DiviNavigationIds;
  route?: string;
}

export function DiviNavigationElement(props: DiviNavigationElementProps) {
  const { id, route } = props;
  const state = useContext(LocalStorageContext).getData<DiviProgressStatesType>(DIVI_PROGRESS_STATE_STORAGE_KEY);

  return <NavLink id={id} className={state?.[id] ?? DiviProgress.todo} to={route ? route : '/'}></NavLink>;
}
