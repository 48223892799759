import React from 'react';
import { ToggleButton } from '@mui/material';
import { useCSS } from '../../../provider/CSSProvider';
import style from './SmileyRating.scss';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { InjuriesCategorySeverityKeys } from '../../../models/genericElements/injuries/InjuriesCategory';
import { Severity } from '../../../backendModels/injuries.model';

interface SmileyRatingProps {
  onChange: (value: Severity) => void;
  value: Severity | null;
}
export default function SmileyRating(props: SmileyRatingProps) {
  useCSS(style);

  const { onChange, value } = props;

  function handleChange(event: React.MouseEvent<HTMLElement>, value: Severity) {
    if (value) {
      onChange?.(value);
    }
  }

  return (
    <ToggleButtonGroup className='similey-rating' value={value ?? null} exclusive onChange={handleChange}>
      <ToggleButton className='smiley-rating-positive' value={InjuriesCategorySeverityKeys.minor}>
        <SentimentSatisfiedAltIcon />
      </ToggleButton>
      <ToggleButton className='smiley-rating-neutral' value={InjuriesCategorySeverityKeys.medium}>
        <SentimentNeutralIcon />
      </ToggleButton>
      <ToggleButton className='smiley-rating-negative' value={InjuriesCategorySeverityKeys.major}>
        <SentimentVeryDissatisfiedIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
