import { PUPILS_NORMAL_STATE, PupilsValue } from '../backendModels/pupils.model';
import { InputState } from '../backendModels/general.model';
import { cloneAndMerge } from '../utils/util';
import { RecordId } from '../backendModels/records.model';

export type PupilsRecord = { readonly id?: RecordId; readonly type: 'pupils' } & PupilsValue;

export enum PupilSizeKeys {
  normal = 'NORMAL',
  narrowed = 'NARROWED',
  dilated = 'DILATED',
}

export enum PupilReactionToLightKeys {
  normal = 'NORMAL',
  slow = 'SLOW',
  noReaction = 'NO_REACTION',
}

export enum PupilShapeKeys {
  normal = 'NORMAL',
  distorted = 'DISTORTED',
}

export function pupilTransitionInputState(record: PupilsRecord, newInputState: InputState): PupilsRecord {
  if (record.inputState === newInputState) {
    return record;
  }

  switch (newInputState) {
    case InputState.ENTERED:
      return { ...record, inputState: newInputState };
    case InputState.UNKNOWN:
      return { id: record.id, type: record.type, inputState: newInputState };
    case InputState.NORMAL:
      return cloneAndMerge(record, PUPILS_NORMAL_STATE);
  }
}
