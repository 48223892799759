import { Chip } from '@mui/material';
import React, { useContext } from 'react';
import style from './Acronyms.scss';
import { useCSS } from '../../../provider/CSSProvider';
import { getPageRoute, PageType } from '../../../models/diviPage';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import { useNavigate } from 'react-router-dom';

export function Acronyms() {
  useCSS(style);

  const { acronyms } = useContext(ReportsAPIContext);
  const navigate = useNavigate();

  const buttons =
    acronyms != null
      ? Object.getOwnPropertyNames(acronyms).map((key: string) => (
          <Chip
            key={key}
            className='acronym-button'
            variant='outlined'
            label={key}
            onClick={() => {
              navigate(getPageRoute(PageType.Acronym) + '/' + encodeURIComponent(key));
            }}
          />
        ))
      : [];

  return (
    <div className='acronym-header'>
      <Chip
        className='acronym-button'
        variant='outlined'
        label='SAMPLER+S'
        onClick={() => {
          navigate(getPageRoute(PageType.SAMPLERPlusS));
        }}
      />
      {buttons}
    </div>
  );
}
