import React, { useContext, useState } from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import dayjs from 'dayjs';
import TimeDialog from '../../customs/timeDialog/TimeDialog';
import { getPageTitle, PageType } from '../../../models/diviPage';
import InjuriesCategory from './InjuriesCategory/InjuriesCategory';
import { useCSS } from '../../../provider/CSSProvider';
import style from '../Injuries/Injuries.scss';
import InjuriesActivity from './InjuriesActivity/InjuriesActivity';
import InjuriesCause from './InjuriesCause/InjuriesCause';
import InjuriesAccidentMechanism from './InjuriesAccidentMechanism/InjuriesAccidentMechanism';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { INJURIES_CONNECTION_RECORD_ELEMENT_KEY } from '../../../models/genericElements/injuries/injuriesConnection';
import { INJURIES_ADDITION_RECORD_ELEMENT_KEY } from '../../../models/genericElements/injuries/injuriesAdditional';
import { INJURIES_CATEGORY_RECORD_ELEMENT_KEY } from '../../../models/genericElements/injuries/InjuriesCategory';

export function Injuries() {
  useCSS(style);

  const recordTypesOfPage = ['injuries'] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.INITIAL_SURVEY;
  const elementKeysOfPage = [
    INJURIES_CONNECTION_RECORD_ELEMENT_KEY,
    INJURIES_ADDITION_RECORD_ELEMENT_KEY,
    INJURIES_CATEGORY_RECORD_ELEMENT_KEY,
  ];

  const [timeDialogOpen, setTimeDialogOpen] = useState<boolean>(false);
  const reportAPI = useContext(ReportsAPIContext);
  const timestamp = dayjs(reportAPI.getNodeTimestamp(nodeTypeOfPage) ?? undefined);

  function setTimestamp(newTimestamp: dayjs.Dayjs) {
    reportAPI.setNodeTimestamp(newTimestamp.valueOf(), nodeTypeOfPage);
  }

  function openTimeDialog() {
    setTimeDialogOpen(true);
  }

  const pageHeader = (
    <PageHeader
      heading={getPageTitle(PageType.Injuries)}
      onTimeClicked={openTimeDialog}
      time={timestamp.format('HH:mm')}
    />
  );

  const pageFooter = (
    <PageFooter
      pageType={PageType.Injuries}
      diviId={DiviNavigationIds.Injuries}
      noPathButton
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
      diviId={DiviNavigationIds.Injuries}
    >
      <TimeDialog
        timestamp={{ timestamp: timestamp, setTimestamp: setTimestamp }}
        dialogVisible={{ open: timeDialogOpen, setOpen: setTimeDialogOpen }}
      />
      <div className='wireframe-container-injuries'>
        <div className='injury-location'>
          <InjuriesCategory nodeType={nodeTypeOfPage} />
        </div>
        <div className='injury-activity'>
          <InjuriesActivity nodeType={nodeTypeOfPage} />
        </div>
        <div className='injury-cause'>
          <InjuriesCause nodeType={nodeTypeOfPage} />
        </div>
        <div className='injury-accident-mechanism'>
          <InjuriesAccidentMechanism nodeType={nodeTypeOfPage} />
        </div>
      </div>
    </DiviPage>
  );
}
