import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { Components, createTheme, Theme, ThemeProvider } from '@mui/material';
import corpulsTheme from '../Theme/CorpulsTheme';
import _ from 'lodash';

// These objects are tracked by reference, i.e. a change of a reference count value will not trigger a rerender.
// If the component is not attached directly under document (e.g. in a shadow dom), you need to provide your own values.
export interface CSSContextType {
  referenceCounts: Map<CSSStyleSheet, number>;
  adoptedStyleSheets: CSSStyleSheet[];
  additionalThemeComponents: Components<Omit<Theme, 'components'>>;
}

const cssContextDefaults: CSSContextType = {
  referenceCounts: new Map<CSSStyleSheet, number>(),
  adoptedStyleSheets: document.adoptedStyleSheets,
  additionalThemeComponents: {},
};

export const CSSContext = createContext(cssContextDefaults);

// Call this function whenever you want to use a stylesheet in a component.
export function useCSS(stylesheet: CSSStyleSheet) {
  const { referenceCounts, adoptedStyleSheets } = useContext(CSSContext);

  useEffect(() => {
    if (referenceCounts.has(stylesheet)) {
      referenceCounts.set(stylesheet, referenceCounts.get(stylesheet)! + 1);
    } else {
      referenceCounts.set(stylesheet, 1);
      adoptedStyleSheets.push(stylesheet);
    }

    return () => {
      referenceCounts.set(stylesheet, referenceCounts.get(stylesheet)! - 1);
      if (referenceCounts.get(stylesheet) === 0) {
        referenceCounts.delete(stylesheet);
        adoptedStyleSheets.splice(adoptedStyleSheets.indexOf(stylesheet), 1);
      }
    };
  }, [stylesheet, referenceCounts, adoptedStyleSheets]);
}

interface CSSContextProviderProps {
  value: CSSContextType;
  children: ReactNode;
}

export function CSSContextProvider({ value, children }: CSSContextProviderProps) {
  const { additionalThemeComponents } = value;

  const theme = useMemo(
    () => createTheme({ ...corpulsTheme, components: _.merge(corpulsTheme.components!, additionalThemeComponents) }),
    [additionalThemeComponents],
  );

  return (
    <CSSContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CSSContext.Provider>
  );
}
