import {
  AccessRouteConfig,
  AccessRoutePlacement,
  AccessRouteSide,
  AccessRouteValue,
} from '../backendModels/access-route.model';
import _ from 'lodash';
import { OptionValueType } from '../App/customs/creatableAutocomplete/CreatableAutocomplete';
import { RecordId } from '../backendModels/records.model';

export type AccessRouteInfoRow = {
  recordId: RecordId[];
  typeLabel: string;
  locationSizeLabels: string[];
  count: number;
  alreadyPlaced: boolean;
};

type AccessRouteRecord = { readonly id?: RecordId; readonly type: 'accessRoute' } & AccessRouteValue;

export enum AccessRouteSizeKeys {
  G26 = '26G',
  G24 = '24G',
  G22 = '22G',
  G20 = '20',
  G18 = '18G',
  G17 = '17G',
  G16 = '16G',
  G14 = '14G',
  MM15 = '15MM',
  MM25 = '25MM',
  MM45 = '45MM',
  NASAL_STANDARD = 'NASAL_STANDARD',
}

export enum AccessRouteLocationKeys {
  HAND = 'HAND',
  FOREARM = 'FOREARM',
  ELBOW_PIT = 'ELBOW_PIT',
  HUMERUS = 'HUMERUS',
  FOOT = 'FOOT',
  TIBIA = 'TIBIA',
  FEMUR = 'FEMUR',
  VENA_JUGULARIS_INTERNA = 'VENA_JUGULARIS_INTERNA',
  VENA_JUGULARIS_EXTERNA = 'VENA_JUGULARIS_EXTERNA',
  VENA_SUBCLAVIA = 'VENA_SUBCLAVIA',
  VENA_BASILICA = 'VENA_BASILICA',
  VENA_BRACHIOCEPHALICA = 'VENA_BRACHIOCEPHALICA',
  VENA_FEMORALIS = 'VENA_FEMORALIS',
  TIBIA_PROXIMAL = 'TIBIA_PROXIMAL',
  TIBIA_DISTAL = 'TIBIA_DISTAL',
  FEMUR_DISTAL = 'FEMUR_DISTAL',
  HUMERUS_PROXIMAL = 'HUMERUS_PROXIMAL',
  TRANSNASAL = 'TRANSNASAL',
}

export enum Color {
  VIOLET = '#7F30C0',
  YELLOW = '#FFFF00',
  BLUE = '#0000FF',
  PINK = '#FFACF0',
  GREEN = '#00A000',
  WHITE = '#FFFFFF',
  GREY = '#808080',
  ORANGE = '#FF8000',
  RED = '#FF0000',
}

export enum AccessRouteTypeKeys {
  INTRAVENOUS_PERIPHERAL = 'INTRAVENOUS_PERIPHERAL',
  INTRAVENOUS_CENTRAL = 'INTRAVENOUS_CENTRAL',
  INTRAOSSEOUS = 'INTRAOSSEOUS',
  TRANSNASAL_APPLICATOR = 'TRANSNASAL_APPLICATOR',
}

const accessRouteLabels: Record<AccessRouteTypeKeys, string> = {
  [AccessRouteTypeKeys.INTRAVENOUS_PERIPHERAL]: 'Peripherer Zugang',
  [AccessRouteTypeKeys.INTRAVENOUS_CENTRAL]: 'intravenös - zentral',
  [AccessRouteTypeKeys.INTRAOSSEOUS]: 'Intraossäre Punktion',
  [AccessRouteTypeKeys.TRANSNASAL_APPLICATOR]: 'Transnasal-Applikator',
};

export const accessRouteConfig: AccessRouteConfig = {
  defaultAccessRoute: 'INTRAVENOUS_PERIPHERAL',
  details: [
    {
      accessRoute: 'INTRAVENOUS_PERIPHERAL',
      defaultLocation: AccessRouteLocationKeys.HAND,
      locations: [
        { key: AccessRouteLocationKeys.HAND, label: 'Hand' },
        { key: AccessRouteLocationKeys.FOREARM, label: 'Unterarm' },
        { key: AccessRouteLocationKeys.ELBOW_PIT, label: 'Armbeuge' },
        { key: AccessRouteLocationKeys.HUMERUS, label: 'Oberarm' },
        { key: AccessRouteLocationKeys.FOOT, label: 'Fußrücken' },
        { key: AccessRouteLocationKeys.TIBIA, label: 'Unterschenkel' },
        { key: AccessRouteLocationKeys.FEMUR, label: 'Oberschenkel' },
      ],
      sizes: [
        { key: AccessRouteSizeKeys.G26, label: '26G Violett', color: Color.VIOLET },
        { key: AccessRouteSizeKeys.G24, label: '24G Gelb', color: Color.YELLOW },
        { key: AccessRouteSizeKeys.G22, label: '22G Blau', color: Color.BLUE },
        { key: AccessRouteSizeKeys.G20, label: '20G Rosa', color: Color.PINK },
        { key: AccessRouteSizeKeys.G18, label: '18G Grün', color: Color.GREEN },
        { key: AccessRouteSizeKeys.G17, label: '17G Weiß', color: Color.WHITE },
        { key: AccessRouteSizeKeys.G16, label: '16G Grau', color: Color.GREY },
        { key: AccessRouteSizeKeys.G14, label: '14G Orange', color: Color.ORANGE },
      ],
    },
    {
      accessRoute: 'INTRAVENOUS_CENTRAL',
      locations: [
        { key: AccessRouteLocationKeys.VENA_JUGULARIS_INTERNA, label: 'v. jugularis interna' },
        { key: AccessRouteLocationKeys.VENA_JUGULARIS_EXTERNA, label: 'v. jugularis externa' },
        { key: AccessRouteLocationKeys.VENA_SUBCLAVIA, label: 'v. subclavia' },
        { key: AccessRouteLocationKeys.VENA_BASILICA, label: 'v. basilica' },
        { key: AccessRouteLocationKeys.VENA_BRACHIOCEPHALICA, label: 'v. brachiocephalica' },
        { key: AccessRouteLocationKeys.VENA_FEMORALIS, label: 'v. femoralis' },
      ],
      sizes: [
        { key: AccessRouteSizeKeys.G26, label: '26G Violett', color: Color.VIOLET },
        { key: AccessRouteSizeKeys.G24, label: '24G Gelb', color: Color.YELLOW },
        { key: AccessRouteSizeKeys.G22, label: '22G Blau', color: Color.BLUE },
        { key: AccessRouteSizeKeys.G20, label: '20G Rosa', color: Color.PINK },
        { key: AccessRouteSizeKeys.G18, label: '18G Grün', color: Color.GREEN },
        { key: AccessRouteSizeKeys.G17, label: '17G Weiß', color: Color.WHITE },
        { key: AccessRouteSizeKeys.G16, label: '16G Grau', color: Color.GREY },
        { key: AccessRouteSizeKeys.G14, label: '14G Orange', color: Color.ORANGE },
      ],
    },
    {
      accessRoute: 'INTRAOSSEOUS',
      locations: [
        { key: AccessRouteLocationKeys.TIBIA_PROXIMAL, label: 'Tibia proximal' },
        { key: AccessRouteLocationKeys.TIBIA_DISTAL, label: 'Tibia distal' },
        { key: AccessRouteLocationKeys.FEMUR_DISTAL, label: 'Femur distal' },
        { key: AccessRouteLocationKeys.HUMERUS_PROXIMAL, label: 'Humerus proximal' },
      ],
      sizes: [
        { key: AccessRouteSizeKeys.MM15, label: '15mm Rot', color: Color.RED },
        { key: AccessRouteSizeKeys.MM25, label: '25mm Blau', color: Color.BLUE },
        { key: AccessRouteSizeKeys.MM45, label: '45mm Gelb', color: Color.YELLOW },
      ],
    },
    {
      accessRoute: 'TRANSNASAL_APPLICATOR',
      defaultLocation: AccessRouteLocationKeys.TRANSNASAL,
      locations: [{ key: AccessRouteLocationKeys.TRANSNASAL, label: 'transnasal' }],
      sizes: [{ key: AccessRouteSizeKeys.NASAL_STANDARD, label: 'Standard', color: Color.WHITE }],
    },
  ],
};

export function collectAccessRoutes(records: AccessRouteRecord[]) {
  const data: Record<string, AccessRouteInfoRow> = {};
  for (let i = 0; i < records.length; i += 1) {
    const record = records[i];
    if (record === undefined) {
      continue;
    }
    const accessRoute = record.accessRoute;
    if (accessRoute === undefined) {
      continue;
    }
    const locationSizeLabel = getLocationSizeLabel(record);
    const alreadyPlaced = isAlreadyPlaced(record);
    if (accessRoute in data) {
      data[accessRoute].locationSizeLabels.push(locationSizeLabel);
      data[accessRoute].count += 1;
      data[accessRoute].alreadyPlaced ||= alreadyPlaced;
      data[accessRoute].recordId.push(record.id ?? -1);
    } else {
      const typeLabel = getTypeLabel(record);
      data[accessRoute] = {
        recordId: [record.id ?? -1],
        typeLabel: typeLabel,
        locationSizeLabels: [locationSizeLabel],
        count: 1,
        alreadyPlaced: alreadyPlaced,
      };
    }
  }
  return data;
}

export function convertToAccessRouteLabels(records: AccessRouteRecord[]): AccessRouteInfoRow[] {
  return Object.values(collectAccessRoutes(records));
}

function getTypeLabel(record: AccessRouteRecord): string {
  return accessRouteLabels[record.accessRoute as AccessRouteTypeKeys];
}

function getLocationLabel(record: AccessRouteRecord): string {
  const locations = _.find(accessRouteConfig.details, { accessRoute: record.accessRoute })?.locations;
  const label = _.find(locations, { key: record.location })?.label;
  if (label === undefined) {
    return 'Ort unbekannt';
  }
  if (record.side === AccessRouteSide.LEFT) {
    return label + ' (Links)';
  } else if (record.side === AccessRouteSide.RIGHT) {
    return label + ' (Rechts)';
  }
  return label;
}

function getSizeLabel(record: AccessRouteRecord): string {
  const sizes = _.find(accessRouteConfig.details, { accessRoute: record.accessRoute })?.sizes;
  const label = _.find(sizes, { key: record.size })?.label;
  return label ?? 'Größe unbekannt';
}

function getLocationSizeLabel(record: AccessRouteRecord): string {
  return `${getLocationLabel(record)} / ${getSizeLabel(record)}`;
}

function isAlreadyPlaced(record: AccessRouteRecord): boolean {
  return record.accessRoutePlacement === AccessRoutePlacement.IS_ALREADY_PLACED;
}

export function getTypeLabels(): OptionValueType<string>[] {
  return Object.entries(accessRouteLabels).map(([key, value]) => ({
    value: key,
    label: value,
  }));
}

export function getLocationLabels(accessRoute: AccessRouteTypeKeys): OptionValueType<string>[] {
  const locations = _.find(accessRouteConfig.details, { accessRoute: accessRoute })?.locations;
  return (
    locations?.map((location) => ({
      value: location.key,
      label: location.label,
    })) ?? []
  );
}

export function getSideLabels(): OptionValueType<string>[] {
  return [
    { value: AccessRouteSide.LEFT, label: 'Links' },
    { value: AccessRouteSide.RIGHT, label: 'Rechts' },
    { value: AccessRouteSide.NONE, label: 'Keine' },
  ];
}

export function getSizeLabels(accessRoute: AccessRouteTypeKeys): OptionValueType<string>[] {
  const sizes = _.find(accessRouteConfig.details, { accessRoute: accessRoute })?.sizes;
  return (
    sizes?.map((size) => ({
      value: size.key,
      label: size.label,
    })) ?? []
  );
}

export function getDefaultAccessRoute(): AccessRouteTypeKeys {
  return accessRouteConfig.defaultAccessRoute as AccessRouteTypeKeys;
}

export function getDefaultLocation(accessRoute: AccessRouteTypeKeys): AccessRouteLocationKeys | undefined {
  return (
    (_.find(accessRouteConfig.details, { accessRoute: accessRoute })?.defaultLocation as AccessRouteLocationKeys) ??
    undefined
  );
}
