import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum MedicalConditionPediatricsKeys {
  fieberkrampf = 'FIEBERKRAMPF',
  pseudokrupp = 'PSEUDOKROUPP',
  sids = 'SIDS',
}

export const MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY = 'ERKRANKUNGEN_PAED';

export type MedicalConditionPediatricsRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          ERKRANKUNGEN_PAED?: MultiSelectFieldValueTyped<MedicalConditionPediatricsKeys>;
          ERKRANKUNGEN_PAED_SONSTIGE?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
    | {
        readonly inputState: InputState.NORMAL;
        readonly values: null;
      }
  );

const pediatricsLabels: Record<MedicalConditionPediatricsKeys, string> = {
  [MedicalConditionPediatricsKeys.fieberkrampf]: 'Fieberkrampf',
  [MedicalConditionPediatricsKeys.pseudokrupp]: 'Pseudokrupp',
  [MedicalConditionPediatricsKeys.sids]: 'SIDS / Near-SIDS',
};

export function getPediatricsTupel(key: MedicalConditionPediatricsKeys): [MedicalConditionPediatricsKeys, string] {
  return [key, pediatricsLabels[key]];
}

export function getPediatricsTupelsFor(
  keys: MedicalConditionPediatricsKeys[],
): [MedicalConditionPediatricsKeys, string][] {
  return keys.map((k) => {
    return getPediatricsTupel(k);
  });
}
