import { RecordId } from '../backendModels/records.model';
import { AirwayManagementValue } from '../backendModels/airway-management.model';

export type AirwayManagementRecord = {
  readonly id?: RecordId;
  readonly type: 'airwayManagement';
} & AirwayManagementValue;

export enum AirwayTreatmentKeys {
  freimachenDerAtemwege = 'FREIMACHEN_DER_ATEMWEGE',
  absaugen = 'ABSAUGEN',
  absaugpumpe = 'ABSAUGPUMPE',
  koniotomieChirurgischerAtemweg = 'KONIOTOMIE_CHIRURGISCHER_ATEMWEG',
  trachealkanuelenwechsel = 'TRACHEALKANUELENWECHSEL',
  sonstigerAtemswgszugang = 'SONSTIGER_ATEMWEGSZUGANG',
  supraglottischeAtemwegshilfe = 'SUPRAGLOTTISCHE_ATEMWEGSHILFE',
  koniotomie = 'KONIOTOMIE',
  maskenBeatmung = 'MASKEN_BEATMUNG',
  maskenBeatmungKontrolliert = 'MASKEN_BEATMUNG_KONTROLLIERT',
  maskenBeatmungNichtMoeglich = 'MASKEN_BEATMUNG_NICHT_MOEGLICH',
  maskenBeatmungUnterstuetzend = 'MASKEN_BEATMUNG_UNTERSTUETZEND',
}

export const AirwayTreatmentLabels: Record<AirwayTreatmentKeys, string> = {
  [AirwayTreatmentKeys.freimachenDerAtemwege]: 'Freimachen der Atemwege',
  [AirwayTreatmentKeys.absaugen]: 'Absaugen',
  [AirwayTreatmentKeys.absaugpumpe]: 'Absaugpumpe',
  [AirwayTreatmentKeys.koniotomieChirurgischerAtemweg]: 'Koniotomie / chirurgischer Atemweg',
  [AirwayTreatmentKeys.trachealkanuelenwechsel]: 'Trachealkanülenwechsel',
  [AirwayTreatmentKeys.sonstigerAtemswgszugang]: 'sonstiger Atemwegszugang',
  [AirwayTreatmentKeys.supraglottischeAtemwegshilfe]: 'supraglottische Atemwegshilfe',
  [AirwayTreatmentKeys.koniotomie]: 'Koniotomie',
  [AirwayTreatmentKeys.maskenBeatmung]: 'Masken-/Beutel-Beatmung',
  [AirwayTreatmentKeys.maskenBeatmungKontrolliert]: 'kontrolliert',
  [AirwayTreatmentKeys.maskenBeatmungNichtMoeglich]: 'nicht möglich',
  [AirwayTreatmentKeys.maskenBeatmungUnterstuetzend]: 'unterstützend, Demand-Ventil',
};

export function getAirwayTreatmentTuple(treatment: AirwayTreatmentKeys): [AirwayTreatmentKeys, string] {
  return [treatment, AirwayTreatmentLabels[treatment]];
}

export function getAirwayTreatmentTuplesFor(keys: AirwayTreatmentKeys[]): [AirwayTreatmentKeys, string][] {
  return keys.map((k) => {
    return getAirwayTreatmentTuple(k);
  });
}
