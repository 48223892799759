import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { TextFieldValue } from '../../backendModels/generic-element.model';

export enum SAMPLERPlusSKeys {
  S = 'SAMPLERS_S',
  A = 'SAMPLERS_A',
  M = 'SAMPLERS_M',
  P = 'SAMPLERS_P',
  L = 'SAMPLERS_L',
  E = 'SAMPLERS_E',
  R = 'SAMPLERS_R',
  plusS = 'SAMPLERS_P_S',
}

export const SAMPLER_PLUS_S_RECORD_ELEMENT_KEY = 'SAMPLERS';

export type SAMPLERPlusSRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof SAMPLER_PLUS_S_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          SAMPLERS_S?: TextFieldValue;
          SAMPLERS_A?: TextFieldValue;
          SAMPLERS_M?: TextFieldValue;
          SAMPLERS_P?: TextFieldValue;
          SAMPLERS_L?: TextFieldValue;
          SAMPLERS_E?: TextFieldValue;
          SAMPLERS_R?: TextFieldValue;
          SAMPLERS_P_S?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );
