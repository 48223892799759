export enum MissionLocationKeys {
  wohnung = 'Wohnung',
  altenheim = 'Altenheim',
  arbeitsplatz = 'Arbeitsplatz',
  sportstaette = 'Sportstätte',
  arztpraxis = 'Arztpraxis',
  krankenhaus = 'Krankenhaus',
  geburtshaus = 'Geburtshaus',
  oeffentlicherraum = 'öff. Raum',
  strasse = 'Straße',
  schule = 'Schule',
  bildungseinrichtung = 'Bildungseinrichtung',
  massenveranstaltung = 'Massenveranstaltung',
  sonstige = 'Sonstige',
  strokeUnit = 'Stroke Unit',
  herzkatheter = 'Herzkatheter',
  traumazentrum = 'Traumazentrum',
}

const missionLocationNameTuples: Record<MissionLocationKeys, string> = {
  [MissionLocationKeys.wohnung]: 'Wohnung',
  [MissionLocationKeys.altenheim]: 'Altenheim',
  [MissionLocationKeys.arbeitsplatz]: 'Arbeitsplatz',
  [MissionLocationKeys.sportstaette]: 'Sportstätte',
  [MissionLocationKeys.arztpraxis]: 'Arztpraxis',
  [MissionLocationKeys.krankenhaus]: 'Krankenhaus',
  [MissionLocationKeys.geburtshaus]: 'Geburtshaus',
  [MissionLocationKeys.oeffentlicherraum]: 'öff. Raum',
  [MissionLocationKeys.strasse]: 'Straße',
  [MissionLocationKeys.schule]: 'Schule',
  [MissionLocationKeys.bildungseinrichtung]: 'Bildungseinrichtung',
  [MissionLocationKeys.massenveranstaltung]: 'Massenveranstaltung',
  [MissionLocationKeys.sonstige]: 'Sonstige',
  [MissionLocationKeys.strokeUnit]: 'Stroke Unit',
  [MissionLocationKeys.herzkatheter]: 'Herzkatheter',
  [MissionLocationKeys.traumazentrum]: 'Traumazentrum',
};

export function getMissionLocationNameTuple(symptom: MissionLocationKeys): [MissionLocationKeys, string] {
  return [symptom, missionLocationNameTuples[symptom]];
}

export function getMissionLocationNameTuplesFor(symptoms: MissionLocationKeys[]): [MissionLocationKeys, string][] {
  return symptoms.map((s) => {
    return getMissionLocationNameTuple(s);
  });
}
