export enum SymptomStartKeys {
  ueber24H = 'OVER_24_HOURS_AGO',
  beobachtet = 'COLLAPSE_OBSERVED',
  unbekannt = 'UNKNOWN',
}

const SymptomStartNameTuples: Record<SymptomStartKeys, string> = {
  [SymptomStartKeys.ueber24H]: 'vor über 24h',
  [SymptomStartKeys.beobachtet]: 'Kollaps beobachtet',
  [SymptomStartKeys.unbekannt]: 'unbekannt',
};

export function getSymptomStartNameTuple(symptom: SymptomStartKeys): [SymptomStartKeys, string] {
  return [symptom, SymptomStartNameTuples[symptom]];
}

export function getSymptomStartNameTuplesFor(symptoms: SymptomStartKeys[]): [SymptomStartKeys, string][] {
  return symptoms.map((s) => {
    return getSymptomStartNameTuple(s);
  });
}
