// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#card-pre-intervention .symptoms-list .MuiFormGroup-root{display:flex;flex-direction:row;justify-content:space-between}#card-pre-intervention .symptoms-list .MuiFormGroup-root .MuiFormControlLabel-root{width:40%}`, "",{"version":3,"sources":["webpack://./src/App/Pages/Medication/PreIntervention/PreIntervention.scss"],"names":[],"mappings":"AACE,yDACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,mFACE,SAAA","sourcesContent":["#card-pre-intervention {\n  .symptoms-list .MuiFormGroup-root {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n\n    .MuiFormControlLabel-root {\n      width: 40%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
