import React, { useContext, useState } from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';
import dayjs from 'dayjs';
import TimeDialog from '../../customs/timeDialog/TimeDialog';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { useCSS } from '../../../provider/CSSProvider';
import style from './MedicalConditions.scss';
import MedicalConditionZNS from './ZNS/MedicalConditionZNS';
import MedicalConditionCardiovascular from './Cardiovascular/MedicalConditionCardiovascular';
import MedicalConditionBreathing from './Breathing/MedicalConditionBreathing';
import MedicalConditionAbdomen from './Abdomen/MedicalConditionAbdomen';
import MedicalConditionPsychiatry from './Psychiatry/MedicalConditionPsychiatry';
import MedicalConditionMetabolism from './Metabolism/MedicalConditionMetabolism';
import MedicalConditionPediatrics from './Pediatrics/MedicalConditionPediatrics';
import MedicalConditionGynecology from './Gynecology/MedicalConditionGynecology';
import MedicalConditionOther from './Other/MedicalConditionOther';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { MEDICAL_CONDITION_ABDOMEN_ELEMENT_KEY } from '../../../models/genericElements/medicalConditions/abdomen';
import { MEDICAL_CONDITION_BREATHING_ELEMENT_KEY } from '../../../models/genericElements/medicalConditions/breathing';
import { MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY } from '../../../models/genericElements/medicalConditions/cardiovascular';
import { MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY } from '../../../models/genericElements/medicalConditions/gynecology';
import { MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY } from '../../../models/genericElements/medicalConditions/metabolism';
import { MEDICAL_CONDITION_OTHER_ELEMENT_KEY } from '../../../models/genericElements/medicalConditions/other';
import { MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY } from '../../../models/genericElements/medicalConditions/pediatrics';
import { MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY } from '../../../models/genericElements/medicalConditions/zns';
import { MEDICAL_CONDITION_PSYCHIATRY_ELEMENT_KEY } from '../../../models/genericElements/medicalConditions/psychiatry';

export function MedicalConditions() {
  useCSS(style);

  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.INITIAL_SURVEY;
  const elementKeysOfPage = [
    MEDICAL_CONDITION_ABDOMEN_ELEMENT_KEY,
    MEDICAL_CONDITION_BREATHING_ELEMENT_KEY,
    MEDICAL_CONDITION_CARDIOVASCULAR_ELEMENT_KEY,
    MEDICAL_CONDITION_GYNECOLOGY_ELEMENT_KEY,
    MEDICAL_CONDITION_METABOLISM_ELEMENT_KEY,
    MEDICAL_CONDITION_OTHER_ELEMENT_KEY,
    MEDICAL_CONDITION_PEDIATRICS_ELEMENT_KEY,
    MEDICAL_CONDITION_PSYCHIATRY_ELEMENT_KEY,
    MEDICAL_CONDITION_ZNS_RECORD_ELEMENT_KEY,
  ];

  const [timeDialogOpen, setTimeDialogOpen] = useState<boolean>(false);
  const reportAPI = useContext(ReportsAPIContext);
  const timestamp = dayjs(reportAPI.getNodeTimestamp(nodeTypeOfPage) ?? undefined);

  function setTimestamp(newTimestamp: dayjs.Dayjs) {
    reportAPI.setNodeTimestamp(newTimestamp.valueOf(), nodeTypeOfPage);
  }

  function openTimeDialog() {
    setTimeDialogOpen(true);
  }

  const pageHeader = (
    <PageHeader
      heading={getPageTitle(PageType.MedicalConditions)}
      onTimeClicked={openTimeDialog}
      time={timestamp.format('HH:mm')}
    />
  );

  const pageFooter = (
    <PageFooter
      pageType={PageType.MedicalConditions}
      diviId={DiviNavigationIds.MedicalConditions}
      noPathButton
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      elementKeys={elementKeysOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.MedicalConditions}
    >
      <TimeDialog
        timestamp={{ timestamp: timestamp, setTimestamp: setTimestamp }}
        dialogVisible={{ open: timeDialogOpen, setOpen: setTimeDialogOpen }}
      />
      <div className='wireframe-container-medical-conditions'>
        <MedicalConditionZNS nodeType={nodeTypeOfPage} />
        <MedicalConditionCardiovascular nodeType={nodeTypeOfPage} />
        <MedicalConditionBreathing nodeType={nodeTypeOfPage} />
        <MedicalConditionAbdomen nodeType={nodeTypeOfPage} />
        <MedicalConditionPsychiatry nodeType={nodeTypeOfPage} />
        <MedicalConditionMetabolism nodeType={nodeTypeOfPage} />
        <MedicalConditionPediatrics nodeType={nodeTypeOfPage} />
        <MedicalConditionGynecology nodeType={nodeTypeOfPage} />
        <MedicalConditionOther nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
