import { NodeRecord } from '../backendModels/records.model';
import { Node } from '../backendModels/report.model';

export enum NodeChangeKind {
  createRecord = 'createRecord',
  updateRecord = 'updateRecord',
  deleteRecord = 'deleteRecord',
  createNode = 'createNode',
  updateNode = 'updateNode',
  deleteNode = 'deleteNode',
}

export interface CreateRecord {
  kind: NodeChangeKind.createRecord;
  nodeId: number;
  newRecord: NodeRecord;
}

export interface UpdateRecord {
  kind: NodeChangeKind.updateRecord;
  nodeId: number;
  newRecord: NodeRecord;
}

export interface DeleteRecord {
  kind: NodeChangeKind.deleteRecord;
  nodeId: number;
  recordId: number;
}

export interface CreateNode {
  kind: NodeChangeKind.createNode;
  newNode: Omit<Node, 'id' | 'owner'>;
}

export interface UpdateNode {
  kind: NodeChangeKind.updateNode;
  newNode: Node;
}

export interface DeleteNode {
  kind: NodeChangeKind.deleteNode;
  nodeId: number;
}

export type NodeChange = CreateRecord | UpdateRecord | DeleteRecord | CreateNode | UpdateNode | DeleteNode;

export class NodeChangeEvent extends Event {
  changes: NodeChange[];

  constructor(type: string, changes: NodeChange[]) {
    super(type, { bubbles: true });

    this.changes = changes;
  }
}
