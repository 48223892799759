import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../generic';
import { TextFieldValue } from '../../backendModels/generic-element.model';

export enum DiagnosisInitialKeys {
  nacai = 'NACA_I',
  nacaii = 'NACA_II',
  nacaiii = 'NACA_III',
  nacaiv = 'NACA_IV',
  nacav = 'NACA_V',
  nacavi = 'NACA_VI',
  nacavii = 'NACA_VII',
  erstdiagnosenPalliativ = 'ERSTDIAGNOSEN_PALLIATIV',
}

export const DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY = 'ERSTDIAGNOSEN';

export type DiagnosisInitialRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof DIAGNOSIS_INITIAL_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          ERSTDIAGNOSEN?: TextFieldValue;
          NACA_SCORE?: SingleSelectFieldValueTyped<DiagnosisInitialKeys>;
          ERSTDIAGNOSEN_PALLIATIV?: MultiSelectFieldValueTyped<DiagnosisInitialKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export const diagnosisInitialLabels: Record<DiagnosisInitialKeys, string> = {
  [DiagnosisInitialKeys.nacai]: 'geringfügige Störung',
  [DiagnosisInitialKeys.nacaii]: 'leichte Störung',
  [DiagnosisInitialKeys.nacaiii]: 'mäßige Störung',
  [DiagnosisInitialKeys.nacaiv]: 'Lebensgefahr nicht auszuschließen',
  [DiagnosisInitialKeys.nacav]: 'akute Lebensgefahr',
  [DiagnosisInitialKeys.nacavi]: 'Reanimation',
  [DiagnosisInitialKeys.nacavii]: 'Tod',
  [DiagnosisInitialKeys.erstdiagnosenPalliativ]: 'Palliative Situation',
};

export function getDiagnosisInitialTuple(key: DiagnosisInitialKeys): [DiagnosisInitialKeys, string] {
  return [key, diagnosisInitialLabels[key]];
}

export function getDiagnosisInitialTuplesFor(keys: DiagnosisInitialKeys[]): [DiagnosisInitialKeys, string][] {
  return keys.map((k) => {
    return getDiagnosisInitialTuple(k);
  });
}

export function getNacaScoreForKey(
  key: Omit<DiagnosisInitialKeys, DiagnosisInitialKeys.erstdiagnosenPalliativ> | undefined,
): number | undefined {
  if (key === undefined) {
    return undefined;
  }
  const keys = Object.keys(diagnosisInitialLabels);
  return keys.indexOf(key as string) + 1;
}
