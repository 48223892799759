import React from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import style from './ReanimationDeath.scss';
import { DiviNavigationIds } from '../../../models/diviProgress';
import { NodeType } from '../../../backendModels/report.model';
import DiviPage from '../../customs/DiviPage/DiviPage';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import Reanimation from './Reanimation/Reanimation';
import Death from './Death/Death';
import { useCSS } from '../../../provider/CSSProvider';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { REANIMATION_DEATH_RECORD_ELEMENT_KEY } from '../../../models/genericElements/reanimationDeath';

export function ReanimationDeath() {
  useCSS(style);

  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.GENERIC;
  const elementKeysOfPage = [REANIMATION_DEATH_RECORD_ELEMENT_KEY];

  const pageHeader = <PageHeader heading={getPageTitle(PageType.ReanimationDeath)} />;

  const pageFooter = <PageFooter pageType={PageType.ReanimationDeath} diviId={DiviNavigationIds.ReanimationDeath} />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
      diviId={DiviNavigationIds.ReanimationDeath}
    >
      <div className='wireframe-container-reanimation-death'>
        <Reanimation nodeType={nodeTypeOfPage} />
        <Death nodeType={nodeTypeOfPage} />
      </div>
    </DiviPage>
  );
}
