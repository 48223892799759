import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { NodeType } from '../../../../backendModels/report.model';
import { getSituationsAndSymptomsTuplesFor, SituationsAndSymptomsKeys } from '../../../../models/situationsAndSymptoms';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import _ from 'lodash';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';

interface GeneralConditionProps {
  nodeType: NodeType;
}

const generalConditionKeys = [
  SituationsAndSymptomsKeys.zuvorGesund,
  SituationsAndSymptomsKeys.zuvorLeichtEingeschraenkt,
  SituationsAndSymptomsKeys.zuvorDeutlichEingeschraenkt,
  SituationsAndSymptomsKeys.zuvorAkutLebensgefaehrdet,
  SituationsAndSymptomsKeys.zuvorMoribund,
];
const generalConditionItems = getSituationsAndSymptomsTuplesFor(generalConditionKeys);

export default function GeneralCondition({ nodeType }: GeneralConditionProps) {
  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.situationAndSymptoms.type, nodeType);

  const symptoms = (record.situationAndSymptoms ?? []) as SituationsAndSymptomsKeys[];

  const changeSymptoms = (event: React.ChangeEvent<HTMLInputElement>) => {
    reportAPI.adaptRecord(defaultRecords.situationAndSymptoms.type, nodeType, (draft) => ({
      ...draft,
      situationAndSymptoms: [..._.difference(symptoms, generalConditionKeys), event.target.value],
    }));
  };

  return (
    <DiviFieldCard cardType={CardId.GeneralCondition}>
      <div className='symptoms-list'>
        <RadioList
          items={generalConditionItems}
          name='ekg-group'
          value={_.intersection(symptoms, generalConditionKeys)[0] ?? null}
          onChange={changeSymptoms}
        />
      </div>
    </DiviFieldCard>
  );
}
