import React, { useContext, useState } from 'react';
import style from './PageFooter.scss';
import { Button, Divider, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useCSS } from '../../../../provider/CSSProvider';
import {
  DIVI_PROGRESS_STATE_STORAGE_KEY,
  DiviNavigationIds,
  DiviProgress,
  DiviProgressStatesType,
} from '../../../../models/diviProgress';
import { useNavigate } from 'react-router-dom';
import { LocalStorageContext } from '../../../../provider/LocalStorageProvider';
import { ReactComponent as ReadyIcon } from '../../../../assets/images/icons/ready.svg';
import { ReactComponent as ReadyFilledIcon } from '../../../../assets/images/icons/ready-filled.svg';
import { ReactComponent as HeartPlusIcon } from '../../../../assets/images/icons/heart-plus.svg';
import { ReactComponent as HearthCheckedIcon } from '../../../../assets/images/icons/heart-checked.svg';
import { getNextPage, getPageRoute, getPageTitle, getPreviousPage, PageType } from '../../../../models/diviPage';
import { useDiviPageContext } from '../DiviPage';
import { getEnhancedInputState } from '../../../../models/custom';
import { BaseNodeRecordValueTypeMap } from '../../../../backendModels/records.model';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import { InputState } from '../../../../backendModels/general.model';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';

export interface PageFooterProps {
  pageType: PageType;
  diviId: DiviNavigationIds;
  noPathButton?: boolean;
  noBackButton?: boolean;
  noDoneButton?: boolean;
  noNextButton?: boolean;
  backInHistoryOnBack?: boolean;
  nodeType?: NodeType;
  recordTypes?: (keyof BaseNodeRecordValueTypeMap)[];
  elementKeys?: string[]; // Do not include generic in the recordTypes
}

function PageFooter({
  pageType,
  diviId,
  noPathButton,
  noBackButton,
  noDoneButton,
  noNextButton,
  backInHistoryOnBack,
  nodeType,
  recordTypes,
  elementKeys,
}: PageFooterProps) {
  useCSS(style);
  const backRoute = getPageRoute(getPreviousPage(pageType));
  const nextPage = getNextPage(pageType);
  const nextRoute = noNextButton ? false : getPageRoute(nextPage);
  const nextText = getPageTitle(nextPage);
  const noNormalButton = noPathButton ?? false;
  const hideBackButton = noBackButton ?? false;
  const hideDoneButton = noDoneButton ?? false;

  const navigate = useNavigate();

  const localStorage = useContext(LocalStorageContext);
  const [state, setState] = localStorage.asState<DiviProgressStatesType>(DIVI_PROGRESS_STATE_STORAGE_KEY);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

  const { handleNoPathologicalFindings, handleClearAll } = useDiviPageContext();
  const reportAPI = useContext(ReportsAPIContext);

  const hasEntries =
    nodeType &&
    ((recordTypes
      ?.filter((recordType) => recordType !== 'vitalParameters' && recordType !== 'circulation')
      .some((recordType) => getEnhancedInputState(recordType, reportAPI.findMultipleRecords(recordType, nodeType))) ??
      false) ||
      (elementKeys?.some((elementKey) =>
        getEnhancedInputState('generic', reportAPI.findMultipleRecords('generic', nodeType, elementKey)),
      ) ??
        false));

  const allNormal =
    nodeType &&
    (recordTypes
      ?.filter((recordType) => recordType !== 'vitalParameters' && recordType !== 'circulation')
      .every(
        (recordType) =>
          getEnhancedInputState(recordType, reportAPI.findMultipleRecords(recordType, nodeType)) === InputState.NORMAL,
      ) ??
      true) &&
    (elementKeys?.every(
      (elementKey) =>
        getEnhancedInputState('generic', reportAPI.findMultipleRecords('generic', nodeType, elementKey)) ===
        InputState.NORMAL,
    ) ??
      true);

  const toggleDone = () => {
    setState((oldData) => {
      // might be corrected as soon as rerender
      return {
        ...oldData,
        [diviId]: state?.[diviId] !== DiviProgress.done ? DiviProgress.done : DiviProgress.todo,
      };
    });
  };

  const openDialog = () => {
    if (hasEntries && !allNormal) {
      setConfirmationDialogOpen(true);
    } else if (hasEntries && allNormal) {
      handleClearAll();
    } else {
      setPageToNormal();
    }
  };

  const handleOk = () => {
    setConfirmationDialogOpen(false);
    setPageToNormal();
  };

  const handleCancel = () => {
    setConfirmationDialogOpen(false);
  };

  const setPageToNormal = () => {
    handleNoPathologicalFindings();
  };

  return (
    <div>
      <Divider />
      <ConfirmationDialog
        dialogVisible={{ open: confirmationDialogOpen, setOpen: setConfirmationDialogOpen }}
        onOk={handleOk}
        onCancel={handleCancel}
        headline='Seite als "ohne path. Befund" markieren'
        text='Sind sie sicher, dass sie die gesamte Seite auf "ohne path. Befund" setzen möchten? Ihre
            bisherigen Einträge auf dieser Seite werden durch diese Aktion unwiederbringlich überschrieben.'
      />
      <div id='PageFooter'>
        <div className='left-side'>
          {backRoute && !hideBackButton && (
            <IconButton aria-label='back' onClick={() => (backInHistoryOnBack ? navigate(-1) : navigate(backRoute))}>
              <ArrowBackIcon />
            </IconButton>
          )}
          {noNormalButton && (
            <Button
              variant={allNormal ? 'contained' : 'text'}
              onClick={openDialog}
              disableElevation
              startIcon={allNormal ? <HearthCheckedIcon fill='white' /> : <HeartPlusIcon fill='black' />}
              aria-label='als fertig markieren'
              className='no-path-button'
            >
              ohne path. Befund
            </Button>
          )}
        </div>
        <div className='right-side '>
          {!hideDoneButton ? (
            <Button
              variant={(state?.[diviId] ?? DiviProgress.todo) === DiviProgress.done ? 'contained' : 'text'}
              onClick={toggleDone}
              disableElevation
              startIcon={
                (state?.[diviId] ?? DiviProgress.todo) === DiviProgress.done ? <ReadyFilledIcon /> : <ReadyIcon />
              }
              aria-label='als fertig markieren'
              className='ready-button'
            >
              vollständig
            </Button>
          ) : (
            <div />
          )}
          {nextRoute && (
            <>
              <Button
                onClick={() => navigate(nextRoute)}
                variant='text'
                disableElevation
                endIcon={<ArrowForwardIcon />}
                aria-label='next'
                className='navigation-next'
              >
                <Typography className='next-label' variant='body1'>
                  {nextText}
                </Typography>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PageFooter;
