import React, { useContext, useState } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { NodeType } from '../../../../backendModels/report.model';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Chip, IconButton } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/delete.svg';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  ApplicationMethod,
  Medication,
  MedicationSource,
  MedicationValue,
} from '../../../../backendModels/medication.model';
import AddMedicationDialog from './AddMedicationDialog';
import { convertToMedicationLabels, MedicationInfoRow } from '../../../../models/medication';
import { CardId } from '../../../../models/diviCard';
import { NodeRecordTypeMap, RecordId } from '../../../../backendModels/records.model';
import { AlarmAdd } from '@mui/icons-material';
import style from './MedicationCard.scss';
import { useCSS } from '../../../../provider/CSSProvider';
import { AddTimerDialog } from './AddTimerDialog';
import dayjs from 'dayjs';

interface MedicationCardProps {
  nodeType: NodeType;
}

export interface MedicationTimerIdentifier {
  recordId: number;
}

export default function MedicationCard({ nodeType }: MedicationCardProps) {
  useCSS(style);

  const [addMedicationDialogOpen, setAddMedicationDialogOpen] = useState<boolean>(false);

  const [addTimerDialogOpen, setAddTimerDialogOpen] = useState(false);
  const [suggestedTimerName, setSuggestedTimerName] = useState<string>('');
  const [timerCorrelatedRecordId, setTimerCorrelatedRecordId] = useState<number | undefined>();

  const reportAPI = useContext(ReportsAPIContext);
  const nodes = reportAPI.findMedicationNodes();
  const records = nodes.flatMap(
    (node) => node.records.filter((record) => record.type === 'medication') as NodeRecordTypeMap['medication'][],
  );
  const timers = reportAPI.timers!.filter((timer) => timer.identifier as MedicationTimerIdentifier);
  const timerIdentifiers = timers.map((timer) => (timer?.identifier as MedicationTimerIdentifier)?.recordId);

  const medicationsList: readonly Medication[] = reportAPI.sharedData!.medications! || [];
  const applicationMethods: readonly ApplicationMethod[] = reportAPI.sharedData!.applicationMethods! || [];

  const data: MedicationInfoRow[] = convertToMedicationLabels(records, medicationsList);

  function deleteMedication(recordId: RecordId) {
    const node = nodes.find((node) => node.records.some((record) => record.id === recordId));
    if (node != null) {
      if (node.records.length > 1) {
        throw new Error('Node for deletion contains more than the medication record');
      }
      reportAPI.deleteNode(node.id);
    }
  }

  function addMedication(medication: MedicationValue) {
    reportAPI.createNodeWithRecord({ type: 'medication', ...medication }, nodeType, dayjs().valueOf());
  }

  function openAddMedicationDialog() {
    setAddMedicationDialogOpen(true);
  }

  function openAddTimerDialog(medication: MedicationInfoRow) {
    setSuggestedTimerName(
      (medication.source === MedicationSource.CONFIGURATION
        ? medication.activeSubstance
        : medication.customActiveSubstance) ?? '',
    );
    setTimerCorrelatedRecordId(typeof medication.recordId === 'number' ? medication.recordId : undefined);
    setAddTimerDialogOpen(true);
  }

  return (
    <DiviFieldCard cardType={CardId.Medication} recordType={defaultRecords.medication.type} nodeType={nodeType}>
      <div id='medication-area'>
        <AddMedicationDialog
          medicationList={medicationsList}
          applicationMethods={applicationMethods}
          open={addMedicationDialogOpen}
          onOpenChanged={setAddMedicationDialogOpen}
          addMedication={addMedication}
        />
        <AddTimerDialog
          open={addTimerDialogOpen}
          onOpenChanged={setAddTimerDialogOpen}
          suggestedName={suggestedTimerName}
          correlatedRecordId={timerCorrelatedRecordId}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Wirkstoff</TableCell>
              <TableCell align='left'>Dosis</TableCell>
              <TableCell align='right'>
                <Button variant='outlined' endIcon={<AddCircleIcon />} onClick={openAddMedicationDialog}>
                  Medikation hinzufügen
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align='center'>
                  Keine Medikation
                </TableCell>
              </TableRow>
            ) : (
              data.map((medication) => (
                <TableRow
                  key={
                    (typeof medication.recordId === 'object'
                      ? `optimistic${medication.recordId.optimistic}`
                      : medication.recordId) ?? -1
                  }
                >
                  <TableCell align='left'>
                    {medication.source === MedicationSource.CONFIGURATION
                      ? medication.activeSubstance
                      : medication.customActiveSubstance}
                  </TableCell>
                  <TableCell align='left'>
                    {medication.dosage}&nbsp;
                    {medication.unit}
                  </TableCell>
                  <TableCell align='right'>
                    {typeof medication.recordId === 'number' && timerIdentifiers.includes(medication.recordId) ? (
                      <span className='add-timer-button'>Timer angelegt</span>
                    ) : (
                      <Chip
                        className='add-timer-button'
                        variant='outlined'
                        avatar={<AlarmAdd />}
                        label='Timer'
                        onClick={() => openAddTimerDialog(medication)}
                      ></Chip>
                    )}
                    <IconButton aria-label='delete' onClick={() => deleteMedication(medication.recordId)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
    </DiviFieldCard>
  );
}
