import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { TextFieldValue } from '../../../backendModels/generic-element.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../../generic';
import { NumericFieldValue } from '../../../backendModels/generic-element.model';

export enum DefibrillationKeys {
  defibrillationaed = 'DEFIBRILLATION_AED',
  defibrillationdefi = 'DEFIBRILLATION_DEFI',
  defibrillationerstanwendunglaien = 'DEFIBRILLATION_ERSTANWENDUNG_LAIEN',
  defibrillationerstanwendungfirstresponder = 'DEFIBRILLATION_ERSTANWENDUNG_FIRST_RESPONDER',
  defibrillationerstanwendungrd = 'DEFIBRILLATION_ERSTANWENDUNG_RD',
  defibrillationerstanwendungarzt = 'DEFIBRILLATION_ERSTANWENDUNG_ARZT',
  defibrillationimpulstypmono = 'DEFIBRILLATION_IMPULS_TYP_MONO',
  defibrillationimpulstypbi = 'DEFIBRILLATION_IMPULS_TYP_BI',
}

export const DEFIBRILLATION_RECORD_ELEMENT_KEY = 'DEFIBRILLATION';

export type DefibrillationRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof DEFIBRILLATION_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          DEFIBRILLATION_TYP?: MultiSelectFieldValueTyped<DefibrillationKeys>;
          DEFIBRILLATION_ERSTANWENDUNG?: SingleSelectFieldValueTyped<DefibrillationKeys>;
          DEFIBRILLATION_IMPULS_TYP?: SingleSelectFieldValueTyped<DefibrillationKeys>;
          DEFIBRILLATION_ERSTER_SCHOCK?: TextFieldValue;
          DEFIBRILLATION_ERSTER_ROSC?: TextFieldValue;
          DEFIBRILLATION_ANZAHL_SCHOCKS?: NumericFieldValue;
          DEFIBRILLATION_MAX_ENERGIE?: NumericFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export const defibrillationLabels: Record<DefibrillationKeys, string> = {
  [DefibrillationKeys.defibrillationaed]: 'AED',
  [DefibrillationKeys.defibrillationdefi]: 'Defi',
  [DefibrillationKeys.defibrillationerstanwendunglaien]: 'Laien',
  [DefibrillationKeys.defibrillationerstanwendungfirstresponder]: 'First Resp.',
  [DefibrillationKeys.defibrillationerstanwendungrd]: 'Rettungsdienst',
  [DefibrillationKeys.defibrillationerstanwendungarzt]: 'Arzt',
  [DefibrillationKeys.defibrillationimpulstypmono]: 'monophasisch',
  [DefibrillationKeys.defibrillationimpulstypbi]: 'biphasisch',
};

export function getDefibrillationTuple(key: DefibrillationKeys): [DefibrillationKeys, string] {
  return [key, defibrillationLabels[key]];
}

export function getDefibrillationTuplesFor(keys: DefibrillationKeys[]): [DefibrillationKeys, string][] {
  return keys.map((k) => {
    return getDefibrillationTuple(k);
  });
}
