import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { NodeType } from '../../../../backendModels/report.model';
import { getSituationsAndSymptomsTuplesFor, SituationsAndSymptomsKeys } from '../../../../models/situationsAndSymptoms';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import _ from 'lodash';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';

interface AnamneseProps {
  nodeType: NodeType;
}

const treatmentSituationKeys = [
  SituationsAndSymptomsKeys.zuvorUnabhaengig,
  SituationsAndSymptomsKeys.zuvorPflegeZuhause,
  SituationsAndSymptomsKeys.zuvorPflegeInInstitution,
];
const treatmentSituationItems = getSituationsAndSymptomsTuplesFor(treatmentSituationKeys);

export default function TreatmentSituation({ nodeType }: AnamneseProps) {
  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.situationAndSymptoms.type, nodeType);

  const symptoms = (record.situationAndSymptoms ?? []) as SituationsAndSymptomsKeys[];

  const changeSymptoms = (event: React.ChangeEvent<HTMLInputElement>) => {
    reportAPI.adaptRecord(defaultRecords.situationAndSymptoms.type, nodeType, (draft) => ({
      ...draft,
      situationAndSymptoms: [..._.difference(symptoms, treatmentSituationKeys), event.target.value],
    }));
  };

  return (
    <DiviFieldCard cardType={CardId.TreatmentSituation}>
      <div className='symptoms-list'>
        <RadioList
          items={treatmentSituationItems}
          name='ekg-group'
          value={_.intersection(symptoms, treatmentSituationKeys)[0] ?? null}
          onChange={changeSymptoms}
        />
      </div>
    </DiviFieldCard>
  );
}
