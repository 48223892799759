import { NodeRecordTypeMap } from './backendModels/records.model';
import { InputState } from './backendModels/general.model';
import { MedicationSource } from './backendModels/medication.model';
import { PressureInfusion } from './backendModels/infusion.model';
import { AccessRoutePlacement, AccessRouteSide } from './backendModels/access-route.model';

export const defaultRecords: NodeRecordTypeMap = {
  airway: {
    type: 'airway',
    inputState: InputState.UNKNOWN,
  },
  breathing: {
    type: 'breathing',
    inputState: InputState.UNKNOWN,
  },
  consciousness: {
    type: 'consciousness',
    inputState: InputState.UNKNOWN,
  },
  ecgFindings: {
    type: 'ecgFindings',
    inputState: InputState.ENTERED,
  },
  circulation: {
    type: 'circulation',
    inputState: InputState.UNKNOWN,
  },
  injuries: {
    type: 'injuries',
    inputState: InputState.NORMAL,
  },
  disability: {
    type: 'disability',
    inputState: InputState.UNKNOWN,
  },
  gcs: {
    type: 'gcs',
  },
  pupils: {
    type: 'pupils',
    inputState: InputState.UNKNOWN,
  },
  psyche: {
    type: 'psyche',
    inputState: InputState.UNKNOWN,
  },
  fast: {
    type: 'fast',
    inputState: InputState.UNKNOWN,
  },
  environment: {
    type: 'environment',
    inputState: InputState.UNKNOWN,
  },
  vitalParameters: {
    type: 'vitalParameters',
    vitalParameters: [],
  },
  pain: {
    type: 'pain',
    inputState: InputState.UNKNOWN,
  },
  situationAndSymptoms: {
    type: 'situationAndSymptoms',
  },
  allergies: {
    type: 'allergies',
    inputState: InputState.UNKNOWN,
  },
  patientMedication: {
    type: 'patientMedication',
    inputState: InputState.UNKNOWN,
  },
  pastMedicalHistory: {
    type: 'pastMedicalHistory',
    inputState: InputState.UNKNOWN,
  },
  lastInOut: {
    type: 'lastInOut',
    inputState: InputState.UNKNOWN,
  },
  priorEvents: {
    type: 'priorEvents',
    inputState: InputState.UNKNOWN,
  },
  riskFactors: {
    type: 'riskFactors',
    inputState: InputState.UNKNOWN,
  },
  note: {
    type: 'note',
  },
  infusion: {
    type: 'infusion',
    pressureInfusion: PressureInfusion.VALUE_NOT_AVAILABLE,
  },
  accessRoute: {
    type: 'accessRoute',
    side: AccessRouteSide.NONE,
    accessRoutePlacement: AccessRoutePlacement.VALUE_NOT_AVAILABLE,
  },
  traumaManagement: {
    type: 'traumaManagement',
    treatments: [],
  },
  oxygen: {
    type: 'oxygen',
  },
  airwayManagement: {
    type: 'airwayManagement',
    treatments: [],
  },
  medication: {
    type: 'medication',
    source: MedicationSource.CONFIGURATION,
  },
  temperatureManagement: {
    type: 'temperatureManagement',
    treatments: [],
  },
  intubation: {
    type: 'intubation',
    intubation: undefined,
    intubationSize: undefined,
    intubationAttempts: undefined,
    applicationMethod: 'UNKNOWN',
    intubationDifficulty: 'VALUE_NOT_AVAILABLE',
    videoLaryngoscopy: 'VALUE_NOT_AVAILABLE',
    tubePlacement: 'VALUE_NOT_AVAILABLE',
  },
  generic: {
    type: 'generic',
    elementKey: '',
    values: {},
  },
};
