import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';

export default function Mission() {
  const reportAPI = useContext(ReportsAPIContext);
  const controlCenter = reportAPI.controlCenter;

  const prefilled = useFreshCallback((value: string | undefined | null) => {
    return value != null ? 'prefilled' : '';
  });

  return (
    <DiviFieldCard cardType={CardId.Mission}>
      <div className='row'>
        <DebouncedTextField
          className={prefilled(controlCenter?.missionNumber)}
          label='Einsatz Nr.'
          InputProps={{
            disabled: true,
          }}
          value={controlCenter?.missionNumber ?? ''}
        />
        <DebouncedTextField
          className={prefilled(controlCenter?.location)}
          label='Standort'
          value={controlCenter?.location ?? ''}
          InputProps={{
            disabled: true,
          }}
        />
        <DebouncedTextField
          className={prefilled(controlCenter?.personnelNumber)}
          label='Pers. Nr.'
          InputProps={{
            disabled: true,
          }}
          value={controlCenter?.personnelNumber ?? ''}
        />
        <DebouncedTextField
          className={prefilled(controlCenter?.controlCenter)}
          label='Leitstelle (KFZ-Kennz.)'
          value={controlCenter?.controlCenter ?? ''}
          InputProps={{
            disabled: true,
          }}
        />
      </div>
    </DiviFieldCard>
  );
}
