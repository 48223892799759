import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';
import { getGenderTuplesFor } from '../../../../models/genericElements/extendedPatientData';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';
import { PatientGender } from '../../../../backendModels/patient.model';

const genderRadioItems: [PatientGender, string][] = getGenderTuplesFor([
  PatientGender.MALE,
  PatientGender.FEMALE,
  PatientGender.UNKNOWN,
]);

export default function Gender() {
  const { patient, patientUpdate } = useContext(ReportsAPIContext);

  const handleGenderChange = useFreshCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    patientUpdate?.({ ...patient, gender: event.target.value as PatientGender });
  });

  return (
    <DiviFieldCard cardType={CardId.Gender}>
      <RadioList
        className='symptoms-list'
        items={genderRadioItems}
        value={patient?.gender}
        onChange={handleGenderChange}
      />
    </DiviFieldCard>
  );
}
