import { NodeRecordTypeMap } from '../../../backendModels/records.model';
import { InputState } from '../../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../../generic';
import { TextFieldValue } from '../../../backendModels/generic-element.model';

export enum EquipmentKeys {
  zwoelfKanalEKG = '12_KANAL_EKG',
  funkuebermittlung = 'FUNKUEBERMITTLUNG',
  externerSchrittmacher = 'EXTERNER_SCHRITTMACHER',
  spritzenpumpe = 'SPRITZENPUMPE',
  invasiverBlutdruck = 'INVASIVER_BLUTDRUCK',
  kardioversion = 'KARDIOVERSION',
  verband = 'VERBAND',
  reposition = 'REPOSITION',
  beckenschlinge = 'BECKENSCHLINGE',
  thoraxDrainageRe = 'THORAX_DRAINAGE_RE',
  thoraxDrainageLi = 'THORAX_DRAINAGE_LI',
  thorakotomie = 'THORAKOTOMIE',
  nadeldekompression = 'NADELDEKOMPRESSION',
  ultraschall = 'ULTRASCHALL',
  magensonde = 'MAGENSONDE',
  blasenkathether = 'BLASENKATHETHER',
  entbindung = 'ENTBINDUNG',
  krisenintervention = 'KRISENINTERVENTION',
  besondereHygiene = 'BESONDEREHYGIENE',
  tourniquet = 'TOURNIQUET',
  haemostyptikum = 'HAEMOSTYPTIKUM',
  thoraxverschlusspflaster = 'THORAXVERSCHLUSSPFLASTER',
}

const equipmentLabels: Record<EquipmentKeys, string> = {
  [EquipmentKeys.zwoelfKanalEKG]: '12-Kanal EKG',
  [EquipmentKeys.funkuebermittlung]: 'Funkübermittlung',
  [EquipmentKeys.externerSchrittmacher]: 'externe Schrittmacher',
  [EquipmentKeys.spritzenpumpe]: 'Spritzenpumpe(n)',
  [EquipmentKeys.invasiverBlutdruck]: 'invasiver RR',
  [EquipmentKeys.kardioversion]: 'Kardioversion',
  [EquipmentKeys.verband]: 'Verband',
  [EquipmentKeys.reposition]: 'Reposition',
  [EquipmentKeys.beckenschlinge]: 'Beckenschlinge',
  [EquipmentKeys.thoraxDrainageRe]: 'Thoraxdrainage re.',
  [EquipmentKeys.thoraxDrainageLi]: 'Thoraxdrainage li.',
  [EquipmentKeys.thorakotomie]: 'Thorakotomie',
  [EquipmentKeys.nadeldekompression]: 'Nadeldekompression',
  [EquipmentKeys.ultraschall]: 'Ultraschall',
  [EquipmentKeys.magensonde]: 'Magensonde',
  [EquipmentKeys.blasenkathether]: 'Blasenkathether',
  [EquipmentKeys.entbindung]: 'Entbindung',
  [EquipmentKeys.krisenintervention]: 'Krisenintervention',
  [EquipmentKeys.besondereHygiene]: 'besondere Hygiene',
  [EquipmentKeys.tourniquet]: 'Tourniquet',
  [EquipmentKeys.haemostyptikum]: 'Haemostyptikum',
  [EquipmentKeys.thoraxverschlusspflaster]: 'Thoraxverschlusspflaster',
};

export const EQUIPMENT_RECORD_ELEMENT_KEY = 'EQUIPMENT';

export type EquipmentRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof EQUIPMENT_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          EQUIPMENT?: MultiSelectFieldValueTyped<EquipmentKeys>;
          EQUIPMENT_SONSTIGES?: TextFieldValue;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function getEquipmentTuple(key: EquipmentKeys): [EquipmentKeys, string] {
  return [key, equipmentLabels[key]];
}

export function getEquipmentTuplesFor(keys: EquipmentKeys[]): [EquipmentKeys, string][] {
  return keys.map((k) => {
    return getEquipmentTuple(k);
  });
}
