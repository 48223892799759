import { ConsciousnessValue } from '../backendModels/consciousness.model';
import { InputState } from '../backendModels/general.model';
import _ from 'lodash';
import { RecordId } from '../backendModels/records.model';

export enum ConsciousnessKeys {
  woke = 'WACH',
  clouded = 'GETRUEBT',
  unconscious = 'BEWUSSTLOS',
  reactionToSpeech = 'REAKT_AUF_ANSPRACHE',
  reactionToPainStimulus = 'REAKT_AUF_SCHMERZ',
  narcotized = 'NARKOSE',
}

export const consciousnessRadioKeys: ConsciousnessKeys[] = [ConsciousnessKeys.clouded, ConsciousnessKeys.unconscious];

const consciousnessLabels: Record<ConsciousnessKeys, string> = {
  [ConsciousnessKeys.woke]: 'wach',
  [ConsciousnessKeys.clouded]: 'getrübt',
  [ConsciousnessKeys.unconscious]: 'bewustlos',
  [ConsciousnessKeys.reactionToSpeech]: 'Reaktion auf Ansprache',
  [ConsciousnessKeys.reactionToPainStimulus]: 'Reaktion auf Schmerzreiz',
  [ConsciousnessKeys.narcotized]: 'analgosediert / Narkose',
};

export function getConsciousnessTupel(key: ConsciousnessKeys): [ConsciousnessKeys, string] {
  return [key, consciousnessLabels[key]];
}

export function getConsciousnessTupelsFor(keys: ConsciousnessKeys[]): [ConsciousnessKeys, string][] {
  return keys.map((k) => {
    return getConsciousnessTupel(k);
  });
}

export type ConsciousnessRecord = {
  readonly id?: RecordId;
  readonly type: 'consciousness';
} & ConsciousnessValue;

export function getRadioValue(record: ConsciousnessRecord): ConsciousnessKeys | null {
  if (record.inputState === InputState.NORMAL) {
    return ConsciousnessKeys.woke;
  } else if (record.inputState === InputState.ENTERED) {
    const radioSymptoms = _.intersection(record.symptoms, consciousnessRadioKeys) as ConsciousnessKeys[];
    return radioSymptoms[0] ?? ConsciousnessKeys.woke;
  } else {
    return null;
  }
}
