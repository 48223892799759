export enum SituationsAndSymptomsKeys {
  zuvorGesund = 'ZUVOR_GESUND',
  zuvorLeichtEingeschraenkt = 'ZUVOR_LEICHT_EINGESCHRAENKT',
  zuvorDeutlichEingeschraenkt = 'ZUVOR_DEUTLICH_EINGESCHRAENKT',
  zuvorAkutLebensgefaehrdet = 'ZUVOR_AKUT_LEBENSGEFAEHRDET',
  zuvorMoribund = 'ZUVOR_MORIBUND',
  zuvorUnabhaengig = 'ZUVOR_UNABHAENGIG',
  zuvorPflegeZuhause = 'ZUVOR_PFLEGE_ZUHAUSE',
  zuvorPflegeInInstitution = 'ZUVOR_PFLEGE_IN_INSTITUTION',
  ehSuffizient = 'EH_SUFFIZIENT',
  ehInsuffizient = 'EH_INSUFFIZIENT',
  keineEH = 'KEINE_EH',
  multiresistenteKeimeBekannt = 'MULTIRESISTENTE_KEIME_BEKANNT',
  multiresistenteKeimeAbgedeckt = 'MULTIRESISTENE_KEIME_ABGEDECKT',
  multiresistenteKeimeOffen = 'MULTIRESISTENE_KEIME_OFFEN',
}

const situationsAndSymptomsLabels: Record<SituationsAndSymptomsKeys, string> = {
  [SituationsAndSymptomsKeys.zuvorGesund]: 'gesund',
  [SituationsAndSymptomsKeys.zuvorLeichtEingeschraenkt]: 'leicht eingeschränkt',
  [SituationsAndSymptomsKeys.zuvorDeutlichEingeschraenkt]: 'deutlich eingeschränkt',
  [SituationsAndSymptomsKeys.zuvorAkutLebensgefaehrdet]: 'akut lebensgefährdet',
  [SituationsAndSymptomsKeys.zuvorMoribund]: 'Moribund',
  [SituationsAndSymptomsKeys.zuvorUnabhaengig]: 'unabhängig',
  [SituationsAndSymptomsKeys.zuvorPflegeZuhause]: 'Pflege zuhause',
  [SituationsAndSymptomsKeys.zuvorPflegeInInstitution]: 'Pflege in Institution',
  [SituationsAndSymptomsKeys.ehSuffizient]: 'suffizient',
  [SituationsAndSymptomsKeys.ehInsuffizient]: 'insuffizient',
  [SituationsAndSymptomsKeys.keineEH]: 'keine',
  [SituationsAndSymptomsKeys.multiresistenteKeimeBekannt]: 'bekannt',
  [SituationsAndSymptomsKeys.multiresistenteKeimeAbgedeckt]: 'abgedeckt',
  [SituationsAndSymptomsKeys.multiresistenteKeimeOffen]: 'offen',
};

export function getSituationsAndSymptomsTuple(key: SituationsAndSymptomsKeys): [SituationsAndSymptomsKeys, string] {
  return [key, situationsAndSymptomsLabels[key]];
}

export function getSituationsAndSymptomsTuplesFor(
  keys: SituationsAndSymptomsKeys[],
): [SituationsAndSymptomsKeys, string][] {
  return keys.map((k) => {
    return getSituationsAndSymptomsTuple(k);
  });
}
