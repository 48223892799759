import { corpulsOrange, corpulsBlue, corpulsGreen, corpulsYellow } from './CorpulsColors';
import { ThemeOptions } from '@mui/material';

const corpulsTheme: ThemeOptions = {
  palette: {
    primary: corpulsOrange,
    warning: corpulsYellow,
    secondary: corpulsGreen,
    info: corpulsBlue,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#fff',
            backgroundColor: '#fb7324',
            '&:hover': {
              backgroundColor: '#fa601b',
            },
          },
        },
      },
    },
  },

  typography: {
    h1: {
      fontSize: '20px',
      fontWeight: 500,
    },
    h2: {
      fontSize: '15px',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '10px',
      fontWeight: 400,
      textAlign: 'left',
    },
  },
};

export default corpulsTheme;
