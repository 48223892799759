// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#card-injury-activity>div div h6{width:200px}#card-injury-activity .row div.MuiFormGroup-root.MuiRadioGroup-root{align-items:flex-start}#card-injury-activity .row div.MuiFormGroup-root.MuiRadioGroup-root label{width:100%}`, "",{"version":3,"sources":["webpack://./src/App/Pages/Injuries/InjuriesActivity/InjuriesActivity.scss"],"names":[],"mappings":"AACE,iCACE,WAAA,CAGF,oEACE,sBAAA,CACA,0EACE,UAAA","sourcesContent":["#card-injury-activity {\n  > div div h6 {\n    width: 200px;\n  }\n\n  .row div.MuiFormGroup-root.MuiRadioGroup-root {\n    align-items: flex-start;\n    label {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
var ___CSS_LOADER_STYLE_SHEET___ = new CSSStyleSheet();
___CSS_LOADER_STYLE_SHEET___.replaceSync(___CSS_LOADER_EXPORT___.toString());
export default ___CSS_LOADER_STYLE_SHEET___;
