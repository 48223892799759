import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { SingleSelectFieldValueTyped } from '../generic';

export enum BloodGlucoseAdditionKeys {
  bzhigh = 'BZ_HIGH',
  bzlow = 'BZ_LOW',
}

const bloodGlucoseAdditionLabels: Record<BloodGlucoseAdditionKeys, string> = {
  [BloodGlucoseAdditionKeys.bzhigh]: 'HIGH',
  [BloodGlucoseAdditionKeys.bzlow]: 'LOW',
};

export const BLUTZUCKER_ERWEITERT_ELEMENT_KEY = 'BLUTZUCKER_ERWEITERT';

export type BloodGlucoseAdditionRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof BLUTZUCKER_ERWEITERT_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          BLUTZUCKER_LOW_HIGH?: SingleSelectFieldValueTyped<BloodGlucoseAdditionKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export function getBloodGlucoseAdditionTupel(key: BloodGlucoseAdditionKeys): [BloodGlucoseAdditionKeys, string] {
  return [key, bloodGlucoseAdditionLabels[key]];
}
