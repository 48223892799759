import {
  ApplicationMethod,
  Medication,
  MedicationDoseUnit,
  MedicationSource,
  MedicationValue,
} from '../backendModels/medication.model';
import { OptionValueType } from '../App/customs/creatableAutocomplete/CreatableAutocomplete';
import _ from 'lodash';
import { RecordId } from '../backendModels/records.model';

export type DosageUnitLabelType = { [key in MedicationDoseUnit]: string };

export const dosageUnitLabels: DosageUnitLabelType = {
  [MedicationDoseUnit.GRAM]: 'g',
  [MedicationDoseUnit.MILLIGRAM]: 'mg',
  [MedicationDoseUnit.MICROGRAM]: 'µg',
  [MedicationDoseUnit.IU]: 'IE',
};

export enum AnticoagualantsKeys {
  tzaggregationshemmer = 'TZ_AGGREGATIONSHEMMER',
  vitkantagonist = 'VITK_ANTAGONIST',
  doak = 'DOAK',
  heparin = 'HEPARIN',
  sonstigeantikoagulanzmedikation = 'SONSTIGE_ANTIKOAGULANZMEDIKATION',
}

const anticoagualantsLabels: Record<AnticoagualantsKeys, string> = {
  [AnticoagualantsKeys.tzaggregationshemmer]: 'TZ-Aggregationshemmer',
  [AnticoagualantsKeys.vitkantagonist]: 'VitK-Antagonist',
  [AnticoagualantsKeys.doak]: 'DOAK',
  [AnticoagualantsKeys.heparin]: 'Heparin',
  [AnticoagualantsKeys.sonstigeantikoagulanzmedikation]: 'Sonstige',
};

export function getAnticoagualantsTuple(key: AnticoagualantsKeys): [AnticoagualantsKeys, string] {
  return [key, anticoagualantsLabels[key]];
}

export function getAnticoagualantsTuplesFor(keys: AnticoagualantsKeys[]): [AnticoagualantsKeys, string][] {
  return keys.map((k) => {
    return getAnticoagualantsTuple(k);
  });
}

export type MedicationInfoRow = {
  recordId: RecordId;
  source: MedicationSource;
  key?: string;
  activeSubstance?: string;
  customActiveSubstance?: string;
  dosage: number;
  unit: string;
};

type MedicationRecord = {
  readonly id?: RecordId;
  readonly type: 'medication';
} & MedicationValue;

/**
 * Converts medications to labels used by table.
 * @param records The records of medications already present.
 * @param medicationsList All available medications in the configuration.
 */
export function convertToMedicationLabels(
  records: MedicationRecord[],
  medicationsList: readonly Medication[],
): MedicationInfoRow[] {
  const medications = records.map((record) => ({
    source: record.source,
    recordId: record.id,
    key: record.key,
    customActiveSubstance: record.customActiveSubstance,
    dosage: record.dose?.value,
    unit: dosageUnitLabels[record.dose?.unit as MedicationDoseUnit],
  }));
  return _.map(medications, (medication) => {
    const matchingItem = _.find(medicationsList, { key: medication.key });
    const prunedMedicationsList = _.pick(matchingItem, ['activeSubstance']);
    return _.assign({}, medication, prunedMedicationsList);
  }) as MedicationInfoRow[];
}

export function convertToMedicationListLabels(medications: readonly Medication[] | null): OptionValueType<string>[] {
  if (medications === null) {
    return [];
  }
  return medications.map((medication) => ({
    label: medication.activeSubstance,
    value: medication.key!,
  }));
}

export function convertToApplicationMethodLabels(
  applicationMethods: readonly ApplicationMethod[] | null,
): OptionValueType<string>[] {
  if (applicationMethods === null) {
    return [];
  }
  return applicationMethods.map((applicationMethod) => ({
    label: `${applicationMethod.methodName} (${applicationMethod.abbreviation})`,
    value: applicationMethod.key,
  }));
}
