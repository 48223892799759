import React from 'react';
import style from './HeaderBar.scss';
import { useCSS } from '../../provider/CSSProvider';
import Searchbar from './Searchbar/Searchbar';
import { Acronyms } from './Acronyms/Acronyms';

function HeaderBar() {
  useCSS(style);

  return (
    <div id='HeaderBar'>
      <Acronyms />
      <Searchbar />
    </div>
  );
}

export default HeaderBar;
