import { RecordId } from '../backendModels/records.model';
import { IntubationValue } from '../backendModels/intubation.model';
import { Draft } from 'immer';

export type IntubationRecord = { readonly id?: RecordId | undefined; readonly type: 'intubation' } & IntubationValue;

export enum IntubationTypeKeys {
  endotracheal = 'ENDOTRACHEAL',
  laryngealMask = 'LARYNGEAL_MASK',
  laryngealTube = 'LARYNGEAL_TUBE',
  other = 'OTHER',
}

export const IntubationTypeLabels: Record<IntubationTypeKeys, string> = {
  [IntubationTypeKeys.endotracheal]: 'endotracheale Intubation',
  [IntubationTypeKeys.laryngealMask]: 'Larynxmaske',
  [IntubationTypeKeys.laryngealTube]: 'Larynxtubus',
  [IntubationTypeKeys.other]: 'Sonstige',
};

export function getIntubationTypeTuple(key: IntubationTypeKeys): [IntubationTypeKeys, string] {
  return [key, IntubationTypeLabels[key]];
}

export function getIntubationTypeTuplesFor(keys: IntubationTypeKeys[]): [IntubationTypeKeys, string][] {
  return keys.map((k) => {
    return getIntubationTypeTuple(k);
  });
}

export enum IntubationApplicationMethodKeys {
  unknown = 'UNKNOWN',
  nasal = 'NASAL',
  oral = 'ORAL',
}

export const IntubationApplicationMethodLabels: Record<IntubationApplicationMethodKeys, string> = {
  [IntubationApplicationMethodKeys.unknown]: 'unbekannt',
  [IntubationApplicationMethodKeys.nasal]: 'nasal',
  [IntubationApplicationMethodKeys.oral]: 'oral',
};

export function getIntubationApplicationMethodTuple(
  key: IntubationApplicationMethodKeys,
): [IntubationApplicationMethodKeys, string] {
  return [key, IntubationApplicationMethodLabels[key]];
}

export function getIntubationApplicationMethodTuplesFor(
  keys: IntubationApplicationMethodKeys[],
): [IntubationApplicationMethodKeys, string][] {
  return keys.map((k) => {
    return getIntubationApplicationMethodTuple(k);
  });
}

export enum IntubationDifficultyKeys {
  difficultIntubation = 'DIFFICULT_INTUBATION',
  valueNotAvailable = 'VALUE_NOT_AVAILABLE',
}

export enum IntubationVideoKeys {
  withVideoLaryngoscope = 'WITH_VIDEO_LARYNGOSCOPE',
  valueNotAvailable = 'VALUE_NOT_AVAILABLE',
}

export enum IntubationSizeKeys {
  size3 = 'SIZE_3',
  size3_5 = 'SIZE_3_5',
  size4 = 'SIZE_4',
  size4_5 = 'SIZE_4_5',
  size5 = 'SIZE_5',
  size5_5 = 'SIZE_5_5',
  size6 = 'SIZE_6',
  size6_5 = 'SIZE_6_5',
  size7 = 'SIZE_7',
  size7_5 = 'SIZE_7_5',
  size8 = 'SIZE_8',
  size8_5 = 'SIZE_8_5',
  size9 = 'SIZE_9',
  size9_5 = 'SIZE_9_5',
}

export const intubationSizeLabels = {
  [IntubationSizeKeys.size3]: '3',
  [IntubationSizeKeys.size3_5]: '3,5',
  [IntubationSizeKeys.size4]: '4',
  [IntubationSizeKeys.size4_5]: '4,5',
  [IntubationSizeKeys.size5]: '5',
  [IntubationSizeKeys.size5_5]: '5,5',
  [IntubationSizeKeys.size6]: '6',
  [IntubationSizeKeys.size6_5]: '6,5',
  [IntubationSizeKeys.size7]: '7',
  [IntubationSizeKeys.size7_5]: '7,5',
  [IntubationSizeKeys.size8]: '8',
  [IntubationSizeKeys.size8_5]: '8,5',
  [IntubationSizeKeys.size9]: '9',
  [IntubationSizeKeys.size9_5]: '9,5',
};

export function getIntubationSizeTupel(key: IntubationSizeKeys): [IntubationSizeKeys, string] {
  return [key, intubationSizeLabels[key]];
}

export function getIntubationSizeTupelsFor(keys: IntubationSizeKeys[]): [IntubationSizeKeys, string][] {
  return keys.map((k) => {
    return getIntubationSizeTupel(k);
  });
}

export function isIntubationRecordDeletable(record: Draft<IntubationRecord> | IntubationRecord) {
  return (
    record.intubation == null &&
    record.intubationAttempts == null &&
    record.intubationSize == null &&
    record.applicationMethod === IntubationApplicationMethodKeys.unknown &&
    record.intubationDifficulty === IntubationDifficultyKeys.valueNotAvailable
  );
}
