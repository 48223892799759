import React from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import Participants from './Participants/Participants';
import SymptomStart from './SymptomStart/SymptomStart';
import MissionType from './MissionType/Type';
import MissionLocation from './MissionLocation/Location';
import Destination from './Destination/Destination';
import { useCSS } from '../../../provider/CSSProvider';
import style from './OperationalData.scss';
import { OPERATIONAL_DATA_RECORD_ELEMENT_KEY } from '../../../models/genericElements/operationalData/operationalData';

export function OperationalData() {
  useCSS(style);

  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.INITIAL_SURVEY;
  const elementKeysOfPage = [OPERATIONAL_DATA_RECORD_ELEMENT_KEY];

  const pageHeader = <PageHeader heading={getPageTitle(PageType.OperationalData)} noClearAllButton />;

  const pageFooter = <PageFooter pageType={PageType.OperationalData} diviId={DiviNavigationIds.IncidentData} />;

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      elementKeys={elementKeysOfPage}
      diviId={DiviNavigationIds.IncidentData}
    >
      <div className='operational-data-wireframe'>
        <div className='participants'>
          <Participants />
        </div>
        <div className='type'>
          <MissionType nodeType={nodeTypeOfPage} />
        </div>
        <div className='location'>
          <MissionLocation />
        </div>
        <div className='symptom-start'>
          <SymptomStart nodeType={nodeTypeOfPage} />
        </div>
        <div className='destination'>
          <Destination nodeType={nodeTypeOfPage} />
        </div>
      </div>
    </DiviPage>
  );
}
