import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { FormControl } from '@mui/material';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { NodeType } from '../../../../backendModels/report.model';
import {
  getPrenotificationTupel,
  OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
  OperationalDataRecord,
  PrenotificationKeys,
} from '../../../../models/genericElements/operationalData/operationalData';
import { InputState } from '../../../../backendModels/general.model';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { Draft } from 'immer';
import { isGenericRecordDeletable } from '../../../../models/generic';
import RadioList from '../../../customs/radioList/RadioList';
import { useFreshCallback } from '../../../../utils/hooks';
import {
  getMissionLocationNameTuplesFor,
  MissionLocationKeys,
} from '../../../../models/genericElements/operationalData/missionLocation';

interface DestinationProps {
  nodeType: NodeType;
}

const radioListItems = getMissionLocationNameTuplesFor([
  MissionLocationKeys.strokeUnit,
  MissionLocationKeys.herzkatheter,
  MissionLocationKeys.traumazentrum,
]);

export default function Destination({ nodeType }: DestinationProps) {
  const { findRecordOrDefault, adaptRecord, missionData, missionDataUpdate } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, OPERATIONAL_DATA_RECORD_ELEMENT_KEY) as OperationalDataRecord;

  const selectedPrenotification =
    record.inputState === InputState.ENTERED ? (record.values?.isPrenotification?.options ?? []) : [];

  const destinationName = missionData?.transportDestination?.name ?? null;
  const destinationPrenotificationDetail = missionData?.transportDestination?.locationType ?? null;

  function changePrenotification(value: PrenotificationKeys[]) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<OperationalDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length !== 0) {
          draft.values = {
            ...draft.values,
            isPrenotification: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.isPrenotification;
          missionDataUpdate?.({
            ...missionData,
            transportDestination: { ...missionData?.transportDestination, locationType: undefined },
          });
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
    );
  }

  const changePrenotificationDetail = useFreshCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    missionDataUpdate?.({
      ...missionData,
      transportDestination: { ...missionData?.transportDestination, locationType: event.target.value },
    });
    changePrenotification([PrenotificationKeys.isprenotification]);
  });

  return (
    <DiviFieldCard cardType={CardId.Destination}>
      <DebouncedTextField
        fullWidth
        className='no-margin-top'
        label='Name der Einrichtung'
        value={destinationName ?? ''}
      />
      <FormControl className='symptoms-list add-margin-top' fullWidth>
        <CheckboxList
          items={[getPrenotificationTupel(PrenotificationKeys.isprenotification)]}
          selectedValues={
            destinationPrenotificationDetail !== null
              ? [PrenotificationKeys.isprenotification]
              : selectedPrenotification
          }
          onValuesChange={changePrenotification}
        />
        <div className='indented'>
          <RadioList
            items={radioListItems}
            value={destinationPrenotificationDetail}
            onChange={changePrenotificationDetail}
          />
        </div>
      </FormControl>
    </DiviFieldCard>
  );
}
