export class StartTimerEvent extends Event {
  message?: string;
  due: Date;
  duration: number;
  identifier?: object;

  constructor(type: string, due: number, duration: number, message?: string, identifier?: object) {
    super(type, { bubbles: true });

    this.message = message;
    this.due = new Date(due);
    this.duration = duration;
    this.identifier = identifier;
  }
}
