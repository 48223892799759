import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { NodeType } from '../../../../backendModels/report.model';
import { getSituationsAndSymptomsTuplesFor, SituationsAndSymptomsKeys } from '../../../../models/situationsAndSymptoms';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { defaultRecords } from '../../../../DefaultRecords';
import { CardId } from '../../../../models/diviCard';

interface AnamneseProps {
  nodeType: NodeType;
}

const multiresistanceKeys = [
  SituationsAndSymptomsKeys.multiresistenteKeimeBekannt,
  SituationsAndSymptomsKeys.multiresistenteKeimeAbgedeckt,
  SituationsAndSymptomsKeys.multiresistenteKeimeOffen,
];
const multiresistanceItems = getSituationsAndSymptomsTuplesFor(multiresistanceKeys);

export default function Multiresistant({ nodeType }: AnamneseProps) {
  const reportAPI = useContext(ReportsAPIContext);
  const record = reportAPI.findRecordOrDefault(defaultRecords.situationAndSymptoms.type, nodeType);

  const selectedMultiresistanceItems = (record.situationAndSymptoms ?? []) as SituationsAndSymptomsKeys[];

  const changeSymptoms = (values: SituationsAndSymptomsKeys[]) => {
    reportAPI.adaptRecord(defaultRecords.situationAndSymptoms.type, nodeType, (draft) => ({
      ...draft,
      situationAndSymptoms: values,
    }));
  };

  return (
    <DiviFieldCard cardType={CardId.Multiresistant}>
      <CheckboxList
        className='symptoms-list'
        items={multiresistanceItems}
        selectedValues={selectedMultiresistanceItems}
        onValuesChange={changeSymptoms}
      />
    </DiviFieldCard>
  );
}
