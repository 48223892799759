import React from 'react';
import style from './DiviNavigation.scss';
import { DiviNavigationElement } from './DiviNavigationElement';
import { useCSS } from '../../provider/CSSProvider';
import { DiviNavigationIds } from '../../models/diviProgress';
import { PageType, getPageRoute } from '../../models/diviPage';

function DiviNavigation() {
  useCSS(style);

  return (
    <div id='divi-navigation'>
      <div id='divi-protocoll-navigation'>
        <div className='divi-container divi-one'>
          <DiviNavigationElement id={DiviNavigationIds.Pat} route={getPageRoute(PageType.PatientMasterData)} />
          <DiviNavigationElement id={DiviNavigationIds.IncidentData} route={getPageRoute(PageType.OperationalData)} />
          <DiviNavigationElement id={DiviNavigationIds.Anamnese} route={getPageRoute(PageType.Anamnese)} />
          <DiviNavigationElement id={DiviNavigationIds.Neurology} route={getPageRoute(PageType.Neurology)} />
          <DiviNavigationElement id={DiviNavigationIds.DataInitial} route={getPageRoute(PageType.DataInitial)} />
          <DiviNavigationElement
            id={DiviNavigationIds.MedicalConditions}
            route={getPageRoute(PageType.MedicalConditions)}
          />
          <DiviNavigationElement id={DiviNavigationIds.Injuries} route={getPageRoute(PageType.Injuries)} />
          <DiviNavigationElement
            id={DiviNavigationIds.InitialDiagnosis}
            route={getPageRoute(PageType.DiagnosisInitial)}
          />
        </div>
        <div className='divi-container divi-two'>
          <DiviNavigationElement id={DiviNavigationIds.Course} route={getPageRoute(PageType.Course)} />
          <DiviNavigationElement id={DiviNavigationIds.Medication} route={getPageRoute(PageType.Medication)} />
          <DiviNavigationElement
            id={DiviNavigationIds.ReanimationDeath}
            route={getPageRoute(PageType.ReanimationDeath)}
          />
          <DiviNavigationElement id={DiviNavigationIds.Measures} route={getPageRoute(PageType.Measures)} />
          <DiviNavigationElement id={DiviNavigationIds.Handover} route={getPageRoute(PageType.Handover)} />
          <DiviNavigationElement id={DiviNavigationIds.Specifics} route={getPageRoute(PageType.Specifics)} />
          <DiviNavigationElement id={DiviNavigationIds.HandoverTo} route={getPageRoute(PageType.HandoverTo)} />
          <DiviNavigationElement id={DiviNavigationIds.Remarks} route={getPageRoute(PageType.Remarks)} />
        </div>
      </div>
    </div>
  );
}

export default DiviNavigation;
