import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { FormGroup } from '@mui/material';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './Participants.scss';
import { getParticipantsTuplesFor, ParticipantsKeys } from '../../../../models/genericElements/participants';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import _ from 'lodash';

const participantsItems: [string, string][] = getParticipantsTuplesFor([
  ParticipantsKeys.nef,
  ParticipantsKeys.selbstfahrer,
  ParticipantsKeys.naw,
  ParticipantsKeys.rtw,
  ParticipantsKeys.ktw,
  ParticipantsKeys.rth,
  ParticipantsKeys.ith,
  ParticipantsKeys.sonstige,
  ParticipantsKeys.bergrettung,
  ParticipantsKeys.wasserrettung,
  ParticipantsKeys.teleNA,
]);

export default function Participants() {
  useCSS(style);

  const { missionData, missionDataUpdate } = useContext(ReportsAPIContext);

  const participantsValues = _.union(missionData?.participants?.participants ?? [], [
    ParticipantsKeys.teleNA,
    ParticipantsKeys.rtw,
  ]);

  const changeParticipants = (values: string[]) => {
    missionDataUpdate?.({
      ...missionData,
      participants: {
        participants: values,
      },
    });
  };

  return (
    <DiviFieldCard cardType={CardId.Participants}>
      <FormGroup className='symptoms-list'>
        <CheckboxList
          items={participantsItems}
          onValuesChange={changeParticipants}
          selectedValues={participantsValues}
          disabledItems={[ParticipantsKeys.rtw, ParticipantsKeys.teleNA]}
        />
      </FormGroup>
    </DiviFieldCard>
  );
}
