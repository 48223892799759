import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import RadioList from '../../../customs/radioList/RadioList';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
  OperationalDataRecord,
} from '../../../../models/genericElements/operationalData/operationalData';
import { InputState } from '../../../../backendModels/general.model';
import { NodeType } from '../../../../backendModels/report.model';
import { Draft } from 'immer';
import {
  getMissionTypeLabelTuplesFor,
  MissionTypeKeys,
} from '../../../../models/genericElements/operationalData/missionType';

interface MissionTypeProps {
  nodeType: NodeType;
}

const firstLevelRadioItems = getMissionTypeLabelTuplesFor([
  MissionTypeKeys.primaereinsatz,
  MissionTypeKeys.folgeeinsatz,
  MissionTypeKeys.fehleinsatz,
  MissionTypeKeys.notarztnachforderung,
  MissionTypeKeys.sekundaereinsatz,
  MissionTypeKeys.bereitstellung,
]);

const secondLevelRadioItems = getMissionTypeLabelTuplesFor([
  MissionTypeKeys.keinpatient,
  MissionTypeKeys.abbestellt,
  MissionTypeKeys.abtransportiert,
  MissionTypeKeys.boeswillig,
]);

const thirdLevelRadioItems = getMissionTypeLabelTuplesFor([
  MissionTypeKeys.technisch,
  MissionTypeKeys.wetter,
  MissionTypeKeys.sonstiges,
]);

export default function MissionType({ nodeType }: MissionTypeProps) {
  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);
  const record = findRecordOrDefault('generic', nodeType, OPERATIONAL_DATA_RECORD_ELEMENT_KEY) as OperationalDataRecord;

  const selectedMissionType = record.inputState === InputState.ENTERED ? record.values?.missionType?.option ?? [] : [];

  const selectedMissionFailure =
    record.inputState === InputState.ENTERED ? record.values?.missionFailure?.option ?? [] : [];

  function changeMissionType(event: React.ChangeEvent<HTMLInputElement>) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<OperationalDataRecord>) => {
        draft.inputState = InputState.ENTERED;
        if (event.target.value !== MissionTypeKeys.fehleinsatz) {
          delete draft?.values?.missionFailure;
        }
        draft.values = {
          ...draft.values,
          missionType: {
            fieldType: 'singleSelect',
            option: event.target.value as MissionTypeKeys,
          },
        };
      },
      OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
    );
  }

  function changeMissionFailure(event: React.ChangeEvent<HTMLInputElement>) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<OperationalDataRecord>) => {
        draft.inputState = InputState.ENTERED;
        draft.values = {
          ...draft.values,
          missionType: {
            fieldType: 'singleSelect',
            option: MissionTypeKeys.fehleinsatz,
          },
          missionFailure: {
            fieldType: 'singleSelect',
            option: event.target.value as MissionTypeKeys,
          },
        };
      },
      OPERATIONAL_DATA_RECORD_ELEMENT_KEY,
    );
  }

  const combinedRadioList = () => {
    return (
      <>
        {firstLevelRadioItems.slice(0, 3).map(([key, label], index) => (
          <FormControlLabel
            key={`list1-${index}`}
            checked={key === selectedMissionType}
            value={key}
            control={<Radio />}
            label={label}
          />
        ))}
        <div className='indented'>
          <RadioList items={secondLevelRadioItems} value={selectedMissionFailure} onChange={changeMissionFailure} />
          <Typography variant='body1' className='list-subheading'>
            {' '}
            Einsatzabbruch
          </Typography>
          <div className='indented'>
            <RadioList items={thirdLevelRadioItems} value={selectedMissionFailure} onChange={changeMissionFailure} />
          </div>
        </div>
        {firstLevelRadioItems.slice(3).map(([key, label], index) => (
          <FormControlLabel
            key={`list1-second-${index}`}
            checked={key === selectedMissionType}
            value={key}
            control={<Radio />}
            label={label}
          />
        ))}
      </>
    );
  };

  return (
    <DiviFieldCard cardType={CardId.MissionType}>
      <div className='symptoms-list'>
        <FormControl>
          <RadioGroup onChange={changeMissionType}>{combinedRadioList()}</RadioGroup>
        </FormControl>
      </div>
    </DiviFieldCard>
  );
}
