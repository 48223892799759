import React, { useContext } from 'react';
import PageHeader from '../../customs/DiviPage/PageHeader/PageHeader';
import { DiviNavigationIds } from '../../../models/diviProgress';
import PageFooter from '../../customs/DiviPage/PageFooter/PageFooter';
import DiviPage from '../../customs/DiviPage/DiviPage';
import { NodeType } from '../../../backendModels/report.model';
import { getPageTitle, PageType } from '../../../models/diviPage';
import { BaseNodeRecordValueTypeMap } from '../../../backendModels/records.model';
import { CardId } from '../../../models/diviCard';
import { useCSS } from '../../../provider/CSSProvider';
import style from './Acronym.scss';
import { CARDID_MAP, COMPONENT_MAP } from './AcronymComponentMap';
import { useLocation } from 'react-router-dom';
import { AcronymEntry } from '../../../models/acronym';
import { ReportsAPIContext } from '../../../provider/ReportsAPIProvider';

interface DynamicPageProps {
  content: AcronymEntry[];
}

interface RenderComponentByNameProps {
  componentName: string;
  key: string;
}

function DynamicPage({ content }: DynamicPageProps) {
  return (
    <div>
      {content.map((block, blockIndex) => (
        <div key={blockIndex}>
          <h2 className='acronym-section'>{block.letter}</h2>
          {block.content.map((item, itemIndex) => {
            const key = `${blockIndex}-${itemIndex}`;
            if (typeof item === 'string') {
              return <RenderComponentByName componentName={item} key={key} />;
            } else if (Array.isArray(item)) {
              return <RenderAsRow items={item} rowIndex={blockIndex} key={key} />;
            } else {
              return null;
            }
          })}
        </div>
      ))}
    </div>
  );
}

function RenderComponentByName({ componentName }: RenderComponentByNameProps) {
  const ComponentToRender = COMPONENT_MAP[componentName];
  if (!ComponentToRender) {
    console.error('Komponente ' + componentName + ' nicht gefunden. Bitte JSON überprüfen.');
    return <p className='error'>Komponente {componentName} nicht gefunden. Bitte JSON überprüfen.</p>;
  }
  // Act as a wrapper to assign the key prop
  return <ComponentToRender key={'key-' + componentName} nodeType={NodeType.INITIAL_SURVEY} />;
}

function RenderAsRow({ items, rowIndex }: { items: string[]; rowIndex: number }) {
  return (
    <div className='field-row'>
      {items.map((itemName, index) => (
        <RenderComponentByName componentName={itemName} key={`${rowIndex}-${index}`} />
      ))}
    </div>
  );
}

function generateFlatIdArray(acronymData: AcronymEntry[]): CardId[] {
  const flatIdArray: CardId[] = [];
  acronymData.forEach((block) => {
    block.content.forEach((item) => {
      if (typeof item === 'string' && CARDID_MAP[item]) {
        flatIdArray.push(CARDID_MAP[item]);
      } else if (Array.isArray(item)) {
        item.forEach((nestedItem) => {
          if (CARDID_MAP[nestedItem]) {
            flatIdArray.push(CARDID_MAP[nestedItem]);
          }
        });
      }
    });
  });
  return flatIdArray;
}

export function Acronym() {
  useCSS(style);
  const recordTypesOfPage = [] as (keyof BaseNodeRecordValueTypeMap)[];
  const nodeTypeOfPage = NodeType.GENERIC;
  const { acronyms } = useContext(ReportsAPIContext);

  const location = useLocation();
  const chosenAcronym = decodeURIComponent(location.pathname.split('/').at(-1) ?? '');

  if (chosenAcronym === undefined || chosenAcronym === '' || acronyms?.[chosenAcronym] == null) {
    console.error(
      'Dies ist kein valides Akronym. Bitte über die Navigation oder über die Suche ein valides Akronym auswählen.',
    );
    return (
      <>Dies ist kein valides Akronym. Bitte über die Navigation oder über die Suche ein valides Akronym auswählen.</>
    );
  }

  const navigationIds = generateFlatIdArray(acronyms[chosenAcronym]);

  const pageHeader = (
    <PageHeader heading={getPageTitle(PageType.Acronym)} ListOfCardIds={navigationIds} noClearAllButton={true} />
  );
  const pageFooter = (
    <PageFooter
      pageType={PageType.Acronym}
      diviId={DiviNavigationIds.Pat}
      noDoneButton
      backInHistoryOnBack
      noNextButton
    />
  );

  return (
    <DiviPage
      pageHeader={pageHeader}
      pageFooter={pageFooter}
      recordTypes={recordTypesOfPage}
      nodeType={nodeTypeOfPage}
      diviId={DiviNavigationIds.Pat}
    >
      <div className='acronym-wireframe'>
        <DynamicPage content={acronyms[chosenAcronym]} />
      </div>
    </DiviPage>
  );
}
