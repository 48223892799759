export enum ParticipantsKeys {
  nef = 'NEF',
  selbstfahrer = 'Selbstfahrer',
  naw = 'NAW',
  rtw = 'RTW',
  ktw = 'KTW',
  rth = 'RTH',
  ith = 'ITH',
  sonstige = 'Sonstige',
  bergrettung = 'Bergrettung',
  wasserrettung = 'Wasserrettung',
  teleNA = 'TNA',
}

const ParticipantsNameTuples: Record<string, string> = {
  [ParticipantsKeys.nef]: 'NEF',
  [ParticipantsKeys.selbstfahrer]: 'Selbstfahrer',
  [ParticipantsKeys.naw]: 'NAW',
  [ParticipantsKeys.rtw]: 'RTW',
  [ParticipantsKeys.ktw]: 'KTW',
  [ParticipantsKeys.rth]: 'RTH',
  [ParticipantsKeys.ith]: 'ITH',
  [ParticipantsKeys.sonstige]: 'sonst.',
  [ParticipantsKeys.bergrettung]: 'Bergrettung',
  [ParticipantsKeys.wasserrettung]: 'Wasserrettung',
  [ParticipantsKeys.teleNA]: 'TeleNA',
};

export function getParticipantsTuple(key: ParticipantsKeys): [string, string] {
  return [key, ParticipantsNameTuples[key]];
}

export function getParticipantsTuplesFor(keys: ParticipantsKeys[]): [string, string][] {
  return keys.map((k) => {
    return getParticipantsTuple(k);
  });
}
